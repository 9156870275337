<template>
  <div class="top-navigation">
    <div v-if="showSearch"><SearchView @on-close="showSearch = false" /></div>
    <nav
      :class="{
        'has-top-banner': hasTopBanner,
        'is-activated': isNotTop || isHamburgerOpen,
        'go-up': showSearch && showSearch !== null,
        'go-down': !showSearch && showSearch !== null,
      }"
    >
      <router-link :to="{ name: 'home' }" class="logo-wrapper">
        <img
          class="logo"
          v-show="!showSearch"
          :class="{
            shrink: isHamburgerOpen
              ? true
              : $store.getters.isLoggedIn
              ? true
              : isNotTop,
            increase: isHamburgerOpen
              ? false
              : $store.getters.isLoggedIn
              ? false
              : !isNotTop,
          }"
          src="@/assets/images/svg/logo/mobioexpress-machine.svg"
          width="200"
          alt="Image"
        />
      </router-link>

      <div
        class="overlay us-search"
        @click="isUserOpen = false"
        v-if="isUserOpen"
      ></div>

      <div class="content">
        <div
          class="is-flex is-flex-direction-row is-justify-content-space-between nav-content"
        >
          <div class="todo">
            <RouterLink
              :to="{ name: 'home' }"
              class="has-icon"
              :class="{ 'is-top': isNotTop }"
              ><img
                class="automat-icon"
                src="@/assets/images/svg/automat-icon.svg"
                width="30"
                alt="Automat"
              />Automat</RouterLink
            >
            <RouterLink :to="{ name: 'about' }">Om</RouterLink>
            <RouterLink :to="{ name: 'policy' }">Villkor</RouterLink>
          </div>

          <div class="login">
            <div class="search">
              <div class="field has-addons">
                <div class="control">
                  <button class="input" @click="toggleSearch">
                    Vad letar du efter?
                  </button>
                </div>
                <div class="control">
                  <a
                    style="height: 40px"
                    class="button is-info"
                    @click="showSearch = true"
                    :class="{ 'is-loading': searchIsLoading }"
                  >
                    <figure class="image is-20x20" v-if="!searchIsLoading">
                      <img src="@/assets/images/svg/zoom-icon.svg" />
                    </figure>
                    <figure class="image is-20x20" v-else>
                      <div style="width: 20px; height: 20px"></div>
                    </figure>
                  </a>
                </div>
              </div>
            </div>

            <a
              @click="onToggleLoginModal"
              class="button"
              :class="{
                'is-login': isNotTop,
                'is-loading': $store.state.authenticating,
              }"
              style="
                padding-left: 3.5rem;
                text-align: left;
                vertical-align: top;
                display: inline;
                border: 0;
                border-radius: 0;
              "
              v-if="!$store.state.authUser"
            >
              <img
                v-if="!$store.state.authenticating"
                class="profile-icon"
                src="@/assets/images/svg/profile.svg"
                width="30"
                alt="Profile"
              />Logga in</a
            >
            <a
              :class="{ 'is-login': isNotTop }"
              @click="onToggleLoggedInModal"
              style="padding-left: 7.5rem; width: 140px; position: relative"
              v-else
            >
              <img
                class="profile-icon"
                src="@/assets/images/svg/profile.svg"
                width="30"
                alt="Profile"
              />
              <small
                v-if="$store.state.user"
                style="
                  position: absolute;
                  top: 13px;
                  left: 54px;
                  width: 85px;
                  white-space: nowrap;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                "
              >
                {{ $store.state.user.alias }}
              </small>
              <small
                v-if="$store.state.user"
                style="
                  position: absolute;
                  top: 31px;
                  left: 54px;
                  color: #ffd859;
                "
                >{{ $store.state.user.points }}p</small
              >
            </a>
            <div
              class="login-modal"
              v-if="isUserOpen"
              :class="{ 'is-active': isUserOpen }"
            >
              <UserDropdown @on-logout="onLogout" :user="$store.state.user" />
            </div>
            <div
              class="login-modal"
              v-if="loginModalIsOpen"
              :class="{ 'is-active': loginModalIsOpen }"
            >
              <LoginForm
                @on-success="loginModalIsOpen = false"
                @on-register="toggleRegister"
              ></LoginForm>
            </div>
            <a
              @click="toggleCart"
              style="padding-left: 3.5rem"
              class="cart-button"
            >
              <img
                class="profile-icon"
                src="@/assets/images/svg/cart-icon.svg"
                width="30"
                alt="Profile"
              />Varukorg
              <span class="cart-items-nr" v-if="$store.state.cart.length">{{
                $store.state.cart.length
              }}</span></a
            >

            <CartInfo />
          </div>

          <figure class="mobile-search" @click="showSearch = true">
            <img src="@/assets/images/svg/zoom-icon.svg" />
          </figure>

          <div
            class="hamburger"
            @click="isHamburgerOpen = !isHamburgerOpen"
            :class="{
              'is-active': isHamburgerOpen,
              'is-activated': isNotTop || isHamburgerOpen,
            }"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="hamburger-content" v-if="isHamburgerOpen">
            <div class="">
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <RouterLink
                  :to="{
                    name: 'productSearchResult',
                    query: { theme: 'funko' },
                  }"
                  >Funko POP!</RouterLink
                >
              </div>
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <RouterLink
                  :to="{
                    name: 'productSearchResult',
                    query: { theme: 'lego' },
                  }"
                  >Lego</RouterLink
                >
              </div>
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <RouterLink
                  :to="{
                    name: 'productSearchResult',
                    query: { theme: 'barbie' },
                  }"
                  >Barbie</RouterLink
                >
              </div>
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <RouterLink
                  :to="{
                    name: 'productSearchResult',
                    query: { theme: 'pokémon' },
                  }"
                  >Pokémon</RouterLink
                >
              </div>
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <RouterLink
                  :to="{
                    name: 'productSearchResult',
                    query: { theme: 'nintendo' },
                  }"
                  >Nintendo</RouterLink
                >
              </div>
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <RouterLink
                  :to="{
                    name: 'productSearchResult',
                    query: { theme: '' },
                  }"
                  >Deals</RouterLink
                >
              </div>
              <div
                class="hamburger-item has-half-top-padding has-half-bottom-padding"
                @click="isHamburgerOpen = false"
              >
                <div class="separator"></div>
              </div>

              <div class="hamburger-item" @click="isHamburgerOpen = false">
                <RouterLink :to="{ name: 'about' }">Om</RouterLink>
              </div>
              <div class="hamburger-item" @click="isHamburgerOpen = false">
                <RouterLink :to="{ name: 'policy' }">Villkor</RouterLink>
              </div>
              <section v-if="$store.state.authUser">
                <hr />
                <div style="display: flex">
                  <figure
                    class="image has-no-margin"
                    style="
                      width: 50px;
                      height: 50px;
                      margin-right: 12px !important;
                    "
                  >
                    <img
                      class="has-no-padding is-rounded"
                      src="https://firebasestorage.googleapis.com/v0/b/mache-staging.appspot.com/o/images%2Fsvg%2Fskagera%2Fprofile-images%2FEDWARD.svg?alt=media&token=a4c335f0-c118-41d5-9212-1c8ee5363ed0"
                    />
                  </figure>
                  <div>
                    <small class="is-size-6">Hej</small>
                    <h3
                      v-if="$store.state.user"
                      class="has-no-top-margin"
                      :class="{
                        'has-no-bottom-margin': $store.state.userIsAdmin,
                      }"
                    >
                      {{ $store.state.user.alias }}
                    </h3>

                    <div
                      v-if="$store.state.userIsAdmin"
                      class="is-size-7 is-bold tag is-info"
                      :class="{
                        'has-half-top-margin': $store.state.userIsAdmin,
                      }"
                    >
                      Mästare
                    </div>
                  </div>
                </div>

                <div class="hamburger-item" @click="isHamburgerOpen = false">
                  <RouterLink :to="{ name: 'account' }">Mitt konto</RouterLink>
                </div>
                <div class="hamburger-item" @click="isHamburgerOpen = false">
                  <RouterLink :to="{ name: 'order' }">Beställningar</RouterLink>
                </div>
                <div class="hamburger-item" @click="isHamburgerOpen = false">
                  <RouterLink :to="{ name: 'membership' }"
                    >Medlemskap</RouterLink
                  >
                </div>
                <button
                  class="button is-info is-light has-top-margin"
                  @click="toggleMobileLogout"
                >
                  Logga ut
                </button>
              </section>
              <section v-else>
                <hr />
                <h3>Logga in</h3>
                <div class="has-top-margin">
                  <LoginForm></LoginForm>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div
        class="navigation-entries content"
        :class="{ 'is-active ': isNotTop }"
      >
        <div class="content wrap">
          <router-link
            :to="{ name: 'productSearchResult', query: { theme: 'funko' } }"
            class="item"
            >Funko POP!</router-link
          >
          <router-link
            :to="{ name: 'productSearchResult', query: { theme: 'lego' } }"
            class="item"
            >Lego</router-link
          >
          <router-link
            :to="{ name: 'productSearchResult', query: { theme: 'barbie' } }"
            class="item"
            >Barbie</router-link
          >
          <div class="split"></div>
          <router-link
            :to="{ name: 'productSearchResult', query: { theme: 'pokémon' } }"
            class="item"
            >Pokémon</router-link
          >
          <router-link
            :to="{ name: 'productSearchResult', query: { theme: 'nintendo' } }"
            class="item"
            >Nintendo</router-link
          >
          <router-link
            :to="{ name: 'productSearchResult', query: { theme: 'deals' } }"
            class="item"
            >Deals ✌🏼
          </router-link>

          <div class="info">
            <a href="https://www.facebook.com/mobioExpress" target="_blank">
              <span class="mdi mdi-message-outline"></span>
              Chatt</a
            >
            &nbsp; | &nbsp;
            <a href="mailto:info@mobio.se" class="has-right-padding">
              <span class="mdi mdi-email-outline"></span>
              info@mobio.se
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import LoginForm from "../forms/LoginForm.vue";
import SearchView from "../SearchView.vue";
import CartInfo from "../CartInfo.vue";
import UserDropdown from "../UserDropdown.vue";

export default {
  name: "TopNavigation",
  components: {
    RouterLink,
    LoginForm,
    SearchView,
    CartInfo,
    UserDropdown,
  },
  data() {
    return {
      searchIsLoading: false,
      isNotTop: null,
      isHome: true,
      loginModalIsOpen: false,
      isHamburgerOpen: false,
      showSearch: null,
      isUserOpen: false,
    };
  },
  props: {
    hasTopBanner: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    window.addEventListener("scroll", () => {
      if (this.isNotTop === null) {
        this.isNotTop = scrollY > 0;
      }
    });
    setTimeout(() => {
      if (this.isNotTop === null) {
        this.isNotTop = true;
      }
    }, 5000);
  },
  watch: {
    "$route.name": function (name) {
      if (name !== "home") {
        this.isNotTop = true;
        this.isHome = false;
      } else this.isHome = true;
    },
  },
  methods: {
    toggleRegister() {
      this.isHamburgerOpen = false;
      this.isUserOpen = false;
      this.loginModalIsOpen = false;
    },
    toggleCart() {
      this.setCartIsHighlighted();

      this.loginModalIsOpen = false;
      this.isUserOpen = false;
    },
    toggleSearch() {
      this.$store.commit("setCartIsHighlighted", false);
      this.showSearch = true;
      this.isUserOpen = false;
      this.loginModalIsOpen = false;
    },
    onLogout() {
      this.isUserOpen = false;
    },
    toggleMobileLogout() {
      this.$store.dispatch("signOut");
    },
    setCartIsHighlighted() {
      this.$store.commit(
        "setCartIsHighlighted",
        !this.$store.state.cartIsHighlighted
      );
    },
    onToggleLoginModal() {
      this.$store.commit("setCartIsHighlighted", false);
      this.loginModalIsOpen = !this.loginModalIsOpen;
    },
    onToggleLoggedInModal() {
      this.$store.commit("setCartIsHighlighted", false);
      this.isUserOpen = !this.isUserOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.top-navigation {
  position: relative;
  z-index: 99999;
  .cart-button .cart-items-nr {
    width: 20px;
    height: 20px;
    background-color: #ffd859;
    color: #272727;
    position: absolute;
    top: 10px;
    padding: 0.1rem 0.4rem;
    right: 0;
    font-size: 12px;
    border-radius: 12px;
    transform: scale(0);
    animation: 0.7s bump linear forwards;
  }
  @keyframes bump {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  nav {
    width: 100%;
    height: 61px;
    position: relative;
    border-bottom: 5px solid transparent;
    top: 0;
    background: transparent;
    position: fixed;
    z-index: 999;
    color: gray;
    transition: 0.1s all ease-in;

    .search {
      position: absolute;
      right: 212px;
      width: 350px;
      top: -12px;
      .is-20x20 {
        width: 20px;
        height: 20px;
      }
      a {
        background: #2647e8 !important;
        padding: 8px 16px 8px 12px !important;
        figure {
          margin: 0;
          img {
            padding: 0;
          }
        }
      }
      .control:not(:first-of-type) a {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .control:first-of-type {
        width: 200px !important;
        button {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          background: #d0d8ff !important;
          border: 1px solid #2647e8;
          transition: 0.3s all ease;
          padding-left: 20px;
          &::placeholder {
            color: rgb(121, 121, 121);
          }
          &:focus {
            background: white !important;
            &::placeholder {
              color: rgb(42, 42, 42);
            }
          }
        }
      }
    }

    &.go-up {
      animation: 0.25s animation-up ease-in forwards;
    }

    @keyframes animation-up {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-66px);
      }
    }

    &.go-down {
      animation: 0.25s animation-down ease-out forwards;
    }

    @keyframes animation-down {
      from {
        transform: translateY(-66px);
      }
      to {
        transform: translateY(0);
      }
    }
    &.is-activated {
      .search {
        a {
          background: #363636 !important;
        }
        .control:first-of-type {
          input {
            background: #dcdcdc !important;
            border: 1px solid #363636;

            &:focus {
              background: white !important;
            }
          }
        }
      }
    }

    .mobile-search {
      display: none;
      z-index: 2;
      width: 40px;
      height: 40px;
      padding: 5px;
      position: absolute;
      left: 1rem;
      top: 10px;

      margin: 0;
      //background: red;
      img {
        padding: 0;
      }
    }

    .hamburger {
      z-index: 2;
      display: none;
      width: 40px;
      height: 40px;
      padding: 5px;
      //background-color: #272727;
      position: absolute;
      right: 1rem;
      border-radius: 3px;
      cursor: pointer;
      &.is-activated {
        background-color: #272727;
      }
      span {
        display: block;
        position: relative;
        width: 30px;
        height: 3px;
        top: 0;
        background-color: white;
        &:nth-of-type(2) {
          top: 10px;
        }
        &:nth-of-type(3) {
          top: 20px;
        }
      }
      &.is-active {
        span {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            top: 13px;
          }
          &:nth-of-type(3) {
            display: none;
          }
        }
      }
    }
    .hamburger-content {
      position: fixed;
      width: 100vw;
      height: calc(100vh - 61px);
      padding: 1rem;
      background-color: white;
      left: 0;
      top: 61px;
      z-index: 1;
      .hamburger-item {
        padding: 1rem 0;
        a {
          padding: 12px 0rem;
          .automat-icon {
            top: 0;
            padding: 0.2rem 0;
          }
        }
        a.router-link-active {
          //border-bottom: 4px solid #4d4d4d;
        }
      }
    }
    a.router-link-active {
      //border-bottom: 5px solid rgba(255, 255, 255, 0.7);
    }
    a.is-top.router-link-active {
      //border-bottom: 5px solid #4d4d4d;
    }
    a {
      position: relative;
    }

    .has-icon {
      padding-left: 2.5rem !important;
    }
    .automat-icon {
      top: 10px;
      left: 0;
      position: absolute;
    }
    .login {
      position: relative;
      .profile-icon {
        position: absolute;
        top: 10px;
        left: 14px;
      }

      a {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      .is-login {
        background: #363636;
        border-top: 5px solid #363636;
      }
    }
    .login-modal {
      position: absolute;

      //bottom: -90px;
      top: 6rem;
      right: 0;
      background-color: white;
      width: 300px;
      padding: 1rem;
      border-radius: 6px;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
      &:after {
        content: " ";
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        background: white;
        top: -8px;
        left: 5.5rem;
        position: absolute;
      }
      &.is-active {
        animation: slide-up 0.4s ease-out;
      }
    }

    @keyframes slide-up {
      0% {
        transform: translateY(10px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &.is-activated {
      //border-bottom: 5px solid #1d1d1b;
      background: #272727;
      margin-top: 0 !important;
    }
    &.has-top-banner {
      margin-top: 48px;
    }
    .content {
      padding: 0 3rem;
      position: relative;
      display: block;
      z-index: 999;
      margin-bottom: 0;
    }
    .nav-content {
      align-items: center;
      justify-content: center;
      height: 61px;
      a {
        padding: 21px 1.2rem;
        background-color: transparent;
        color: white;
        font-weight: bold;
      }
    }

    img {
      padding: 0.5rem 0;
    }

    @keyframes animate-shrink {
      from {
        width: 180px;
        top: 100px;
      }
      to {
        width: 130px;
        top: 0;
      }
    }

    @keyframes animate-increase {
      from {
        width: 130px;
        top: 0;
      }
      to {
        width: 180px;
        top: 100px;
      }
    }
    .shrink {
      animation: 0.2s animate-shrink ease-out forwards;
      z-index: 9999;
    }

    .increase {
      animation: 0.2s animate-increase ease-in forwards;
    }

    @media screen and (max-width: 1300px) {
      .search .input {
        display: none;
      }
      .search .button {
        border-bottom-left-radius: 20px !important;
        border-top-left-radius: 20px !important;
        padding: 10px !important;
      }
    }

    @media screen and (max-width: 768px) {
      .hamburger-content {
        a {
          color: #272727;
        }
      }

      .mobile-search {
        display: block;
      }
      .hamburger {
        display: block;
      }

      .todo,
      .login {
        display: none;
      }
    }
  }

  @keyframes nav-pop {
    from {
      top: -40px;
      opacity: 0;
    }
    to {
      top: 0px;
      opacity: 1;
    }
  }

  .navigation-entries {
    position: relative;
    top: -40px;
    opacity: 0;
    &.is-active {
      animation: 0.25s nav-pop 0.25s forwards;
    }
    padding: 0rem 1rem;
    background: #2f2f2f;

    .wrap {
      justify-content: center;
      display: flex;
    }
    border-bottom: 5px solid #1d1d1b;
    .split {
      width: 180px;
    }

    .info {
      position: absolute;
      right: 0;
      justify-content: center;
      display: flex;
      font-size: 14px;
      top: 50%;
      transform: translateY(-50%);
      a {
        color: white;
      }
    }
    .item {
      border-bottom: 0;
      display: inline-block;
      font-size: 14px;
      padding: 0.35rem 1.25rem;
      color: white;
      position: relative;

      &:hover {
        //position: relative;
        //border-bottom: 5px solid #4d4d4d;
        &:after {
          content: "";
          height: 0;
          width: 100%;
          background: red;
          position: absolute;
          left: 0;
          bottom: -5px;

          border-bottom: 5px solid #4d4d4d;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-entries {
      display: none !important;
    }
  }
}
</style>
