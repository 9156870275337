import { functions } from "@/main";
import { httpsCallable } from "firebase/functions";

const StripeService = {
  async reservProducts(products, sessionId) {
    const reserv = httpsCallable(functions, "stripeReservProducts");
    return reserv({ products: products, sessionId: sessionId });
  },
  async checkAvailabilityOf(products) {
    const check = httpsCallable(functions, "checkAvailability");
    return check({ products: products });
  },
};

export default StripeService;
