<template>
  <div class="product-list">
    <ModalPopup
      v-if="isModalOpen"
      @close-modal="isModalOpen = false"
      :hasNoPadding="true"
    >
      <template v-slot:header>
        <p></p>
      </template>
      <template v-slot:content>
        <div class="image is-relative has-top-margin">
          <img
            :src="selectedProduct.image"
            class="has-no-margin is-horizontal-middle"
            style="max-height: 220px; width: auto"
          />
        </div>
        <section class="has-1x5-padding">
          <div class="has-2x-bottom-margin">
            <h5 class="has-top-margin has-text-grey has-text-centered">
              {{ selectedProduct.description || selectedProduct.brand }}
            </h5>

            <h4 class="has-no-margin has-text-centered">
              {{ selectedProduct.name }}
            </h4>
            <h4
              class="has-no-bottom-margin has-half-top-margin is-size-4 has-text-centered"
            >
              <span
                class="is-size-6 tag"
                :class="{ 'is-danger': selectedProduct.promoPrice }"
                >{{
                  selectedProduct.promoPrice
                    ? selectedProduct.promoPrice
                    : selectedProduct.price
                }}
                kr</span
              >
              <span
                v-if="selectedProduct.promoPrice"
                style="text-decoration: line-through"
                class="has-half-left-margin"
                >{{ selectedProduct.price }} kr</span
              >
            </h4>

            <div
              class="has-top-margin"
              v-if="selectedProduct.content"
              v-html="selectedProduct.content"
            ></div>
          </div>

          <button
            v-if="$store.state.userIsAdmin"
            class="button is-danger is-fullwidth has-half-top-margin"
            @click="
              toggleActivationOf(selectedProduct, selectedProduct.isActive)
            "
          >
            {{ selectedProduct.isActive ? "Avpublicera" : "Publicera" }}
          </button>
          <div class="has-top-margin" v-if="$store.state.userIsAdmin">
            <EditProductForm :product="selectedProduct"></EditProductForm>
          </div>
        </section>
        <div class="bottom-actions">
          <section
            class="is-inline-block is-horizontal-middle has-half-bottom-margin"
          >
            <small
              v-if="
                selectedProduct.isBookable &&
                formatDate(new Date(selectedProduct.booking.date))
              "
              ><span class="warning-circle"></span>
              <span class="circle-text"
                >Förboka! | {{ selectedProduct.available }}st kvar</span
              >
            </small>
            <small v-else-if="selectedProduct.available > 2"
              ><span class="success-circle"></span>
              <span class="circle-text"
                >{{ selectedProduct.available }} st i lager</span
              >
            </small>
            <small v-else-if="selectedProduct.available > 0"
              ><span class="warning-circle"></span>
              <span class="circle-text"
                >Endast {{ selectedProduct.available }} st i lager</span
              >
            </small>
            <small v-else-if="selectedProduct.available === 0"
              ><span class="no-circle"></span>
              <span class="circle-text">Slut i lager</span>
            </small>
          </section>
          <p class="is-size-7 has-no-margin has-text-centered">
            Ej lämplig för barn under 3 år på grund av smådelar.
          </p>
          <button
            class="button is-primary is-fullwidth has-half-top-margin"
            @click="onAddProduct(selectedProduct)"
            v-if="selectedProduct.available > 0"
          >
            Lägg i varukorg
          </button>
        </div>
      </template>
    </ModalPopup>
    <div v-if="view === 'LIST'" class="columns is-multiline">
      <div
        class="column is-full"
        v-for="product in products"
        :key="product.objectID"
      >
        <article
          class="card is-list"
          :class="{
            'not-available': isAvailable(product),
            'is-bookable':
              product.isBookable && formatDate(new Date(product.booking.date)),
          }"
          @click="onOpenModal(product)"
        >
          <div class="flex" :class="{ disabled: product.available <= 0 }">
            <figure class="image is-48x48">
              <img :src="product.image" />
            </figure>
            <div class="flex is-space-between" style="width: 100%">
              <div class="has-left-margin">
                <div v-if="isOnSale(product)" class="is-size-7 tag banner">
                  {{ getSaleBanner(product) }}
                </div>
                <p style="line-height: 2.2">
                  {{ product.name }}
                </p>
              </div>
              <div>
                <p
                  class="is-bold has-text-right"
                  :class="{
                    'has-banner-on-top': isOnSale(product),
                  }"
                >
                  {{ product.price }} kr
                </p>
                <p
                  class="is-size-7 has-text-right"
                  v-if="
                    product.isBookable &&
                    formatDate(new Date(product.booking.date))
                  "
                >
                  <span class="warning-circle has-quarter-right-margin"></span>
                  Förboka!
                </p>
                <p
                  class="is-size-7 has-text-right"
                  v-else-if="product.available > 2"
                >
                  <span class="success-circle has-quarter-right-margin"></span>
                  I lager
                </p>
                <p
                  class="is-size-7 has-text-right"
                  v-else-if="product.available > 0"
                >
                  <span class="warning-circle has-quarter-right-margin"></span>
                  Få i lager
                </p>

                <p
                  class="is-size-7 has-text-right"
                  v-else-if="product.available === 0"
                >
                  <span class="no-circle has-quarter-right-margin"></span>
                  Slut i lager
                </p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div
      class="columns is-multiline"
      :class="{ 'card-container': isMobileScrollable }"
      v-else
    >
      <div
        :class="columnsClass"
        class="column"
        v-for="product in products"
        :key="product.objectID"
      >
        <article
          class="product-card"
          :class="{
            'not-available': isAvailable(product),
            'is-bookable':
              product.isBookable && formatDate(new Date(product.booking.date)),
          }"
          @click="onGoTo($event, product)"
        >
          <div
            :class="{
              bubble: quantityHasChanged === product.objectID,
              'is-frozen': quantityHasChanged !== product.objectID,
            }"
          >
            <p>1+</p>
          </div>
          <figure class="image is-relative">
            <img :src="product.image" />
            <div class="purchase-circle" @click="onOpenModal($event, product)">
              <span class="mdi mdi-cart-outline"></span>
            </div>
            <div class="discount-circle" v-if="isOnSale(product)">
              Tillgänglig Rabatt
            </div>
            <p
              class="bookable-info has-text-centered"
              v-if="
                product.isBookable && formatDate(new Date(product.booking.date))
              "
            >
              Kommer
              {{ formatDate(new Date(product.booking.date)) }}
            </p>
          </figure>

          <div class="content">
            <h5 class="has-text-centered prime">{{ product.name }}</h5>
            <h5 class="is-secondary has-text-centered">
              {{ product.description || product.brand }}
            </h5>

            <div
              v-if="isOnSale(product)"
              class="is-size-7 tag is-absolute banner"
            >
              {{ getSaleBanner(product) }}
            </div>
            <div class="price" :class="{ 'is-promo': product.promoPrice }">
              <section>
                <div
                  :style="{
                    width: '100px',
                    marginTop: product.extra ? '4px' : '23px',
                  }"
                >
                  <h5 class="extra">
                    {{ product.extra ? product.extra : "" }}
                  </h5>

                  <small
                    v-if="
                      product.isBookable &&
                      formatDate(new Date(product.booking.date))
                    "
                    ><span class="warning-circle"></span>
                    <span class="circle-text">Förboka!</span>
                  </small>
                  <small v-else-if="product.available > 2"
                    ><span class="success-circle"></span>
                    <span class="circle-text">I lager</span>
                  </small>
                  <small v-else-if="product.available > 0"
                    ><span class="warning-circle"></span>
                    <span class="circle-text">Få i lager</span>
                  </small>
                  <small v-else-if="product.available === 0"
                    ><span class="no-circle"></span>
                    <span class="circle-text">Slut i lager</span>
                  </small>
                </div>
              </section>
              <section style="position: relative; bottom: -6px">
                <p v-if="product.promoPrice">{{ product.price }} kr</p>
                <p v-else>-</p>
                <p :class="{ 'has-banner-on-top': isOnSale(product) }">
                  {{ product.price }} kr
                </p>
              </section>
            </div>
          </div>
        </article>
      </div>
    </div>
    <button
      class="button is-primary is-horizontal-middle is-small"
      :class="{ 'is-loading': isLoadmoreLoading }"
      v-if="products.length > 0 && showMoreButton"
      @click="onLoadMoreProducts"
      :disabled="isLoadmoreButtonDisabled"
    >
      Ladda fler produkter
    </button>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
import ModalPopup from "./general/ModalPopup.vue";
import EditProductForm from "./EditProductForm.vue";
export default {
  name: "ProductList",
  components: { ModalPopup, EditProductForm },
  props: {
    products: {
      default: [],
    },
    isMobileScrollable: {
      default: false,
    },
    isLoadmoreButtonDisabled: {
      default: false,
    },
    isLoadmoreLoading: {
      default: false,
    },
    showMoreButton: {
      default: true,
    },
    columnsClass: {
      default:
        "is-half is-half-mobile is-one-third-tablet is-one-fifth-widescreen",
    },
    view: {
      default: "LIST-WINDOW",
    },
  },

  data() {
    return {
      quantityHasChanged: null,
      filteredProducts: [],
      selectedProduct: null,
      isModalOpen: false,
    };
  },
  methods: {
    formatDate(date) {
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();

      let today = new Date();
      const preOrderDatehasPassed = today >= date;

      return preOrderDatehasPassed ? "" : day + "/" + month + "/" + year;
    },
    isOnSale(product) {
      let found = false;

      if (this.$store.state.sales.length) {
        this.$store.state.sales.forEach((sale) => {
          let productFound = sale.products.find(
            (saleProdId) => saleProdId === product.id
          );

          if (productFound && !found) found = true;
        });
      }

      return found;
    },
    getSaleBanner(product) {
      let salesBanner = "";
      this.$store.state.sales.forEach((sale) => {
        let productFound = sale.products.find(
          (saleProdId) => saleProdId === product.id
        );

        if (productFound && salesBanner === "") salesBanner = sale.banner;
      });
      return salesBanner;
    },
    onLoadMoreProducts() {
      this.$emit("load-more");
    },
    onOpenModal(e, product) {
      e.stopPropagation();
      this.selectedProduct = product;
      this.isModalOpen = true;
    },
    onGoTo(e, product) {
      if (product.id !== this.$route.params.id) {
        this.$router
          .push({ name: "product", params: { id: product.id } })
          .catch(() => {
            this.$router.replace(`product/${product.id}`);
          });
      }
    },
    toggleActivationOf(product, isActive) {
      if (isActive) {
        ProductService.deActivateProduct(product.objectID)
          .then(() => {
            this.$buefy.snackbar.open(`Produkten har blivit avpublicerat.`);
          })
          .catch((error) => {
            console.log("något gick fel!", error);
          });
      } else {
        ProductService.activateProduct(product.objectID)
          .then(() => {
            this.$buefy.snackbar.open(`Produkten har publicerats!`);
          })
          .catch((error) => {
            console.log("något gick fel!", error);
          });
      }
    },
    onAddProduct(product) {
      this.$store.commit("addProductToCart", { product: product, quantity: 1 });
      const isAvailable = this.isAvailable(product);
      if (!isAvailable) {
        this.quantityHasChanged = product.objectID;
        setTimeout(() => {
          this.quantityHasChanged = null;
        }, 800);
      }
      this.isModalOpen = false;
    },
    getAvailability(product) {
      return product.available;
    },
    isAvailable(product) {
      const storeProduct = this.$store.state.cart.find(
        (p) => p.objectID === product.objectID
      );
      if (storeProduct) {
        return storeProduct.quantity === product.available;
      } else {
        return product.available === 0;
      }
    },
    getAvailabilityStatus(product) {
      if (product.sold === 0) {
        return "is-success";
      }
      const availability = product.sold / product.available;
      if (availability > 0.7) {
        return "is-danger";
      } else if (availability > 0.3) {
        return "is-warning";
      } else {
        return "is-success";
      }
    },
    compare(a) {
      if (a.isBookable) {
        return -1;
      }
      return 0;
    },
  },
  created() {},
  watch: {},
};
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";
.product-list {
  padding: 2rem 1rem;

  .has-1x5-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .bottom-actions {
    position: sticky;
    bottom: 0rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    background: white;
    border-top: 1px solid $grey-normal;
    left: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .card.is-list {
    box-shadow: none;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 0.25rem;
    .disabled {
      opacity: 0.5;
    }
    .has-banner-on-top {
      border-radius: 8px;
      padding: 0 0.75rem;
    }
    &:hover {
      cursor: pointer;
      p {
        font-weight: bold;
      }
    }
  }

  .banner {
    right: 0.5rem;
    bottom: 2.25rem;
    background: #2647e8;
    color: white;
    font-weight: bold;
  }

  .has-banner-on-top {
    color: #2647e8 !important;
    background: #e2ebff !important;
  }
  .is-frozen {
    display: none;
  }

  .is-bookable {
    border: 3px solid #ffd859;
  }

  .bookable-info {
    position: absolute;
    bottom: 1rem;
    left: 0rem;
    width: 100%;
    background: #ffd859;
    padding: 0.25rem 0.5rem;
    font-size: 11px;
    opacity: 0.9;
    color: black;
    font-weight: bold;
  }
  .bubble {
    width: 40px;
    height: 40px;
    background-color: #f3633b;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    transform: translateX(-50%);
    right: 1rem;
    top: 1rem;
    animation: pop-up 0.8s linear forwards;
    color: white;

    p {
      position: absolute;
      color: white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes pop-up {
    0% {
      //transform: translateY(30px);
      opacity: 0;
      transform: scale(0);
    }
    10% {
      opacity: 1;
      transform: scale(0) translateY(25px);
    }
    30% {
      opacity: 1;
      transform: scale(1.1) translateY(25px);
    }
    40% {
      opacity: 1;
      transform: scale(1) translateY(18px);
    }
    75% {
      opacity: 1;
      transform: scale(1) translateY(4px);
    }
    100% {
      opacity: 0;
      transform: scale(1) translateY(-10px);
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;

    &:first-of-type {
      margin-bottom: 0.25rem;
    }
    &.is-secondary {
      font-size: 12px;
      color: gray;
    }
  }

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .product-card {
    position: relative;
    margin-bottom: 1rem;
    //margin: 0 0.75rem 1rem 0.75rem;
    border-radius: 8px;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background: white;
    cursor: pointer;
    transition: transform 0.15s ease-in-out;

    .image {
      height: 120px;
      img {
        height: 100%;
        width: auto;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }
    }

    .prime {
      display: -webkit-box;
      height: 48px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.not-available {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:hover:not(.not-available) {
      transform: scale(1.04);
    }
    .content {
      padding: 0.5rem;
      .price {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;

        p:first-of-type {
          font-size: 12px;
          margin: 0;
          text-decoration: line-through;
          text-align: right;
        }
        p:last-of-type {
          text-align: right;
          font-size: 14px;
          color: white;
          padding: 0 0.5rem;
          background: whitesmoke;
          color: #4a4a4a;
          font-weight: bold;
          border-radius: 3px;
        }
        .extra {
          font-weight: normal;
          font-size: 14px;
        }
        &.is-promo {
          p:last-of-type {
            background: #f14668 !important;
            color: white !important;
          }
        }
      }
    }
    @media screen and (max-width: 992px) {
      .price {
        display: flex !important;
        right: 0;

        // bottom: 2.75rem;
        position: relative;
      }
      .banner {
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 1rem);
        bottom: 2.75rem;
      }
    }
  }

  .discount-circle {
    width: 60px;
    height: 60px;
    background: #2647e8;
    color: white;
    font-size: 8px;
    padding: 18px 10px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 0;
    right: 1rem;
    font-weight: bold;
  }
  .purchase-circle {
    width: 36px;
    height: 36px;
    background: $grey-light;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 1rem;
    padding: 3px 8px;
    &:hover {
      background: darken($grey-light, 10%);
    }
    span {
      font-size: 20px;
    }
  }

  .success-circle,
  .warning-circle,
  .no-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 99px;
  }
  .success-circle {
    background: green;
  }

  .no-circle {
    background: rgb(51, 51, 51);
  }

  .warning-circle {
    background: rgb(253, 250, 67);
  }
  .circle-text {
    display: inline-block;
    margin-left: 4px;
  }
  @media screen and (max-width: 992px) {
    .card-container {
      overflow: scroll !important;
      flex-wrap: nowrap !important;
    }
  }
}
</style>
