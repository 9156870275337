import HomeView from "./views/HomeView.vue";
import RegisterView from "./views/RegisterView.vue";
import CreateProductView from "./views/CreateProductView.vue";
import CreateProductsView from "./views/CreateProductsView.vue";
import AboutView from "./views/AboutView.vue";
import PolicyView from "./views/PolicyView.vue";
import CheckoutView from "./views/CartView.vue";
import ShopCheckoutSummary from "./views/ShopCheckoutSummary.vue";
import LoginView from "./views/LoginView.vue";
import MyOffersView from "./views/top-home-actions/MyOffersView.vue";
import MonthlyOffersView from "./views/top-home-actions/MonthlyOffersView.vue";
import PreorderView from "./views/top-home-actions/PreorderView.vue";
import WowView from "./views/top-home-actions/WowView.vue";
import ProductView from "./views/ProductView.vue";
import MembershipView from "./views/MembershipView.vue";
import AccountView from "./views/AccountView.vue";
import OrderView from "./views/OrderView.vue";
import MySubscriptionsView from "./views/MySubscriptionsView.vue";
import VerifyAccountView from "./views/VerifyAccountView.vue";
import HealthView from "./views/HealthView.vue";
import PromotionView from "./views/promotion/PromotionView.vue";
import AdminSalesView from "./views/admin/sales/AdminSalesView.vue";
import AdminUtilitiesView from "./views/admin/sales/AdminUtilitiesView.vue";
import AdminCreateSaleView from "./views/admin/sales/AdminCreateSaleView.vue";
import ProductSearchResultView from "./views/result/ProductSearchResultView.vue";

import Vue from "vue";
import Router from "vue-router";
import { auth } from "@/main";
import store from "@/store";

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/start",
      name: "home",
      component: HomeView,
    },
    {
      path: "/register",
      name: "register",
      component: RegisterView,
    },
    {
      path: "/product/:id",
      name: "product",
      component: ProductView,
    },
    {
      path: "/products/result",
      name: "productSearchResult",
      component: ProductSearchResultView,
    },
    {
      path: "/checkout",
      name: "checkout",
      component: CheckoutView,
    },
    {
      path: "promotion/:id",
      component: PromotionView,
      name: "promotion",
    },
    {
      path: "/account/membership",
      name: "membership",
      component: MembershipView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account",
      name: "account",
      component: AccountView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/order",
      name: "order",
      component: OrderView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/subscriptions",
      name: "mySubscriptions",
      component: MySubscriptionsView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/verify",
      component: VerifyAccountView,
      name: "accountVerify",
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/product/create/sello",
      name: "create-product",
      component: CreateProductView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/products/create/sello",
      name: "create-product",
      component: CreateProductsView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/checkout/summary",
      name: "checkoutSummary",
      component: ShopCheckoutSummary,
    },

    {
      path: "/login",
      name: "login",
      component: LoginView,
    },
    {
      path: "/my-offers",
      name: "myOffers",
      component: MyOffersView,
    },
    {
      path: "/monthly-offers",
      name: "monthlyOffers",
      component: MonthlyOffersView,
    },
    {
      path: "/preorder",
      name: "preorder",
      component: PreorderView,
    },
    {
      path: "/wow",
      name: "wow",
      component: WowView,
    },
    {
      path: "/policy",
      name: "policy",
      component: PolicyView,
    },
    {
      path: "/about",
      name: "about",
      component: AboutView,
    },
    {
      path: "/health",
      name: "health",
      component: HealthView,
    },
    {
      path: "/admin/sales/view",
      name: "admin-sales",
      component: AdminSalesView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/utilities/view",
      name: "admin-utilities",
      component: AdminUtilitiesView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/admin/sales/create",
      name: "admin-sales-create",
      component: AdminCreateSaleView,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
});

import AuthService from "@/services/AuthService";
var requiresAuth = {};
var requiresAdmin = {};
let isAdmin = false;
router.beforeEach(async (to, from, next) => {
  requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  auth.onAuthStateChanged(async function (currentUser) {
    store.commit("setAuthUser", currentUser);

    if (currentUser) {
      if (!store.state.user) {
        const userId = currentUser.uid;
        const resultIdToken = await currentUser.getIdTokenResult();

        isAdmin = resultIdToken.claims.isAdmin !== undefined;
        await store.commit("setUserIsAdmin", isAdmin);
        const user = await AuthService.getUserBy(userId);
        store.commit("setUser", user);
      }
    }
    store.commit("setAuthenticating", false);

    next();

    if (
      requiresAuth &&
      currentUser &&
      !currentUser.emailVerified &&
      to.name !== "accountVerify"
    ) {
      next("/verify");
    } else if (
      requiresAuth &&
      currentUser &&
      currentUser.emailVerified &&
      to.name === "accountVerify"
    ) {
      next("/");
      //next("/home");
    } else if (requiresAuth && currentUser) {
      if (requiresAdmin && !isAdmin) {
        next("/");
      } else {
        next();
      }
    } else if (requiresAuth && !currentUser) {
      next("/");
    }

    /*if (requiresAdmin && isAdmin) {
      console.log("is adminini");
      next();
    }*/
  });
});

export default router;
