<template>
  <div class="verify-account-view view">
    <div class="container box is-small has-text-centered">
      <animation-ship-floating></animation-ship-floating>
      <h2 class="is-bold">Nästan framme!</h2>
      <p class="is-size-5">
        Ett verifikations mejl är på väg till dig. Kolla din inkorg nu eller
        vänta i någon minut. Om du inte får något inom ett par minuter, försök
        då att skicka en ny. Kom ihåg att kolla
        <i class="fa fa-trash"></i> bland dina mejl.
      </p>
      <div class="columns">
        <div class="column is-full">
          <b-notification
            class="has-top-margin has-shadow"
            v-if="verificationSent"
            type="is-info"
            aria-close-label="Close notification"
            role="alert"
          >
            När du har verifierat dig kan du logga ut och in igen för att få
            tillgång till ditt konto.
          </b-notification>
        </div>
      </div>
      <button
        style="display: block"
        class="button is-primary has-top-margin is-horizontal-middle"
        v-bind:class="{ 'is-loading': btnIsLoading }"
        @click="onVerified"
      >
        JAG HAR VERIFIERAT MIG
      </button>
      <button
        style="display: block"
        class="button is-text has-top-margin is-horizontal-middle"
        v-bind:class="{ 'is-loading': btnIsLoading }"
        @click="requestVerificationMail()"
      >
        SKICKA NYTT VERIFIKATIONS MEJL
      </button>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import AnimationShipFloating from "@/components/animations/AnimationShipFloating.vue";

export default {
  name: "verifyAccountView",
  components: { AnimationShipFloating },
  data: function () {
    return {
      btnIsLoading: false,
      verificationSent: false,
    };
  },
  methods: {
    onVerified() {
      window.location.reload();
    },
    verRequestSuccessSnackbar() {
      this.$buefy.snackbar.open({
        message: `Ett verifieringsmejl har skickats till din registrerade E-post`,
        position: "is-bottom-left",
        queue: false,
      });
    },
    verRequestFailedSnackbar() {
      this.$buefy.snackbar.open({
        message: "Det gick inte att skicka ett nytt verifieringsmejl",
        duration: 5000,
        type: "is-danger",
        position: "is-bottom-left",
        actionText: "Försök igen",
        queue: false,
        onAction: () => {
          this.requestVerificationMail();
        },
      });
    },
    requestVerificationMail() {
      this.btnIsLoading = true;
      AuthService.requestVerificationMail()
        .then(() => {
          this.btnIsLoading = false;
          this.verRequestSuccessSnackbar();
          this.verificationSent = true;
        })
        .catch(() => {
          this.btnIsLoading = false;
          this.verRequestFailedSnackbar();
        });
    },
  },
};
</script>
