import { database, functions } from "@/main";
import { httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";

const COLLECTION = "subscriptions";

const SubscriptionService = {
  async getSubscriptions() {
    let themes = [];
    const themesRef = await getDocs(collection(database, COLLECTION));

    themesRef.forEach((gameRef) => {
      themes.push({
        id: gameRef.id,
        ...gameRef.data(),
      });
    });

    return themes;
  },

  getMySubscriptions() {
    const mySubscriptions = httpsCallable(functions, "mySubscriptions");
    return mySubscriptions();
  },
};

export default SubscriptionService;
