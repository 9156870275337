<template>
  <div class="bruktstorget">
    <h1 class="has-text-centered is-size-3">Om oss</h1>
    <div class="container has-2x-bottom-margin">
      <div class="row">
        <div class="column">
          <p class="subtitle">
            Vi förvaltar och erbjuder populära och efterfrågade leksaker. Vi
            fokuserar bland annat på att erbjuda samlingsobjekt som kan vara
            svåra att få tag på i sin vanliga och/eller lokala leksaksbutik.
          </p>
        </div>
      </div>
    </div>

    <h1 class="has-text-centered is-size-3">
      Välkommen till vår butik på brukstorget!
    </h1>

    <div class="container has-2x-bottom-margin">
      <div class="row">
        <div class="column">
          <p class="subtitle">
            Kom förbi och köp ditt första samlarföremål – Oavsett om du är vuxen
            eller barn så har vi något för alla! Kom och träffa butikens väktare
            Iris Yoko 🐕 (Hund av rasen Shiba Inu), häng, umgås, spela tv-spel
            och lyssna på musik. En dryck kanske? Vi har både kalla och varma
            drycker 👍.
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="column">
          <figure class="image is-256by256">
            <img class="" src="@/assets/images/svg/kartor.svg" />
          </figure>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="column">
          <p class="subtitle">
            Brukstorget finns på Birger Svenssons väg 16E-F i Varberg. Tar du
            motorvägen kör du av antingen norra eller centrala avfarten mot
            Varberg. Brukstorget ligger i anslutning till en rad olika butiker
            så som Jem & fix, Rusta och Babyboom. Besök
            <a
              href="https://brukstorget.se/verksamheter/kategori:butik"
              target="_blank"
              >Brukets hemsida</a
            >
          </p>
          <p class="subtitle">
            <strong class="is-bold">Företagsinformation</strong><br />
            Dennis Rojas Bustamante<br />Oxelstigen 8<br />
            432 46 VARBERG <br />Organisationsnummer: 9009134991
          </p>
          <p class="subtitle">
            <strong class="is-bold">Kontakt</strong><br />
            info@mobio.se
            <br />
            Tel: 0766 26 86 08
          </p>
          <br />
          <br />
          <div class="subtitle">
            Vi använder namnet <strong>Mobio</strong> som ett marknadsnamn för
            alla våra säljkanaler inklusive vår fysiska butik
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BruktsTorget",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    publish: async function () {},
  },
};
</script>

<style scoped lang="scss">
.bruktstorget {
  margin: 2rem 0;
  .subtitle {
    line-height: 1.8rem;
  }
}
</style>
