<template>
  <div class="preorder-view">
    <div class="container vending-machine-checkout" style="top: calc(25vh)">
      <p class="has-text-centered has-top-margin">
        Just nu visas förbokningar enbart på startsidan. Vänligen återkom senare
        när vi har uppdaterat denna sida.
      </p>
      <br />
      <RouterLink
        :to="{ name: 'home' }"
        class="button is-info is-horizontal-middle"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "preorder-view",
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
