<template>
  <div class="account-view view">
    <div class="container box is-small">
      <b-notification v-model="isActive" :closable="false">
        <div class="flex is-space-between">
          <div class="is-relative">
            <p class="is-vertical-middle">Få erbjudanden och nyhetsbrev</p>
          </div>
          <b-field>
            <b-switch v-model="newsLetter" type="is-info"></b-switch>
          </b-field>
        </div>
        <button
          class="button is-white has-top-margin"
          :class="{ 'is-loading': isLoading }"
          @click="onSave"
        >
          Spara
        </button>
      </b-notification>
      <br />
      <RouterLink :to="{ name: 'home' }" class="button is-text has-top-margin"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "AccountView",
  components: {},
  data: function () {
    return {
      isLoading: false,
      newsLetter: false,
    };
  },
  created() {
    const isSubscriber = this.$store.state.user.subscriber;
    this.newsLetter = Boolean(isSubscriber);
  },
  methods: {
    async onSave() {
      this.isLoading = true;
      let subscriber = "";
      if (this.newsLetter) {
        subscriber = "Subscriber";
      }
      await UserService.updateUsersAttributes({ subscriber: subscriber });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.account-view {
}
</style>
