<template>
  <div class="cart-view view">
    <ModalPopup v-if="openTerms" @close-modal="openTerms = false">
      <template v-slot:header>
        <h4 ref="BUSINESSINFORMATION" class="has-top-margin">
          <strong>FÖRETAGSINFORMATION</strong>
        </h4>
      </template>
      <template v-slot:content>
        <section class="scroll">
          <br />
          <div class="subtitle"><strong>Om Mobio</strong></div>
          <p>
            Företagets juridiska namn är Dennis Rojas Bustamante med
            organisationsnummer 9009134991. Företaget innehar F-skatt. Företaget
            har sitt säte i VARBERG med postadress Oxelstigen 8. Önskar du att
            komma i kontakt med oss, skicka ett mail till info@mobio.se med ditt
            ärende och dina kontaktuppgifter så hör vi av oss inom kort.
          </p>
          <br />

          <div class="subtitle"><strong>Beställning</strong></div>
          <p>
            När du slutfört din beställning skickas en orderbekräftelse till din
            e-postadress. I bekräftelsen finner du alla uppgifter om produkter,
            pris, fakturerings- och leveransadress. Är något fel i
            orderbekräftelsen ska du omedelbart kontakta oss via e-post till
            info@mobio.se.
          </p>
          <br />

          <h4 ref="PRODUCTINFORMATION_AND_PRICE">
            <strong>PRODUKTINFORMATION OCH PRIS</strong>
          </h4>
          <br />
          <div class="subtitle"><strong>Produktinformation</strong></div>
          <p>
            Vi reserverar oss för eventuella tryckfel på denna webbplats samt
            slutförsäljning av produkter. Vi garanterar inte att bilderna
            återger produkternas exakta utseende då en viss färgskillnad kan
            förekomma beroende på bildskärm, fotokvalitet samt upplösning. Vi
            försöker alltid på bästa sätt att exponera produkterna så korrekt
            som möjligt.
          </p>
          <br />
          <div class="subtitle"><strong>Priser</strong></div>
          <p>
            Alla priser i butiken anges i SEK och alla priser är inklusive 25%
            moms. Vi reserverar oss för prisändringar orsakat av prisändring
            från leverantör, feltryck i prislistan samt felaktigheter i priser
            beroende på felaktig information och förbehåller oss rätten att
            justera priset.
          </p>
          <br />
          <div class="subtitle">
            <strong>Rabatter och Erbjudanden</strong>
          </div>
          <p>
            Rabatter och erbjudanden kan utnyttjas endast om villkoren för
            erbjundandet uppfylls. Läs även sektionen "Vid nyttjande av din
            ångerrätt".
          </p>
          <br />

          <h4 ref="SALE_AND_POLICY" name="SALE_AND_POLICY">
            <strong>FÖRSÄLJNINGS OCH LEVERANSVILLKOR</strong>
          </h4>
          <br />
          <div class="subtitle"><strong>Leverans</strong></div>
          <p>
            Våra normala leveranstider är 1-4 dagar. OBS! Beställningar lagda på
            helger skickas tidigast på måndagen efter. Vi skickar automatiskt
            per e-post en leveransbekräftelse tillsammans med ett spårnummer när
            ditt gods lämnar oss. Vi väljer Postnord eller Schenker för frakt.
            Fraktsätt och val av fraktbolag är beroende på vilken storlek och
            vikt det är på godset som ska fraktas. Om förseningar i leveransen
            skulle uppstå (utan att vi har meddelat dig om längre leveranstid)
            ska du kontakta oss på e-postadress: info@mobio.se.
          </p>
          <br />
          <section class="has-text-left-by-one-rem">
            <strong>Postnord</strong>
            <p>
              Din order kommer skickas i form av ett postpaket eller varubrev
              baserat på orderns storlek, vikt och innehåll. Paketen brukar
              vanligtvis levereras inom 1-2 arbetsdagar. PostNord kommer avgöra
              om ditt paket levereras till ditt lokala ombud eller hem till din
              brevlåda. Vi har dessvärre ingen kontroll över detta, men varubrev
              ska i regel landa i brevlådan medan postpaket levereras till
              ombudet.
            </p>
            <br />
            <strong>Schenker</strong>
            <p>
              Din order kommer skickas i form av ett postpaket. Leveranstiden är
              vanligtvis cirka 7 arbetsdagar. Paket levereras till ditt lokala
              Schenkerombud.
            </p>
            <br />
          </section>

          <div class="subtitle"><strong>Ångerrätt</strong></div>

          <p>
            Vid köp av varor på mobio.se har du som kund enligt svensk lag 14
            dagars ångerätt (distansavtalslagen). Vi erbjuder däremot 30 dagars
            ångerrätt som gäller från det att du har tagit emot en vara som du
            har beställt. Återbetalning kan först efter mottagen och godkänd
            retur.
          </p>

          <br />

          <section class="has-text-left-by-one-rem">
            <strong>Vid nyttjande rabatter och erbjudanden: </strong>

            <li>
              Om produkter som ingår i ett erbjudande hävs i ett köp innebär det
              att villkoren inte längre uppfylls. Det innebär att produkterna
              kommer att prisjusteras efter ordinarie pris. Gåvor skall också
              returneras.
              <br />
              <br />
            </li>

            <strong>Vid nyttjande av din ångerrätt: </strong>

            <li>
              Du måste meddela att du ångrar dig. Meddelandet ska skickas till
              oss info@mobio.se. I ditt meddelande ska ditt namn, din adress,
              e-postadress, ordernumret samt vilka varor som returneringen
              gäller framgå klart och tydligt.
            </li>
            <li>
              Du bör omedelbart och senast inom 30 dagar efter ångermeddelandet
              returnera produkterna till oss.
            </li>
            <li>
              Du står för returfrakt, leverans och skick på produkterna vid
              retur, produkterna bör därför skickas välpaketerade och i
              ursprunglig förpackning.
            </li>
            <li>
              På återbetalningsbeloppet förbehåller vi oss rätten att dra av en
              summa motsvarande värdeminskningen jämfört med varans ursprungliga
              värde vid använd eller skadad produkt.
            </li>
            <li>
              Om en Swish betalning har använts som betalmetod kan endast en
              återbetalning ske till ett personligt bankkonto.
              <br />
              <br />
            </li>
            <strong>Ångerrätten gäller inte vid:</strong>
            <li>
              Produkter som på grund av hälso- eller hygienskäl har förseglats
              (plomberats) och där förseglingen (plomberingen) har brutits av
              dig.
            </li>
            <li>
              Produkter som har karaktär av förseglad ljud- eller bildupptagning
              och där förseglingen har brutits av dig.
            </li>
            <li>
              Specialtillverkad produkt, som har skräddarsytts särskilt åt dig
              eller har en tydlig personlig prägel efter dina önskemål.
            </li>
            <li>
              Tjänster som har fullgjorts och där du uttryckligen har samtyckt
              till att tjänsten påbörjas utan ångerrätt.
            </li>
            <li>Varor som snabbt kan försämras, exempelvis livsmedel.</li>
            <li>
              Lösnummer av tidningar eller tidskrifter.

              <br />
              <br />För mer om den lagstiftade ångerrätten,
            </li>
            Se
            <a
              target="_blank"
              href="http://www.konsumentverket.se/for-foretag/konsumentratt-for-foretagare/om-kunden-angrar-sitt-kop/"
            >
              <strong>här</strong>.
            </a>
          </section>

          <br />
          <h4 ref="COMPLAINTS"><strong>REKLAMATIONER</strong></h4>
          <br />
          <div class="subtitle"><strong>Allmän information</strong></div>
          <p>
            Vi besiktigar alla produkter innan dessa skickas till dig. Skulle
            produkten ändå vara skadad eller felexpedierad när den anländer åtar
            vi oss i enlighet med gällande konsumentskyddslagstiftning att
            kostnadsfritt åtgärda felet. Du måste alltid kontakta oss för ett
            godkännande innan du returnerar en defekt vara. Klagomålet ska
            skickas omedelbart efter att defekten har upptäckts.
          </p>
          <br />
          <section class="has-text-left-by-one-rem">
            <strong>Hur går du tillväga vid reklamation?</strong>
            <li>
              Eventuella fel och defekt ska alltid reklameras till info@mobio.se
              där du anger ditt namn, din adress, e-postadress, ordernummer och
              en beskrivning av felet.
            </li>
            <li>
              Om det inte lyckas oss att åtgärda felet eller leverera en
              liknande produkt, återbetalar vi dig för den defekta produkten i
              enlighet med gällande konsumentskyddslagstiftning. Vi står för
              returfrakt vid godkända reklamationer.
            </li>
            <li>
              Vi förbehåller oss rätten att neka en reklamation om det visar sig
              att varan inte är felaktig i enlighet med gällande
              konsumentskyddslagstiftning. Vid reklamationer följer vi
              riktlinjer från Allmänna Reklamationsnämnden, se arn.se.
            </li>
          </section>
          <br />

          Om det inte lyckas oss att åtgärda felet eller leverera en liknande
          produkt, återbetalar vi dig för den defekta produkten i enlighet med
          gällande konsumentskyddslagstiftning. Vi står för returfrakt vid
          godkända reklamationer.<br /><br />
          Vi förbehåller oss rätten att neka en reklamation om det visar sig att
          varan inte är felaktig i enlighet med gällande
          konsumentskyddslagstiftning. Vid reklamationer följer vi riktlinjer
          från Allmänna Reklamationsnämnden, se arn.se.

          <br />
          <br />
          <div class="subtitle"><strong>Ansvarsbegränsning</strong></div>
          <p>
            <strong
              >Vi tar inget ansvar för indirekta skador som kan uppstå på grund
              av produkten.</strong
            >
            Vi accepterar inget ansvar för förseningar/fel till följd av
            omständigheter utanför företagets rådande (Force Majeure). Dessa
            omständigheter kan exempelvis vara arbetskonflikt, eldsvåda, krig,
            myndighetsbeslut, förminskad eller utebliven leverans från
            leverantör. Vidare tas inget ansvar för eventuella förändringar på
            produkter/produktegenskaper som ändrats av respektive leverantör och
            andra faktorer utanför vår kontroll.
          </p>
          <br />
          <div class="subtitle"><strong>Olöst Tvist</strong></div>
          <p>
            I tillfälle av att tvist inte kan lösas i samförstånd med företagets
            kundtjänst och kunden, kan du som kund vända dig till Allmänna
            Reklamationsnämnden, se arn.se. För boende i ett annat EU-land än
            Sverige kan man lämna klagomål online via EU-kommissionens plattform
            för medling i tvister, se http://ec.europa.eu/consumers/odr Vid
            eventuell tvist följer vi beslut från ARN eller motsvarande
            tvistlösningsorgan. Tvist gällande tolkningen eller tillämpningen av
            dessa allmänna villkor ska tolkas i enlighet med svensk rätt och
            lag.
          </p>
          <br />

          <h4 ref="DATA_AND_PERSONINFO">
            <strong>DATA OCH PERSONUPPGIFTER</strong>
          </h4>
          <br />
          <div class="subtitle">
            <strong>Information om Cookies</strong>
          </div>
          <p>
            Enligt lag om elektronisk information ska besökare på en webbplats i
            integritetssyfte få information om att cookies används.
            Informationen i cookien är möjlig att använda för att följa en
            användares surfande. Cookie är en liten textfil som webbplatsen du
            besöker begär att få spara på din dator för att ge tillgång till
            olika funktioner. Det går att ställa in sin webbläsare så att den
            automatiskt nekar cookies. Mer information kan man hitta på Post och
            telestyrelsens hemsida.
          </p>
          <br />
          <div class="subtitle"><strong>Personuppgifter</strong></div>
          Genom att handla hos Mobio accepterar du vår dataskyddspolicy och vår
          behandling av dina personuppgifter. Vi värnar om din personliga
          integritet och samlar inte in fler uppgifter än nödvändigt för att
          behandla din beställning. Vi säljer eller vidareger aldrig dina
          uppgifter till tredjepart utan rättslig grund. Mobio är ansvarig för
          behandlingen av personuppgifter som du lämnat till oss som kund. Dina
          personuppgifter behandlas av oss för att kunna hantera din beställning
          samt i de tillfällen då du har önskat nyhetsbrev eller
          kampanjerbjudanden - för att kunna anpassa marknadsföringen åt dina
          individuella behov. Nedan information är en summering av hur vi i
          enlighet med
          <a
            href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/"
            target="_blank"
            >dataskyddsförordningen</a
          >
          (GDPR) lagrar och behandlar dina uppgifter. <br /><br />
          <section class="has-text-left-by-one-rem">
            <ul>
              <li>
                <strong>Vad är en personuppgift?</strong><br />
                En personuppgift är all information som direkt eller indirekt
                kan hänföras till en fysisk person.<br /><br />
              </li>
              <li>
                <strong>Vilka uppgifter lagrar vi?</strong><br />
                För att kunna hantera din beställning samt svara på frågor
                relaterat till din order (kundtjänst) lagrar vi ditt förnamn-
                och efternamn, adress, telefonnummer, e-postadress, ip-adress
                och köphistorik.<br /><br />
                Dina uppgifter lagras så länge vi har en rättslig grund att
                behandla dina uppgifter, exempelvis för att fullfölja avtalet
                mellan oss eller för att efterleva en rättslig förpliktelse
                enligt exempelvis bokföringslagen.<br /><br />
              </li>
              <li>
                <strong>Rättslig grund</strong><br />
                I samband med ett köp behandlas dina personuppgifter för att
                fullfölja avtalet med dig.<br />
                Marknadsföring, kampanjer och liknande utskick sker efter
                samtycke från dig.<br /><br />
              </li>
              <li>
                <strong>Vilka uppgifter delas och med vilket syfte?</strong
                ><br />
              </li>
              <section class="has-text-left-by-one-rem">
                <li><strong>Betalleverantör</strong></li>
                <li>
                  Vid genomförande av köp, delas information med vår
                  betalleverantör. Det som lagras är förnamn, efternamn, adress,
                  e-postadress och telefonnummer. Väljer du att betala med
                  faktura sparas även personnummer hos betalleverantören.
                  Informationen sparas för att kunna genomföra köpet och för att
                  skydda parterna mot bedrägeri.
                  <br />
                  De betalleverantörer (betaltjänster) som vi använder oss av
                  är: Klarna, Swish, Bankgiro.<br /><br />
                </li>
                <li>
                  <strong>Fraktbolag</strong><br />
                  För att kunna leverera dina beställningar och slutföra vårt
                  avtal måste vi dela med specifik information med fraktbolaget.
                  Det som delas med fraktbolaget är förnamn, efternamn samt
                  adressuppgifter för leverans. E-postadress och/eller
                  mobilnummer kan även komma att delas med fraktbolaget för
                  avisering.<br />
                  De fraktbolag vi samarbetar med är Postnord och Schenker.<br /><br />
                </li>
                <li>
                  <strong>Nyhetsbrev</strong><br />
                  Har du valt att prenumerera på vårt nyhetsbrev delas förnamn,
                  efternamn och e-postadress med vår nyhetsbrevsleverantör.
                  Detta för att kunna hålla dig uppdaterad med information och
                  erbjudanden i marknadsföringssyfte.<br />
                  Vi använder för utskick av nyhetsbrev. <br /><br />
                </li>
              </section>
              <li>
                <strong>Rätten till tillgång</strong><br />
                Du har rätt att få utdrag av all information som finns om dig
                hos oss. Utdrag levereras elektroniskt i ett läsbart format.<br /><br />
              </li>
              <li>
                <strong>Rätt till rättelse</strong>
                <br />
                Du har rätt att be oss uppdatera felaktig information eller
                komplettera information som är bristfällig.<br /><br />
              </li>
              <li>
                <strong>Rätten att bli glömd</strong><br />
                Du kan när som helst be att uppgifterna som avser dig raderas.
                Det finns få undantag till rätten till radering, som till
                exempel om det ska behållas för att vi måste uppfylla en
                rättslig förpliktelse (exempelvis enligt bokföringslagen).<br /><br />
              </li>
              <li>
                <strong>Ansvarig för dataskydd</strong><br />
                Mobio är ansvarig för lagring och behandling av personuppgifter
                i webbutiken och ser till att reglerna efterföljs.<br /><br />
              </li>
              <li>
                <strong>Så skyddar vi dina personuppgifter</strong><br />
                Vi använder oss av industristandarder som SSL/TLS och envägs
                hash-algoritmer för att lagra, behandla och kommunicera känslig
                information som exempelvis personuppgifter och lösenord på ett
                säkert sätt.

                <br />
              </li>
            </ul>
          </section>
          <br />

          <h4 ref="OTHER"><strong>ÖVRIGT</strong></h4>
          <br />
          <div class="subtitle">
            <strong>Ändringar till de Allmänna Villkoren</strong>
          </div>
          <p>
            Vi förbehåller oss rätten att när som helst företa ändringar i
            villkoren. Ändringar av villkoren kommer att publiceras online på
            webbplatsen. De ändrade villkoren anses för accepterade i samband
            med order eller besök på webbplatsen.
          </p>
          <br />

          <i>Senast uppdaterad: 2023-07-25</i>
        </section>
      </template>
    </ModalPopup>
    <div class="container vending-machine-checkout wrapper">
      <CartPayMethodSelector
        @on-selected-method="onOnSelectedMethod"
        v-if="this.$store.state.cart.length !== 0"
      />
      <div class="notification is-danger" v-if="error">
        {{ error }}
      </div>

      <section
        v-if="!swishPaymentRequestIsLoading && !stripePaymentRequestIsLoading"
      >
        <p
          class="has-text-centered has-top-margin"
          v-if="this.$store.state.cart.length === 0"
        >
          Du har inget i varukorgen.
        </p>

        <div
          class="notification is-warning has-bottom-margin has-half-top-margin"
          v-if="
            $store.state.authUser === null &&
            this.$store.state.cart.length !== 0 &&
            selectedMethod === 'STRIPE'
          "
        >
          Du behöver vara inloggad för att betala med kort. Skapa ett konto och
          börja samla poäng idag!
        </div>
        <section
          class="has-bottom-margin dropdown-card"
          v-if="$store.state.cart.length !== 0"
        >
          <div
            class="is-flex has-space-between dropdown-card-header"
            @click="
              dropdownCard === 1 ? (dropdownCard = 0) : (dropdownCard = 1)
            "
          >
            <p class="dropdown-card-title">Varukorg</p>
            <p class="dropdown-card-title">
              <img
                src="@/assets/images/svg/dropdown-down.svg"
                width="24"
                :class="{ 'rotate-180': dropdownCard === 1 }"
              />
            </p>
          </div>
          <section class="dropdown-card-content" v-if="dropdownCard === 1">
            <li v-for="product in $store.state.cart" :key="product.objectID">
              <span>{{ product.name }}</span>
              <div class="amount-input">
                <b-numberinput
                  :editable="false"
                  style="width: fit-content"
                  class="has-half-bottom-margin"
                  min="0"
                  :max="product.available"
                  size="is-small"
                  controls-position="compact"
                  controls-rounded
                  v-model="product.quantity"
                  @input="onUpdateProductQuantity($event, product)"
                ></b-numberinput>
              </div>
            </li>
            <section
              class="has-text-centered has-top-margin"
              v-if="shipping === 0"
            >
              <strong>FRI FRAKT!</strong>
            </section>
            <section style="" class="has-text-centered has-top-margin" v-else>
              <strong>59 kr för frakt är inkluderat i totalpriset</strong>
            </section>
            <div class="buttons is-centered has-top-margin">
              <button
                v-if="selectedMethod === 'SWISH'"
                class="button is-primary is-small is-centered is-rounded"
                @click="dropdownCard = 2"
              >
                Fortsätt
              </button>
            </div>
          </section>
        </section>
        <section v-if="selectedMethod === 'SWISH'">
          <section class="dropdown-card" v-if="$store.state.cart.length !== 0">
            <div
              class="is-flex has-space-between dropdown-card-header"
              @click="
                dropdownCard === 2 ? (dropdownCard = 0) : (dropdownCard = 2)
              "
            >
              <p class="dropdown-card-title">Kundinformation</p>
              <p class="dropdown-card-title">
                <img
                  src="@/assets/images/svg/dropdown-down.svg"
                  width="24"
                  :class="{ 'rotate-180': dropdownCard === 2 }"
                />
              </p>
            </div>
            <section class="dropdown-card-content" v-if="dropdownCard === 2">
              <b-field label="Förnamn" :type="name">
                <b-input v-model="name" maxlength="32"></b-input>
              </b-field>
              <b-field label="Efternamn" :type="surname">
                <b-input v-model="surname" maxlength="32"></b-input>
              </b-field>
              <b-field label="Gatuadress" :type="adress">
                <b-input v-model="adress" maxlength="64"></b-input>
              </b-field>

              <div class="is-flex has-space-between">
                <b-field label="Postnummer" :type="validator.zipcode">
                  <b-input
                    v-model="zipcode"
                    expanded
                    @input="onZipcodeChange"
                    maxlength="5"
                  ></b-input>
                </b-field>
                <b-field label="Postort" :type="onValidateText(city)">
                  <b-input v-model="city" maxlength="32" expanded></b-input>
                </b-field>
              </div>
              <b-field label="Telefonnummer" :type="validator.phone">
                <b-input
                  v-model="phone"
                  maxlength="32"
                  @input="onPhoneChange"
                ></b-input>
              </b-field>
              <b-field label="E-postadress" :type="validator.email">
                <b-input
                  v-model="email"
                  maxlength="64"
                  @input="onEmailChange"
                ></b-input>
              </b-field>
            </section>
          </section>
        </section>
        <section class="has-top-margin">
          <div class="field" v-if="$store.state.cart.length !== 0">
            <b-checkbox type="is-info" v-model="acceptTerms"
              >Jag har läst och godkänner
              <a @click="openTerms = true">Mobios Köpvillkor</a></b-checkbox
            >
          </div>
        </section>
        <p
          class="has-text-centered is-size-4 has-text-black has-top-margin"
          v-if="$store.state.cart.length !== 0"
        >
          {{ total + shipping }} kr
        </p>
        <p
          class="has-text-centered is-size-7"
          v-if="$store.state.cart.length !== 0"
        >
          Totalt {{ total + shipping }} kr inkl {{ vat }} kr moms.
        </p>

        <div class="buttons is-centered has-top-margin">
          <button
            class="button is-text"
            @click="onBack"
            :class="{
              'is-loading':
                stripePaymentRequestIsLoading || swishPaymentRequestIsLoading,
            }"
          >
            Tillbaka
          </button>
          <button
            class="button is-dark"
            :class="{
              'is-loading':
                stripePaymentRequestIsLoading || swishPaymentRequestIsLoading,
            }"
            @click="onPay"
            v-if="$store.state.cart.length !== 0"
            :disabled="!onReadyToPay()"
          >
            Betala
          </button>
        </div>
      </section>
      <section
        v-if="stripePaymentRequestIsLoading"
        class="is-horizontal-middle"
        style="display: inline-block"
      >
        <section style="display: inline-block">
          <div
            class="loader"
            style="width: 3em; height: 3em; left: calc(50% - 1.5rem)"
          ></div>
          <p
            class="subtitle has-top-margin has-text-centered has-half-bottom-margin"
          >
            Kontaktar betaltjänst
          </p>
          <p class="subtitle has-text-centered">Vänligen vänta...</p>
        </section>
      </section>
      <section v-if="swishPaymentRequestIsLoading">
        <div class="column is-full">
          <div class="is-relative">
            <img
              src="@/assets/images/svg/mobile-swish.svg"
              class="has-half-bottom-margin has-top-margin is-horizontal-middle"
              width="300"
            />
            <div></div>
            <div
              class="loading-dots is-horizontal-middle"
              style="display: inline-block"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <h5 class="is-bold is-full-payment has-text-centered">
              Totalsumma: {{ total + shipping }} kr
            </h5>
            <i class="fas fa-spinner fa-pulse is-loading"></i>
          </div>
          <h5 class="has-half-bottom-margin has-text-centered">
            Det finns en pågående betalning.
          </h5>
          <div class="has-text-centered">
            1. Öppna swish-appen på din mobila enhet.<br />
            2. Kontrollera betalningen och godkänn.<br />
            3. Klart.
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SwishService from "@/services/SwishService";
import { onSnapshot, doc } from "firebase/firestore";
import { database } from "@/main";
import ModalPopup from "@/components/general/ModalPopup.vue";
import CartPayMethodSelector from "@/components/navigation/CartPayMethodSelector.vue";
import StripeService from "@/services/stripeService";
import { stripePayments } from "@/main";
import { createCheckoutSession } from "@stripe/firestore-stripe-payments";
import PromotionService from "@/services/PromotionService";
import SaleService from "@/services/SaleService";

export default {
  name: "HomeView",
  components: { ModalPopup, CartPayMethodSelector },
  data() {
    return {
      unsubscription: null,
      selectedMethod: "SWISH",
      swishPaymentRequestIsLoading: false,
      stripePaymentRequestIsLoading: false,
      swishPaymentId: null,
      swishOrder: null,
      dropdownCard: 1,
      total: 0,
      vat: 0,
      shipping: 0,
      discount: 0,
      validator: {
        phone: "",
        email: "",
        zipcode: "",
      },
      name: "",
      surname: "",
      adress: "",
      zipcode: "",
      city: "",
      email: "",
      phone: "",
      acceptTerms: false,
      openTerms: false,
      error: "",
    };
  },
  async created() {
    const saleProducts = await SaleService.getUpdatedProductsFrom(
      this.$store.state.sales,
      this.$store.state.cart
    );
    //this.displayProducts = saleProducts.productsOnSale;
    this.total = saleProducts.total;
    this.vat = saleProducts.totalVAT;
    this.shipping = saleProducts.shipping;
    this.discount = saleProducts.amountOff;
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  watch: {
    async cart(newVal) {
      const saleProducts = await SaleService.getUpdatedProductsFrom(
        this.$store.state.sales,
        newVal
      );

      //this.displayProducts = saleProducts.productsOnSale;
      this.total = saleProducts.total;
      this.vat = saleProducts.totalVAT;
      this.shipping = saleProducts.shipping;
      this.discount = saleProducts.amountOff;
    },
  },
  methods: {
    getMaxProductQuantity(product) {
      const foundProduct = this.$store.state.cart.find(
        (prod) => prod.objectID === product.objectID
      );
      const amount = foundProduct ? foundProduct.quantity : 0;
      const amountLeft = product.available - amount;

      return amountLeft;
    },
    onOnSelectedMethod(method) {
      this.selectedMethod = method;
    },
    validatePhone() {
      const phone = this.phone;
      const phoneRegex = /^((\+|00)46|0)7[02369]\d{7}$/;

      return phoneRegex.test(phone);
    },
    validateEmail() {
      const email = this.email;
      // eslint-disable-next-line
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return emailRegex.test(email);
    },
    validateZipcode() {
      const zipcode = this.zipcode;
      const zipcodeRegex = /^[1-9][0-9]{2}\s?[0-9]{2}$/;
      return zipcodeRegex.test(zipcode);
    },
    onValidateText(text) {
      const isValid = text.length > 1;
      return isValid ? "is-success" : "is-danger";
    },
    onPhoneChange() {
      const isValid = this.validatePhone();
      this.validator.phone = isValid ? "is-success" : "is-danger";
    },
    onEmailChange() {
      const isValid = this.validateEmail();
      this.validator.email = isValid ? "is-success" : "is-danger";
    },
    onZipcodeChange() {
      const isValid = this.validateZipcode();
      this.validator.zipcode = isValid ? "is-success" : "is-danger";
    },
    convertPhoneToSwishFormat(phone) {
      if (phone.startsWith("+")) {
        return phone.replace(/^\+/, "");
      }

      if (phone.startsWith("0046")) {
        return phone.replace(/^0046/, "46");
      }

      return phone.replace(/^0/, "46");
    },
    onReadyToPay() {
      if (this.selectedMethod === "STRIPE" && this.acceptTerms) {
        return this.$store.state.authUser;
      }
      return (
        this.validateEmail() &&
        this.validatePhone() &&
        this.validateZipcode() &&
        this.onValidateText(this.surname) &&
        this.onValidateText(this.name) &&
        this.onValidateText(this.adress) &&
        this.onValidateText(this.city) &&
        this.acceptTerms
      );
    },
    async payWithStripe() {
      this.stripePaymentRequestIsLoading = true;

      const stripeProducts = this.$store.state.cart.map((product) => {
        return {
          price: product.sPriceId,
          quantity: product.quantity,
        };
      });

      /*let amount = 0;
      this.$store.state.cart.forEach((product) => {
        amount += product.price * product.quantity;
      });*/
      if (this.shipping > 0) {
        stripeProducts.push({
          price: "price_1Ne4dAGX2bJq55ekRZkbhlWG",
          quantity: 1,
        });
      }

      let date = new Date();
      date.setMinutes(date.getMinutes() + 30);
      let epochTime = Math.floor(date / 1000);

      const promo = await PromotionService.createStripePromotionCode(
        this.$store.state.cart
      );
      const promotion = promo.data.promotion ? promo.data.promotion : "";

      // TODO create service for generating promotion
      const session = await createCheckoutSession(stripePayments, {
        line_items: stripeProducts,
        mode: "payment",
        expires_at: epochTime,
        currency: "SEK",
        allow_promotion_codes: false,
        promotion_code: promotion,
        success_url: `${window.location.origin}/#/checkout/summary?session_id={CHECKOUT_SESSION_ID}&method=card`,
      });

      await StripeService.reservProducts(
        this.$store.state.cart.map((p) => {
          const product = JSON.parse(JSON.stringify(p));
          delete product._highlightResult;
          delete product.lastmodified;
          return product;
        }),
        session.id
      )
        .then(() => window.location.assign(session.url))
        .catch((error) => {
          this.error = error;
        });

      this.stripePaymentRequestIsLoading = false;
    },
    async onPay() {
      if (this.selectedMethod === "STRIPE") {
        this.payWithStripe();
      } else {
        this.swishPaymentRequestIsLoading = true;
        let order = {
          userId: null,
          customer: {
            email: this.email,
            firstname: this.name,
            lastname: this.surname,
            address: this.adress,
            zip: this.zipcode,
            city: this.city,
            phone: this.convertPhoneToSwishFormat(this.phone),
            mobile: this.convertPhoneToSwishFormat(this.phone),
          },
          products: this.$store.state.cart.map((p) => {
            const product = JSON.parse(JSON.stringify(p));
            delete product._highlightResult;
            delete product.lastmodified;
            return product;
          }),
          total: this.total + this.shipping,
        };

        await SwishService.createOrderBy(order)
          .then((response) => {
            this.swishPaymentId = response.data.paymentId;

            this.swishPaymentId !== null
              ? (this.swishPaymentRequestIsLoading = true)
              : (this.swishPaymentRequestIsLoading = false);

            this.swishPaymentListener(this.swishPaymentId);
          })
          .catch((error) => {
            this.swishPaymentRequestIsLoading = false;
            this.error = error;
          });
      }
    },
    async swishPaymentListener(id) {
      const q = doc(database, "swishPayments", id);

      this.unsubscription = onSnapshot(q, (doc) => {
        const resp = doc.data();
        let status = resp.status;

        if (!this.swishOrder && id) {
          this.swishOrder = SwishService.getSwishPaymentBy(id);
        }

        switch (status) {
          case "PAID":
            this.swishPaymentRequestIsLoading = false;
            if (this.swishOrder) {
              this.$router.push({
                name: "checkoutSummary",
                query: { payment: "SWISH", transaction: id },
              });
              this.unsubscription();
              this.unsubscription = null;
            }
            break;
          case "CANCELLED":
            this.swishPaymentRequestIsLoading = false;
            this.unsubscription();
            this.unsubscription = null;
            break;
          case "TIMEOUT":
            this.swishPaymentRequestIsLoading = false;
            this.unsubscription();
            this.unsubscription = null;
            break;
          case "ERROR":
            this.swishPaymentRequestIsLoading = false;
            this.unsubscription();
            this.unsubscription = null;
            break;
          case "PENDING":
            this.swishPaymentRequestIsLoading = true;
            break;
          default:
            this.swishPaymentRequestIsLoading = false;
            this.unsubscription();
            this.unsubscription = null;
        }
      });
    },
    onBack() {
      this.$router.push({ name: "home" });
    },
    onUpdateProductQuantity(event, product) {
      this.$store.commit("setProductToCart", {
        product: product,
        quantity: event,
      });

      if (product.quantity === 0) {
        this.$store.commit("removeQuantityFromProduct", product);
      }
    },
    setCartIsHighlighted() {
      this.$store.commit(
        "setCartIsHighlighted",
        !this.$store.state.cartIsHighlighted
      );
    },
  },
};
</script>

<style scoped lang="scss">
.cart-view {
  .checkbox {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  li {
    //padding: 0 0.75rem;
    span {
      margin-top: 6px;
    }
  }
  li:nth-child(even) {
    //margin-bottom: 2.5rem !important;
    background-color: rgb(241, 241, 241);
  }
  .dropdown-card {
    cursor: pointer;
    width: 100%;
    background-color: rgb(241, 241, 241);
    border-radius: 8px;

    .dropdown-card-header {
      padding: 1rem 1.5rem;
      &:hover {
        background-color: rgb(238, 238, 238);
        border-radius: 8px;
        filter: brightness(95%);
      }
    }

    .dropdown-card-content {
      padding: 1rem 1.5rem;
    }
    .dropdown-card-title {
      //margin-bottom: 1rem;
      color: rgb(53, 53, 53);
      font-weight: bold;
      line-height: 2;
    }
  }
  .rotate-180 {
    transform: rotate(180deg);
  }

  .loading-dots {
    span:after {
      content: " ";
      height: 20px;
      width: 20px;
      background: rgb(40, 114, 187);
      display: inline-block;
      margin: 0.5rem;
      border-radius: 50%;
    }

    span:first-of-type:after {
      animation: 1.5s dots 0.5s infinite;
    }
    span:nth-child(2):after {
      animation: 1.5s dots 1s infinite;
    }
    span:nth-child(3):after {
      animation: 1.5s dots 1.5s infinite;
    }
  }
  @keyframes dots {
    0% {
      transform: translateY(0);
    }
    16% {
      transform: translateY(-10px);
    }
    33% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
  .scroll {
    height: 50vh;
    overflow: auto;
    li {
      background: transparent;
    }
  }
  .amount-input {
    width: 90px;
  }

  @media screen and (max-width: 768px) {
    .dropdown-card-content,
    .dropdown-card-header {
      padding: 1rem 0.5rem;
    }
  }
}
</style>
