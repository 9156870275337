<template>
  <div class="my-subscriptions-view view">
    <div class="container box">
      <section v-if="!isLoading">
        <h2 class="is-bold">Dina prenumerationer</h2>
        <h3 class="is-bold is-size-5 has-half-bottom-margin has-top-margin">
          Aktiva
        </h3>
        <section
          v-if="
            subscriptions.filter((sub) => sub.status === 'active').length !== 0
          "
        >
          <div
            v-for="subscription of subscriptions.filter(
              (sub) => sub.status === 'active'
            )"
            :key="subscription.id"
            class="card-plan has-shadow-on-hover"
          >
            <div class="is-flex has-space-between">
              <div>
                {{ getNameOf(subscription.plan.product) }}
              </div>
              <div class="tag" :class="getTagClass(subscription)">
                {{ getStatus(subscription) }}
              </div>
            </div>
          </div>
        </section>
        <section v-else>
          <p>Du har inga prenumerationer.</p>
          <RouterLink
            :to="{ name: 'home' }"
            class="button is-secondary has-half-top-margin"
            >Köp en prenumeration</RouterLink
          >
        </section>
        <h3 class="is-bold is-size-5 has-half-bottom-margin has-2x-top-margin">
          Inaktiva
        </h3>

        <section
          v-if="
            subscriptions.filter((sub) => {
              return sub.status !== 'active';
            }).length !== 0
          "
        >
          <div
            v-for="subscription of subscriptions.filter((sub) => {
              return sub.status !== 'active';
            })"
            :key="subscription.id"
            class="card-plan has-shadow-on-hover is-not-active"
          >
            <div class="is-flex has-space-between">
              <div>
                {{ getNameOf(subscription.plan.product) }}
              </div>
              <div class="tag" :class="getTagClass(subscription)">
                {{ getStatus(subscription) }}
              </div>
            </div>
          </div>
        </section>
        <section v-else>Du har inga prenumerationer.</section>

        <RouterLink
          :to="{ name: 'home' }"
          class="button is-secondary has-2x-top-margin"
          >Till startsidan</RouterLink
        >
      </section>
      <section v-else>
        <h2 class="is-bold has-bottom-margin">Dina prenumerationer</h2>
        <b-skeleton width="100%" height="40" :animated="animated"></b-skeleton>
        <b-skeleton width="100%" height="40" :animated="animated"></b-skeleton>
        <b-skeleton width="100%" height="40" :animated="animated"></b-skeleton>
        <RouterLink
          :to="{ name: 'home' }"
          class="button is-secondary has-2x-top-margin"
          >Till startsidan</RouterLink
        >
      </section>
    </div>
  </div>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";

export default {
  name: "MySubscriptionsView",
  components: {},
  data: function () {
    return {
      subscriptions: [],
      products: [],
      isLoading: true,
    };
  },
  async created() {
    SubscriptionService.getMySubscriptions()
      .then((sub) => {
        this.subscriptions = sub.data.subscriptions;
        this.products = sub.data.stripeProducts;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    getStatus(subscription) {
      switch (subscription.status) {
        case "trialing":
          return "Test period";
        case "active":
          return "Aktivt";
        case "incomplete_expired":
          return "Ej aktivt";
        case "incomplete":
          return "Ej aktivt";
        case "past_due":
          return "Ej aktivt";
        case "canceled":
          return "Avbruten";
        case "unpaid":
          return "Ej betald";
        default:
          return "Ej aktivt";
      }
    },
    getTagClass(subscription) {
      switch (subscription.status) {
        case "trialing":
          return "is-success";
        case "active":
          return "is-success";
        case "incomplete_expired":
          return "";
        case "incomplete":
          return "";
        case "past_due":
          return "is-danger";
        case "canceled":
          return "";
        case "unpaid":
          return "is-danger";
        default:
          return "";
      }
    },
    getNameOf(subscriptionId) {
      const found = this.products.find(
        (product) => product.id === subscriptionId
      );
      if (found) {
        return found.name;
      }
      return "Kunde ej hämta namn.";
    },
  },
};
</script>

<style scoped lang="scss">
.my-subscriptions-view {
  .card-plan {
    border-radius: 8px;
    border: 1px solid rgb(225, 225, 225);
    cursor: pointer;
    padding: 1rem 1.5rem;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &.is-not-active {
      background: rgb(242, 242, 242);
    }
  }
}
</style>
