<template>
  <div class="promotion-view has-text-left">
    <div class="container has-left-padding has-right-padding">
      <div class="">
        <div class="card-content">
          <div class="columns" v-if="!isLoading">
            <div class="column">
              <div class="bullhorn is-horizontal-middle">
                <i class="fa fa-5x fa-bullhorn" aria-hidden="true"></i>
              </div>
              <h3 class="has-text-centered">{{ promotion.title.sv }}</h3>
              <p
                class="has-text-centered has-text-black subtitle has-half-bottom-margin"
              >
                {{ promotion.description.sv }}**
              </p>
              <p class="has-text-centered has-text-black subtitle">
                <span class="tag has-background-white is-large has-text-black">
                  {{ formatDate(promotion.startDate) }}
                  <i
                    class="fas fa-arrow-right has-half-left-margin has-half-right-margin"
                  ></i>
                  {{ formatDate(promotion.endDate) }}
                </span>
              </p>
              <hr class="hr-half-large-primary has-2x-bottom-margin" />

              <div class="columns">
                <div
                  class="column"
                  :class="getColumnClass(promotion.products.length, index)"
                  v-for="(product, index) in promotion.products"
                  :key="product.id"
                >
                  <div class="product-wrapper">
                    <div class="product-content">
                      <figure class="image is-4by3">
                        <img class="center" :src="product.image" />
                      </figure>
                    </div>
                    <div class="product-title">
                      <span v-if="product.available > 0">{{
                        product.title
                      }}</span>
                      <span v-else>
                        <span
                          class="tag is-horizontal-middle has-no-products-left"
                          style="font-size: 1rem"
                        >
                          <strong>SLUT I LAGER</strong>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns" v-if="!isLoading">
            <div class="column is-half is-offset-one-quarter">
              <p class="is-size-7 has-text-black has-half-bottom-margin">
                * Erbjudandet gäller endast för medlemmar. Medlemskap är gratis.
              </p>
              <p class="is-size-7 has-text-black">
                ** Erbjudandet gäller så långt lagret räcker. Om en produkt inte
                längre är tillgänglig eller slut innebär det att den sålts slut.
                I vissa fall kan produkter som har tagit slut komma att ersättas
                med likvärdiga produkter. Därmed reserverar vi oss för
                förändringar i erbjudandet.
              </p>
            </div>
          </div>

          <div class="columns" v-if="isLoading">
            <div class="column has-no-top-padding">
              <div class="columns has-no-margin">
                <div class="column is-2 is-offset-5">
                  <b-skeleton
                    circle
                    width="140px"
                    height="140px"
                    :animated="true"
                  ></b-skeleton>
                </div>
              </div>
              <div class="columns has-no-margin">
                <div class="column is-one-third is-offset-one-third">
                  <b-skeleton
                    width="100%"
                    height="40px"
                    :animated="true"
                  ></b-skeleton>
                </div>
              </div>
              <div class="columns has-no-bottom-margin">
                <div class="column is-half is-offset-one-quarter">
                  <b-skeleton
                    width="100%"
                    height="30px"
                    :animated="true"
                  ></b-skeleton>
                </div>
              </div>
              <div class="columns">
                <div class="column is-half is-offset-one-quarter">
                  <b-skeleton
                    width="100%"
                    height="30px"
                    :animated="true"
                  ></b-skeleton>
                </div>
              </div>

              <section class="columns">
                <div class="column is-4">
                  <div class="product-wrapper has-large-lightgrey-shadow">
                    <div class="product-content">
                      <b-skeleton
                        width="100%"
                        height="140px"
                        :animated="true"
                      ></b-skeleton>
                    </div>
                    <div class="product-title">
                      <b-skeleton
                        width="100%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                      <b-skeleton
                        width="100%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                      <b-skeleton
                        width="80%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="product-wrapper has-large-lightgrey-shadow">
                    <div class="product-content">
                      <b-skeleton
                        width="100%"
                        height="140px"
                        :animated="true"
                      ></b-skeleton>
                    </div>
                    <div class="product-title">
                      <b-skeleton
                        width="100%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                      <b-skeleton
                        width="100%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                      <b-skeleton
                        width="80%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="product-wrapper has-large-lightgrey-shadow">
                    <div class="product-content">
                      <b-skeleton
                        width="100%"
                        height="140px"
                        :animated="true"
                      ></b-skeleton>
                    </div>
                    <div class="product-title">
                      <b-skeleton
                        width="100%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                      <b-skeleton
                        width="100%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                      <b-skeleton
                        width="80%"
                        height="25px"
                        :animated="true"
                      ></b-skeleton>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromotionService from "../../services/PromotionService";
//import moment from "moment";
export default {
  name: "promotionView",
  components: {},
  data() {
    return {
      isLoading: false,
      promotion: null,
      products: [],
    };
  },
  async created() {
    this.isLoading = true;
    const promoData =
      await PromotionService.getCurrentlyPromotionProductsByPromotionId(
        this.$route.params.id
      );

    this.promotion = promoData.data;
    this.isLoading = false;
  },
  methods: {
    getColumnClass(nrOfProducts, index) {
      let classToReturn;
      switch (nrOfProducts) {
        case 2:
          classToReturn = "is-4-tablet is-3-fullhd";
          classToReturn =
            index === 0
              ? classToReturn + " is-offset-2-tablet is-offset-3-fullhd"
              : classToReturn;
          return classToReturn;
        case 3:
          classToReturn = "is-4-tablet";
          return classToReturn;
        case 4:
          classToReturn = "is-3-tablet is-2-fullhd";
          classToReturn =
            index === 0 ? classToReturn + " is-offset-2-fullhd" : classToReturn;
          return classToReturn;
        case 5:
          classToReturn = "is-4-tablet is-3-fullhd";
          classToReturn =
            index === 3
              ? classToReturn + " is-offset-2-tablet is-offset-4-fullhd"
              : classToReturn;
          return classToReturn;
        default:
          return "is-4-tablet is-3-fullhd";
      }
      //'is-4-tablet is-3-fullhd': promotion.products.length == 2, 'is-offset-2-tablet is-offset-3-fullhd': index == 0 && promotion.products.length == 2
    },
    formatDate() {
      //return moment(date).format("YYYY/MM/DD");
      return "2002.22.22";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/variables.scss";
.promotion-view {
  //background-image: url("./../../assets/svg/bg-unknown-v2.svg");
  background-position: center bottom;
  background-repeat: repeat-x;
  position: relative;
  bottom: -2.6rem;
}

.tag.has-no-products-left {
  color: black;
  background: repeating-linear-gradient(
    -55deg,
    lighten($grey, 20%),
    lighten($grey, 20%) 10px,
    $grey-light 10px,
    $grey-light 20px
  );
}

.product-wrapper {
  border: 1px solid $grey-light;
  border-radius: $radius-large;
  //height: 280px;
  background: white;
  .product-content {
    img.center {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      padding: 1rem 1rem 0 1rem;
    }
  }
  .product-title {
    padding: 1rem;
    span {
      //height: 75px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      //-webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}

.bullhorn {
  width: 120px;
  height: 120px;
  position: relative;
  display: inline-block;
  background: $primary-default-cloud-blue-color;
  border-radius: 50%;
  margin-bottom: 1rem;
  i {
    font-size: 7rem;
    transform: rotate(-25deg);
  }
}
.card-content {
  padding-top: 0;
}
</style>
