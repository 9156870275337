import { functions } from "@/main";
import { httpsCallable } from "firebase/functions";

const SelloService = {
  async getSelloProductBy(id) {
    const selloProduct = httpsCallable(functions, "getSelloProductBy");
    return selloProduct({ id: id });
  },
  async getSelloProducts(offset = 0) {
    const selloProduct = httpsCallable(functions, "getSelloProducts");
    return selloProduct({ offset: offset });
  },
  async createProduct(product) {
    const createProduct = httpsCallable(functions, "createProduct");
    return createProduct(product);
  },
  async getReceiptBy(orderId) {
    const getSelloReceipt = httpsCallable(functions, "selloReceipt");
    return getSelloReceipt({ orderId: orderId });
  },
};

export default SelloService;
