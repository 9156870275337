import { render, staticRenderFns } from "./RegisterView.vue?vue&type=template&id=1f7b40e0&scoped=true"
import script from "./RegisterView.vue?vue&type=script&lang=js"
export * from "./RegisterView.vue?vue&type=script&lang=js"
import style0 from "./RegisterView.vue?vue&type=style&index=0&id=1f7b40e0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7b40e0",
  null
  
)

export default component.exports