<template>
  <div class="my-offers-view">
    <div class="container vending-machine-checkout wrapper">
      <p class="has-text-centered has-top-margin">
        Det finns inga erbjudanden just nu.
      </p>
      <br />
      <RouterLink
        :to="{ name: 'home' }"
        class="button is-info is-horizontal-middle"
        >Tillbaka</RouterLink
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "my-offers-view",
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
