<template>
  <div class="create-product-view view">
    <section v-if="viewIsLoading" class="loading-start">
      <div class="loader" style="width: 3em; height: 3em"></div>
      <p class="has-text-white subtitle has-top-margin">Laddar...</p>
    </section>
    <div
      v-show="!viewIsLoading"
      class="container vending-machine-checkout wrapper"
    >
      <div class="notification is-success" v-if="creatingProductWasSuccessfull">
        Product was created!
      </div>
      <div class="notification is-success" v-if="error">
        Something went wrong :O
      </div>
      <section v-if="!product || !Object.keys(product.data.product).length">
        <b-field message="What do you want to search?">
          <b-input
            placeholder="Search..."
            v-model="search"
            type="search"
            icon="magnify"
          >
          </b-input>
          <p class="control">
            <b-button type="is-primary" @click="onSearch" label="Search" />
          </p>
        </b-field>
      </section>
      <section class="create-model" v-else>
        <figure class="image is-128x128">
          <img :src="model.image" />
        </figure>
        <b-field label="Name">
          <b-input v-model="model.name">{{ model.name }}</b-input>
        </b-field>
        <b-field label="Brand">
          <b-input v-model="model.brand">{{ model.brand }}</b-input>
        </b-field>
        <b-field label="EAN">
          <b-input v-model="model.ean">{{ model.ean }}</b-input>
        </b-field>
        <b-field label="Content">
          <b-input v-model="model.content" type="textarea">{{
            model.content
          }}</b-input>
        </b-field>
        <b-field label="Available">
          <b-input v-model="model.available" type="number">{{
            model.available
          }}</b-input>
        </b-field>
        <b-field label="Price">
          <b-input v-model="model.price">{{ model.price }}</b-input>
        </b-field>
        <b-field label="Prev Price has-top-margin">
          <b-input v-model="model.prevPrice">{{ model.prevPrice }}</b-input>
        </b-field>
        <div class="buttons has-top-margin">
          <button
            class="button is-primary"
            @click="onCreate"
            :class="{ 'is-loading': creatingProductIsLoading }"
          >
            Create
          </button>
          <button
            :class="{ 'is-loading': creatingProductIsLoading }"
            class="button is-link"
            @click="onClear"
          >
            Clear
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SelloService from "@/services/selloService";

export default {
  name: "CreateProductView",
  components: {},
  data() {
    return {
      view: "UPLOAD",
      search: "54083306",
      product: null,
      viewIsLoading: true,
      creatingProductIsLoading: false,
      creatingProductWasSuccessfull: false,
      error: false,
      model: {
        selloId: "",
        name: "",
        description: "",
        brand: "",
        ean: "",
        image: "",
        content: "",
        images: [],
        sold: 0,
        available: 0,
        isActive: true,
        price: 0,
        prevPrice: 0,
      },
    };
  },
  async created() {
    // eslint-disable-next-line no-undef

    setTimeout(() => {
      this.viewIsLoading = false;
    }, 1000);
  },
  methods: {
    onClear() {
      this.product = null;
      this.search = "";
      this.model = {
        selloId: "",
        name: "",
        description: "",
        brand: "",
        ean: "",
        image: "",
        content: "",
        images: [],
        sold: 0,
        available: 0,
        isActive: true,
        price: 0,
        prevPrice: 0,
      };
    },
    async onSearch() {
      this.product = await SelloService.getSelloProductBy(this.search);

      const p = this.product.data.product;
      this.model.name = p.private_name;
      this.model.brand = p.brand_name;
      this.model.ean = p.properties[0].value.default;
      this.model.image = p.images[0].url_large;
      this.model.images = p.images;
      this.model.available = p.quantity;
      this.model.content = p.texts.default.sv.description;
      this.model.price = Math.round(p.prices["56636"].store * 0.88);
      this.model.prevPrice = p.prices["56636"].store;
    },
    async onCreate() {
      this.model.selloId = this.search;
      this.model.price = Number(this.model.price);
      this.model.prevPrice = Number(this.model.prevPrice);
      this.model.available = Number(this.model.available);

      this.creatingProductIsLoading = true;
      SelloService.createProduct(this.model)
        .then(() => {
          this.creatingProductWasSuccessfull = true;
          this.error = false;
        })
        .catch(() => {
          this.error = true;
          this.creatingProductWasSuccessfull = false;
        })
        .finally(() => (this.creatingProductIsLoading = false));
    },
  },
};
</script>

<style lang="scss">
.create-product-view {
  .create-model .field {
    display: block !important;
  }
}
</style>
