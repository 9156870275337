<template>
  <div class="product-description">
    <nav>
      <div class="tabsy">
        <div
          class="tab"
          @click="selectedTab = 'OVERVIEW'"
          :class="{ 'is-active': selectedTab === 'OVERVIEW' }"
        >
          Beskrivning
        </div>
        <div
          class="tab"
          @click="selectedTab = 'RECENSIONER'"
          :class="{ 'is-active': selectedTab === 'RECENSIONER' }"
        >
          Recensioner
        </div>
      </div>
    </nav>
    <section v-if="selectedTab === 'OVERVIEW'">
      <p v-html="product.content"></p>
    </section>
    <section v-if="selectedTab === 'RECENSIONER'">
      <p>Det finns inga recensioner.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "ProductDescription",
  components: {},
  props: {
    product: {
      default: null,
    },
  },

  data() {
    return {
      viewIsLoading: true,
      selectedTab: "OVERVIEW",
    };
  },
  async created() {
    this.viewIsLoading = false;
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "../../assets/variables.scss";
.product-description {
  margin-bottom: 2rem;
  nav {
    .tabsy {
      border-bottom: 3px solid $grey-lightest;
      margin-bottom: 1rem;
      .tab {
        padding: 0.5rem 0.75rem;
        display: inline-block;

        cursor: pointer;
        bottom: -3px;

        position: relative;
        font-size: 14px;
        color: black;
        border-bottom: 3px solid transparent;

        &:hover,
        &.is-active {
          border-color: #476dff;
        }
      }
    }
  }
}
</style>
