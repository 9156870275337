<template>
  <div class="product-properties">
    <div class="cards">
      <div
        class="property-card"
        v-for="property in properties"
        :key="property.value"
      >
        <img
          src="@/assets/images/svg/menu/pikachu.svg"
          width="70"
          v-if="property.img === 'POKEMON'"
        />
        <img
          src="@/assets/images/svg/menu/zelda.svg"
          width="70"
          v-else-if="property.img === 'NINTENDO'"
        />
        <img
          src="@/assets/images/svg/menu/barbie.svg"
          width="70"
          v-else-if="property.img === 'MATTEL'"
        />
        <img
          src="@/assets/images/svg/menu/lego.svg"
          width="70"
          v-else-if="property.img === 'LEGO'"
        />
        <img
          src="@/assets/images/svg/menu/funko.svg"
          v-else-if="property.img === 'FUNKO POP'"
          width="70"
        />
        <p>{{ property.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductProperties",
  components: {},
  props: {
    product: {
      default: null,
    },
  },

  data() {
    return {
      viewIsLoading: true,
      properties: [],
    };
  },
  async created() {
    const brand = this.product.description || this.product.brand;
    if (brand) this.properties.push({ img: brand, value: brand });

    this.viewIsLoading = false;
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.product-properties {
  .cards {
    display: flex;
    justify-content: center;
    .property-card {
      height: 100px;
      width: 100px;
      img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
</style>
