import { render, staticRenderFns } from "./AdminUtilitiesView.vue?vue&type=template&id=bb9242fe&scoped=true"
import script from "./AdminUtilitiesView.vue?vue&type=script&lang=js"
export * from "./AdminUtilitiesView.vue?vue&type=script&lang=js"
import style0 from "./AdminUtilitiesView.vue?vue&type=style&index=0&id=bb9242fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb9242fe",
  null
  
)

export default component.exports