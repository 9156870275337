import { render, staticRenderFns } from "./SearchView.vue?vue&type=template&id=1d4e2baa&scoped=true"
import script from "./SearchView.vue?vue&type=script&lang=js"
export * from "./SearchView.vue?vue&type=script&lang=js"
import style0 from "./SearchView.vue?vue&type=style&index=0&id=1d4e2baa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4e2baa",
  null
  
)

export default component.exports