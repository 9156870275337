<template>
  <transition name="modal-fade">
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="modal" :style="{ width: `${width}px` }" @click.stop>
        <slot name="header"></slot>
        <div
          class="content has-text-left"
          :class="{ 'has-no-padding': hasNoPadding }"
        >
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalPopup",
  props: {
    width: {
      default: 500,
    },
    hasNoPadding: {
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #000000da;
}

.modal {
  display: block;
  text-align: center;
  background-color: white;
  min-height: fit-content;
  margin: 0 auto;
  margin-top: 15vh;
  border-radius: 6px;
  overflow: unset;
  position: relative;
  z-index: 999;
  height: fit-content;
  z-index: 99999;

  .content {
    padding: 0 1.5rem 2rem 1.5rem;
    max-height: 80vh;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    max-width: 350px;
  }
}

.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
