<template>
  <div class="product-info">
    <div class="image is-relative has-top-margin">
      <img
        :src="product.image"
        class="has-no-margin is-horizontal-middle"
        style="max-height: 220px; width: auto"
      />
    </div>
    <div class="has-2x-bottom-margin">
      <h5 class="has-top-margin has-text-grey has-text-centered">
        {{ product.description || product.brand }}
      </h5>

      <h4 class="has-no-margin has-text-centered">
        {{ product.name }}
      </h4>
      <h4
        class="has-no-bottom-margin has-half-top-margin is-size-4 has-text-centered"
      >
        <span class="is-size-6 tag" :class="{ 'is-danger': product.promoPrice }"
          >{{
            product.promoPrice ? product.promoPrice : product.price
          }}
          kr</span
        >
        <span
          v-if="product.promoPrice"
          style="text-decoration: line-through"
          class="has-half-left-margin"
          >{{ product.price }} kr</span
        >
      </h4>

      <div
        class="has-top-margin"
        v-if="product.content"
        v-html="product.content"
      ></div>
    </div>

    <p class="is-size-7 has-no-margin has-text-centered">
      Ej lämplig för barn under 3 år på grund av smådelar.
    </p>
    <div
      class="notification"
      v-if="isDisabled(product) || product.available === product.sold"
    >
      Slut i lager
    </div>
    <button
      class="button is-primary is-fullwidth has-half-top-margin"
      @click="onAddProduct(product)"
      :disabled="isDisabled(product) || product.available === product.sold"
    >
      Lägg i varukorg
    </button>
  </div>
</template>

<script>
export default {
  name: "ProductList",
  components: {},
  props: {
    product: {
      default: null,
    },
  },

  data() {
    return {
      quantityHasChanged: null,
    };
  },
  created() {},
  methods: {
    isDisabled(product) {
      const foundProduct = this.$store.state.cart.find(
        (prod) => prod.objectId === product.objectId
      );
      if (!foundProduct) return false;
      return product.available === foundProduct.quantity;
    },
    onAddProduct(product) {
      this.$store.commit("addProductToCart", { product: product, quantity: 1 });
      const isAvailable = this.isAvailable(product);
      if (!isAvailable) {
        this.quantityHasChanged = product.objectID;
        setTimeout(() => {
          this.quantityHasChanged = null;
        }, 800);
      }
      this.isModalOpen = false;
    },

    isAvailable(product) {
      const storeProduct = this.$store.state.cart.find(
        (p) => p.objectID === product.objectID
      );
      if (storeProduct) {
        return storeProduct.quantity === product.available;
      } else {
        return product.available === 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-list {
  padding: 2rem 1rem;
  .is-frozen {
    display: none;
  }

  .is-bookable {
    border: 3px solid #ffd859;
  }

  .bookable-info {
    position: absolute;
    bottom: 1rem;
    left: 0rem;
    width: 100%;
    background: #ffd859;
    padding: 0.25rem 0.5rem;
    font-size: 11px;
    opacity: 0.9;
    color: black;
  }
  .bubble {
    width: 40px;
    height: 40px;
    background-color: #f3633b;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    transform: translateX(-50%);
    right: 1rem;
    top: 1rem;
    animation: pop-up 0.8s linear forwards;
    color: white;

    p {
      position: absolute;
      color: white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes pop-up {
    0% {
      //transform: translateY(30px);
      opacity: 0;
      transform: scale(0);
    }
    10% {
      opacity: 1;
      transform: scale(0) translateY(25px);
    }
    30% {
      opacity: 1;
      transform: scale(1.1) translateY(25px);
    }
    40% {
      opacity: 1;
      transform: scale(1) translateY(18px);
    }
    75% {
      opacity: 1;
      transform: scale(1) translateY(4px);
    }
    100% {
      opacity: 0;
      transform: scale(1) translateY(-10px);
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;

    &:first-of-type {
      margin-bottom: 0.25rem;
    }
    &.is-secondary {
      font-size: 12px;
      color: gray;
    }
  }

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .product-card {
    position: relative;
    margin-bottom: 1rem;
    //margin: 0 0.75rem 1rem 0.75rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background: white;
    cursor: pointer;
    transition: transform 0.15s ease-in-out;

    .image {
      height: 120px;
      img {
        height: 100%;
        width: auto;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }
    }

    .prime {
      display: -webkit-box;
      max-width: 200px;
      height: 48px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.not-available {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:hover:not(.not-available) {
      transform: scale(1.04);
    }
    .content {
      padding: 0.5rem;
      .price {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        p:first-of-type {
          font-size: 12px;
          margin: 0;
          text-decoration: line-through;
          text-align: right;
        }
        p:last-of-type {
          text-align: right;
          font-size: 14px;
          color: white;
          padding: 0 0.5rem;
          background: #f14668;
          border-radius: 3px;
        }
        .extra {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
