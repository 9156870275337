<template>
  <div class="login view">
    <div class="columns has-no-margin">
      <div class="column is-half wall">
        <figure class="image is-full-size">
          <img class="robots" src="@/assets/images/svg/robots.svg" />
          <h3 class="has-text-white has-text-centered">
            <span class="is-boxed">Build,</span>
            <span class="spell-right">Package</span>,<br />
            <i>Import</i> &
            <strong class="has-text-white">Ready to use</strong>
          </h3>
        </figure>
      </div>
      <div class="column is-half">
        <div
          style="width: 380px"
          class="is-vertical-middle is-horizontal-middle"
        >
          <img
            src="@/assets/images/svg/logo/pintor-logo.svg"
            width="170"
            style="margin-bottom: 4rem"
          />
          <div class="title">Hello again!</div>

          <LoginForm></LoginForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm.vue";

export default {
  name: "LoginView",
  components: { LoginForm },
  data: function () {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.login {
  .wall {
    background: rgb(19, 19, 63);
    background: linear-gradient(-30deg, #ff6316, #fe9627, #ffc500);
    position: relative;

    height: 100vh;
    width: 50vw;
    padding: 0;
    .is-full-size {
      width: 100%;
      height: 100%;
      h3 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 15%;
        width: 500px;
        .is-boxed {
          margin-right: 12px;
          padding: 2px 6px;
          border-radius: 3px;
          background: white;
          color: rgba(47, 46, 129, 0.9038209033613446);
        }
        .spell-right {
          text-underline-offset: 9px;
          text-decoration: #2b4770 wavy underline;
        }
      }
    }
    .robots {
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 80%;
    }
  }
}
</style>
