<template>
  <div class="create-products-view view">
    <section v-if="viewIsLoading" class="loading-start">
      <div class="loader" style="width: 3em; height: 3em"></div>
      <p class="has-text-white subtitle has-top-margin">Laddar...</p>
    </section>

    <div v-show="!viewIsLoading" class="container box">
      Visar sello produkter: {{ selloProducts.length }} | Visar inlagda
      produkter: {{ fbProducts.length }}
      <ul v-for="product in selloProducts" :key="product.objectID">
        <li
          class="product-card has-top-margin"
          :class="{ exists: productExists(product) }"
          :style="{ opacity: product.available > 0 ? 1 : 0.5 }"
        >
          <article class="media" style="flex-basis: 100%">
            <figure class="media-left">
              <p class="image is-64x64">
                <img :src="product.image" />
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <b-field label="">
                  <b-input v-model="product.name" size="is-small">{{
                    product.name
                  }}</b-input>
                </b-field>
                <b-field
                  label=""
                  style="display: inline-block"
                  class="has-right-margin"
                  ><p class="is-size-7">{{ product.available }} st</p></b-field
                >
                <b-field
                  label=""
                  style="display: inline-block"
                  class="has-right-margin"
                >
                  <b-input
                    v-model="product.price"
                    size="is-small"
                    style="width: 100px"
                    >{{ product.price }}</b-input
                  >
                </b-field>
                <b-field
                  label=""
                  style="display: inline-block"
                  class="has-right-margin"
                >
                  <b-input
                    v-model="product.prevPrice"
                    size="is-small"
                    style="width: 100px"
                    >{{ product.prevPrice }}</b-input
                  >
                </b-field>
                <b-field label="" style="display: inline-block">
                  <b-input
                    v-model="product.brand"
                    size="is-small"
                    style="width: 200px"
                    >{{ product.brand }}</b-input
                  >
                </b-field>
              </div>
            </div>
            <div class="media-right">
              <button
                class="button is-primary"
                @click="onCreate(product)"
                :disabled="productExists(product) || product.available === 0"
                :class="{ 'is-loading': creatingProductIsLoading }"
              >
                Publicera
              </button>
            </div>
          </article>
        </li>
      </ul>
      <button
        class="button is-primary"
        @click="getNextProducts"
        v-if="nrOfProducts > nextOffset"
        :class="{ 'is-loading': fetchingIsLoading }"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
import SelloService from "@/services/selloService";

export default {
  name: "CreateProductsView",
  components: {},
  data() {
    return {
      selloProducts: [],
      fbProducts: [],
      nrOfProducts: 0,
      nextOffset: 0,

      viewIsLoading: true,
      creatingProductIsLoading: false,
      creatingProductWasSuccessfull: false,
      fetchingIsLoading: false,
      error: false,
    };
  },
  async created() {
    const t = await SelloService.getSelloProducts(this.nextOffset);
    this.nextOffset = this.nextOffset + 100;
    this.nrOfProducts = t.data.product.meta.total;

    this.selloProducts = t.data.product.products.map((p) => {
      return {
        selloId: String(p.id),
        name: p.texts.default.sv.name,
        brand: p.brand_name,
        ean: p.properties.length ? p.properties[0].value.default : "",
        image: p.images.length ? p.images[0].url_large : "",
        images: p.images,
        available: p.quantity,
        content: p.texts.default.sv.description,
        price: Math.round(p.prices["56636"].store * 0.88),
        prevPrice: p.prices["56636"].store,
        sold: 0,
      };
    });
    this.fbProducts = await ProductService.getAllProducts();
    this.viewIsLoading = false;
  },
  methods: {
    productExists(product) {
      return this.fbProducts.find((p) => p.selloId === product.selloId);
    },
    async getNextProducts() {
      this.fetchingIsLoading = true;
      const t = await SelloService.getSelloProducts(this.nextOffset);
      this.nextOffset = this.nextOffset + 100;

      this.selloProducts = [
        ...this.selloProducts,
        ...t.data.product.products.map((p) => {
          return {
            selloId: String(p.id),
            name: p.texts.default.sv.name,
            brand: p.brand_name,
            ean: p.properties.length ? p.properties[0].value.default : "",
            image: p.images.length ? p.images[0].url_large : "",
            images: p.images,
            available: p.quantity,
            content: p.texts.default.sv.description,
            price: Math.round(p.prices["56636"].store * 0.88),
            prevPrice: p.prices["56636"].store,
            sold: 0,
          };
        }),
      ];

      this.fetchingIsLoading = false;
    },

    async onCreate(product) {
      this.creatingProductIsLoading = true;

      const price = Number(product.price);
      const prevPrice = Number(product.prevPrice);
      const available = Number(product.available);
      const model = {
        selloId: product.selloId,
        name: product.name,
        brand: product.brand.toUpperCase(),
        ean: product.ean,
        image: product.image,
        images: product.images,
        available: available,
        content: product.content,
        price: price,
        prevPrice: prevPrice,
        isActive: true,
        sold: 0,
      };

      SelloService.createProduct(model)
        .then(() => {
          this.creatingProductWasSuccessfull = true;
          this.error = false;
          this.$buefy.toast.open({
            message: `${model.name} har publicerats!`,
            type: "is-success",
            position: "is-bottom",
            duration: 4000,
          });
        })
        .catch(() => {
          this.error = true;
          this.creatingProductWasSuccessfull = false;
          this.$buefy.toast.open({
            message: `Det gick inte att publicera ${model.name}`,
            type: "is-danger",
            position: "is-bottom",
            duration: 4000,
          });
        })
        .finally(() => (this.creatingProductIsLoading = false));
    },
  },
};
</script>

<style lang="scss">
.create-products-view {
  .product-card {
    padding: 0.5rem 1rem;
    background: rgb(239, 239, 239);
    border: 1px solid gainsboro;

    &.exists {
      background: rgb(203 236 203) !important;
    }
  }
}
</style>
