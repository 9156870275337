import { database } from "@/main";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore";

const SaleService = {
  async getSales() {
    let themes = [];
    const themesRef = await getDocs(collection(database, "sales"));

    themesRef.forEach((gameRef) => {
      themes.push({
        id: gameRef.id,
        ...gameRef.data(),
      });
    });

    return themes;
  },
  async deleteBy(id) {
    await deleteDoc(doc(database, "sales", id));
  },
  createSale(sales) {
    return addDoc(collection(database, "sales"), sales);
  },
  async getUpdatedProductsFrom(sales, products) {
    let amountOff = 0;
    let total = 0;
    let receiptSaleText = "";

    sales.forEach((sale) => {
      let salesAmount = 0;
      let productsOnSale = [];
      let currentSale = JSON.parse(JSON.stringify(sale));

      if (sale) {
        productsOnSale = products
          .filter((product) =>
            sale.products.find((sProd) => sProd === product.id)
          )
          .sort((a, b) => a.price - b.price);
      }

      // CALC NR OF QUANTITY
      let nrOfProductsOnSale = 0;
      let fulfilled = [];

      productsOnSale.forEach((product) => {
        nrOfProductsOnSale = nrOfProductsOnSale + product.quantity;
      });

      let methods = currentSale.method
        .sort((a, b) => a.amount - b.amount)
        .reverse();

      methods.forEach((m) => {
        const nrOffulfill = Math.floor(nrOfProductsOnSale / m.amount);

        if (nrOffulfill >= 1) {
          nrOfProductsOnSale = nrOfProductsOnSale - nrOffulfill * m.amount;
          fulfilled.push({ method: m, fulfilled: nrOffulfill * m.amount });
        }
      });

      let index = 0;

      let tempProducts = JSON.parse(JSON.stringify(productsOnSale));

      fulfilled.forEach((fulfill) => {
        let running = true;

        while (running) {
          const prod = tempProducts[index];

          if (prod.quantity / fulfill.fulfilled < 1) {
            fulfill.fulfilled = fulfill.fulfilled - prod.quantity;
            let s = prod.price - fulfill.method.price;
            amountOff = amountOff + s * prod.quantity;
            salesAmount = salesAmount + s * prod.quantity;
            index = index + 1;
          } else {
            prod.quantity = -(fulfill.fulfilled - prod.quantity);
            let s = prod.price - fulfill.method.price;
            amountOff = amountOff + s * fulfill.fulfilled;
            salesAmount = salesAmount + s * fulfill.fulfilled;
            running = false;
          }
        }
      });
      if (salesAmount > 0) {
        if (receiptSaleText) {
          receiptSaleText = receiptSaleText + ", " + sale.title;
        } else {
          receiptSaleText = "RABATT: " + receiptSaleText + sale.title;
        }
      }
    });

    products.forEach((product) => {
      total = total + product.price * product.quantity;
    });
    total = total - amountOff;
    //console.log("fulfill", receiptSaleText, total);
    const shipping = total >= 500 ? 0 : 59;

    const costWithShipping = total + shipping;
    return {
      shipping: shipping,
      total: total,
      amountOff: amountOff,
      receiptSaleText: receiptSaleText,
      totalVAT: (Math.round(costWithShipping * 0.2 * 100) / 100).toFixed(2),
    };
  },
};

export default SaleService;
