import { database, functions } from "@/main";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";
const collectionPath = "swishPayments";

const SwishService = {
  async createOrderBy(order) {
    const createOrder = httpsCallable(functions, "requestPayment");
    return createOrder(order);
  },
  async userIsNotifiedSuccessfulOrder(id) {
    const notifyUserSuccessfulOrder = httpsCallable(
      functions,
      "notifyUserSuccessfulOrder"
    );
    return notifyUserSuccessfulOrder({ id: id });
  },
  async getPendingSwishPayment(user) {
    let payments = [];
    const payment = await database
      .collection(collectionPath)
      .where("userId", "==", user.id)
      .where("status", "==", "PENDING")
      .get();

    payment.forEach((article) => {
      payments.push({
        id: article.id,
        ...article.data(),
      });
    });
    return payments.pop();
  },
  async getSwishPaymentBy(id) {
    const paymentRef = doc(database, collectionPath, id);
    const paymentSnap = await getDoc(paymentRef);
    return {
      id: paymentSnap.id,
      ...paymentSnap.data(),
    };
  },
  async isSwishPaymentPlacedInLastThreeHours(id) {
    const now = new Date();

    if (id === undefined) return false;
    const paymentRef = doc(database, collectionPath, id);
    const paymentSnap = await getDoc(paymentRef);
    const payment = {
      id: paymentSnap.id,
      ...paymentSnap.data(),
    };

    if (!payment.isTracked) {
      // eslint-disable-next-line no-undef
      pjpixel.track(
        "purchase",
        { amount: payment.total, currency: "SEK" },
        { eventId: payment.selloOrderId }
      );
      this.userIsNotifiedSuccessfulOrder(payment.id);
    }

    const seconds = (now.getTime() - new Date(payment.created)) / 1000;
    const hourInSeconds = 3600;

    return seconds < hourInSeconds * 3;
  },
};

export default SwishService;
