<template>
  <div class="cart-info">
    <div
      style="z-index: 9999"
      class="cart-content"
      :class="{
        'is-active': $store.state.cartIsHighlighted,
        'is-mobile': isMobile,
      }"
      v-if="$store.state.cartIsHighlighted"
    >
      <div v-if="cart.length !== 0">
        <h5>Du har plockat...</h5>
        <hr />
        <li
          v-for="product in JSON.parse(JSON.stringify(cart))"
          :key="product.objectID"
        >
          <div>
            {{ product.quantity }} st {{ product.name }}
            <div class="price">
              {{ product.price }} kr
              <span v-if="product.promoPrice" class="text-underline"
                >{{ product.promoPrice }} kr</span
              >
            </div>
          </div>
          <div>
            <b-numberinput
              :editable="false"
              style="width: 90px"
              class="has-half-bottom-margin"
              min="0"
              :max="product.available"
              size="is-small"
              controls-position="compact"
              controls-rounded
              v-model="product.quantity"
              @input="onUpdateProductQuantity($event, product)"
            ></b-numberinput>
          </div>
        </li>
        <div
          class="bubble is-size-6 has-half-top-margin has-half-bottom-margin"
        >
          <section class="has-text-centered" v-if="shipping === 0">
            Gratis frakt!
          </section>
          <section style="" class="has-text-centered" v-else>
            Frakt: 59 kr<br />
            Återstående för gratis frakt: {{ 500 - total }} kr
          </section>
        </div>
        <p class="has-text-centered is-size-4 has-text-black has-no-margin">
          {{ total + shipping }} kr
        </p>
        <p
          v-if="discount"
          class="has-text-centered is-size-6 has-text-black has-no-margin"
        >
          Du sparar {{ discount }} kr! 🙌
        </p>
        <p class="has-text-centered is-size-7 has-no-margin">
          Totalt {{ total + shipping }} kr inkl {{ vat }} kr moms.
        </p>

        <div class="buttons">
          <button
            class="button is-primary is-fullwidth has-top-margin"
            @click="onCheckout"
          >
            Till kassan
          </button>
          <button
            class="button is-text is-fullwidth"
            @click="setCartIsHighlighted"
          >
            Stäng
          </button>
        </div>
      </div>
      <div class="has-text-centered has-padding" v-else>
        <figure>
          <img src="@/assets/images/svg/watertank.svg" width="80" />
        </figure>

        Du har inget i varukorgen
        <div class="buttons is-centered">
          <button
            class="button has-half-top-margin is-light"
            @click="setCartIsHighlighted"
          >
            Stäng
          </button>
        </div>
      </div>
    </div>
    <div
      :class="{ wall: $store.state.cartIsHighlighted }"
      @click="setCartIsHighlighted"
    ></div>
  </div>
</template>

<script>
import SaleService from "@/services/SaleService";

export default {
  name: "CartInfo",
  components: {},
  props: {
    product: {
      default: null,
    },
    isMobile: {
      default: false,
    },
  },

  data() {
    return {
      selectedQuantity: 1,
      total: 0,
      vat: 0,
      shipping: 0,
      discount: 0,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  async created() {
    const saleProducts = await SaleService.getUpdatedProductsFrom(
      this.$store.state.sales,
      this.$store.state.cart
    );
    //this.displayProducts = saleProducts.productsOnSale;
    this.total = saleProducts.total;
    this.vat = saleProducts.totalVAT;
    this.shipping = saleProducts.shipping;
    this.discount = saleProducts.amountOff;
  },
  watch: {
    async cart(newVal) {
      const saleProducts = await SaleService.getUpdatedProductsFrom(
        this.$store.state.sales,
        newVal
      );

      //this.displayProducts = saleProducts.productsOnSale;
      this.total = saleProducts.total;
      this.vat = saleProducts.totalVAT;
      this.shipping = saleProducts.shipping;
      this.discount = saleProducts.amountOff;
    },
  },
  methods: {
    getCartValueWithShipping() {
      let cost = this.$store.getters.getCart.reduce((acc, product) => {
        return acc + product.price * product.quantity;
      }, 0);
      if (cost === 0) return 0;
      else if (cost < 500) {
        cost = cost + 59;
      }
      return cost;
    },
    getMaxProductQuantity(product) {
      const foundProduct = this.$store.state.cart.find(
        (prod) => prod.objectID === product.objectID
      );
      const amount = foundProduct ? foundProduct.quantity : 0;
      const amountLeft = product.available - amount;

      return amountLeft;
    },
    getCartVatValue() {
      return (
        Math.round(this.getCartValueWithShipping() * 0.2 * 100) / 100
      ).toFixed(2);
    },
    getCartValue() {
      return this.$store.getters.getCart.reduce((acc, product) => {
        return acc + product.price * product.quantity;
      }, 0);
    },
    onUpdateProductQuantity(event, product) {
      this.$store.commit("setProductToCart", {
        product: product,
        quantity: event,
      });

      if (product.quantity === 0) {
        this.$store.commit("removeQuantityFromProduct", product);
      }
    },
    setCartIsHighlighted() {
      this.$store.commit(
        "setCartIsHighlighted",
        !this.$store.state.cartIsHighlighted
      );
    },
    onCheckout() {
      this.setCartIsHighlighted();
      setTimeout(() => {
        this.$router.push({ name: "checkout" });
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.cart-info {
  position: fixed;
  z-index: 9999;
  .cart-content {
    background: white;
    padding: 1rem;
    position: fixed;
    //top: calc(30vh + 2rem);
    margin-top: 28px;
    right: 1rem;
    width: calc(100% - 2rem);
    max-width: 400px;
    border-radius: 4px;
    opacity: 1;
    z-index: 99;
    top: 5.5rem;

    .price {
      color: #2647e8;
    }
    &.is-mobile {
      bottom: 7rem;
      top: auto;
      &::before {
        display: none;
      }
    }

    h5 {
      font-size: 20px;
      font-weight: bold;
      color: rgb(39, 39, 39);
    }
    @keyframes slide-up {
      0% {
        transform: translateY(10px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &.is-active {
      animation: slide-up 0.4s ease-out;
    }
    &::before {
      content: " ";
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      position: absolute;
      background-color: white;
      top: -8px;
      right: 6rem;
    }
  }
}
</style>
