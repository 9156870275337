<template>
  <div class="shop-checkout-summary view">
    <div class="container vending-machine-checkout wrapper">
      <div v-if="!isLoading">
        <div
          class="has-text-centered has-padding"
          v-if="isRecentlyPlaced || method === 'card'"
        >
          <h3 class="has-half-bottom-margin is-size-3">Tack för ditt köp!</h3>

          En bekräftelse kommer att skickas till din mejladress inom kort.
          Notera att bekräftelsemejlet kan ta upp till 24 timmar. Dina varor
          kommer att skickas iväg till dig inom 1-3 arbetsdagar så länge varorna
          finns i lager.

          <br /><br />
          Om du har några frågor angående din order är du välkommen att höra av
          dig till oss. Referera då till transaktionens id som du ser nedan.
          <br /><br /><br />
          <label class="is-size-7"
            >Transaktions-ID: {{ id || sessionId }}</label
          >
          <br />
          <br />
          <a
            href="https://www.prisjakt.nu/butiksinfo.php?f=41270&review=write"
            target="_blank"
          >
            <img
              src="https://pricespy-75b8.kxcdn.com/g/shop_review/SE/blue.svg"
            />
          </a>
          <br /><br />
          <a class="button is-info" href="/">Till startsidan</a>
        </div>
        <section v-else>
          <h3>Syns inte bekräftelsen?</h3>
          Om en beställning gjordes för mer än 3 timmar sedan så syns inte
          bekräftelsen här längre. Du kan alltid kolla i din mejlinkorg för
          orderbekräftelse. Notera att bekräftelsemejlet kan ta upp till 24
          timmar.
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import SwishService from "@/services/SwishService";

export default {
  name: "shop-checkout-summary",
  components: {},
  data() {
    return {
      id: null,
      isRecentlyPlaced: null,
      isLoading: true,
      method: null,
      sessionId: "",
    };
  },
  async created() {
    //this.id = this.$route.params.id;
    this.id = this.$route.query.transaction;
    this.method = this.$route.query.method;
    this.sessionId = this.$route.query.session_id;

    this.isRecentlyPlaced =
      await SwishService.isSwishPaymentPlacedInLastThreeHours(this.id);
    this.isLoading = false;

    if (this.isRecentlyPlaced) {
      this.$emit("orderWasPlaced");
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
