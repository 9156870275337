<template>
  <div class="campaign-block">
    <div class="container">
      <router-link
        :to="{ name: 'productSearchResult', query: { theme: 'funko' } }"
      >
        <h1 class="has-text-white has-text-centered">POP Deal</h1>
        <div
          class="subtitle has-text-white has-text-centered has-half-bottom-margin"
        >
          På alla standard Funko POP figurer! Gäller så långt lagret räcker.
        </div>
        <div
          class="subtitle has-text-white has-text-centered has-bottom-margin"
        >
          Tag 3st för 450kr eller 5st för 700kr**
        </div>
        <p class="is-size-7 has-text-white has-text-centered">
          ** Rabatten appliceras på de billigaste varorna först i fallande
          ordning. Erbjudandet kan utnyttjas vid fler tillfällen och på
          produkter som är beställningsbara. Läs mer om våra villkor
          <router-link class="has-text-white" :to="{ name: 'policy' }">
            här </router-link
          >.
        </p>
      </router-link>
      <img src="./../../assets/images/png/pidgeotto.png" class="left-image" />
      <img src="./../../assets/images/png/harryp.png" class="right-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignBlock",
  components: {},
  data() {
    return {};
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.campaign-block {
  //background: #212529;
  background: #fff6de;
  background: #292d3e;
  //border-radius: 8px;
  color: white;
  padding: 1rem 1.5rem;
  cursor: pointer;
  .left-image {
    position: absolute;
    left: 0;
    top: -1rem;
    height: 160px;
  }
  .right-image {
    position: absolute;
    right: 0;
    top: -1rem;
    height: 180px;
  }
  h1 {
    color: white;
    font-weight: bold;
    font-size: 60px !important;
    line-height: 1;
    margin-bottom: 1rem;
    margin-top: 0.75rem;
  }
  &:hover .subtitle {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 30px !important;
    }
    .container {
      padding: 0 6rem !important;
    }
    .right-image {
      height: 136px;
      right: -2rem;
    }
    .left-image {
      height: 136px;
      left: -2rem;
    }
  }
  @media screen and (max-width: 550px) {
    h1 {
      font-size: 30px !important;
    }
    .container {
      padding: 0 !important;
    }
    .right-image,
    .left-image {
      display: none;
    }
  }
}
</style>
