const LocalStorageService = {
  getCookie() {
    const key = "ACCEPT_COOKIES";
    return this.getFromLocalStorageBy(key);
  },
  setCookie() {
    const key = "ACCEPT_COOKIES";
    return this.setFromLocalStorageBy(key, true);
  },
  getFromLocalStorageBy(key) {
    return JSON.parse(localStorage.getItem(key));
  },
  setFromLocalStorageBy(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  },
};

export default LocalStorageService;
