<template>
  <div class="about-view view">
    <div class="container vending-machine-checkout wrapper">
      <Brukstorget></Brukstorget>
      <br />
      <RouterLink
        :to="{ name: 'home' }"
        class="button is-info is-horizontal-middle"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
import Brukstorget from "@/components/Brukstorget.vue";

export default {
  name: "about-view",
  components: { Brukstorget },
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
