<template>
  <div class="policy-view">
    <div class="container has-text-left">
      <div class="columns">
        <div class="column">
          <div class="">
            <div class="">
              <h4 ref="BUSINESSINFORMATION">
                <strong>FÖRETAGSINFORMATION</strong>
              </h4>
              <br />
              <div class="subtitle"><strong>Om Mobio</strong></div>
              <p>
                Företagets juridiska namn är Dennis Rojas Bustamante med
                organisationsnummer 9009134991. Företaget innehar F-skatt.
                Företaget har sitt säte i VARBERG med postadress Oxelstigen 8.
                Önskar du att komma i kontakt med oss, skicka ett mail till
                info@mobio.se med ditt ärende och dina kontaktuppgifter så hör
                vi av oss inom kort.
              </p>
              <br />

              <div class="subtitle"><strong>Beställning</strong></div>
              <p>
                När du slutfört din beställning skickas en orderbekräftelse till
                din e-postadress. I bekräftelsen finner du alla uppgifter om
                produkter, pris, fakturerings- och leveransadress. Är något fel
                i orderbekräftelsen ska du omedelbart kontakta oss via e-post
                till info@mobio.se.
              </p>
              <br />

              <h4 ref="PRODUCTINFORMATION_AND_PRICE">
                <strong>PRODUKTINFORMATION OCH PRIS</strong>
              </h4>
              <br />
              <div class="subtitle"><strong>Produktinformation</strong></div>
              <p>
                Vi reserverar oss för eventuella tryckfel på denna webbplats
                samt slutförsäljning av produkter. Vi garanterar inte att
                bilderna återger produkternas exakta utseende då en viss
                färgskillnad kan förekomma beroende på bildskärm, fotokvalitet
                samt upplösning. Vi försöker alltid på bästa sätt att exponera
                produkterna så korrekt som möjligt.
              </p>
              <br />
              <div class="subtitle"><strong>Priser</strong></div>
              <p>
                Alla priser i butiken anges i SEK och alla priser är inklusive
                25% moms. Vi reserverar oss för prisändringar orsakat av
                prisändring från leverantör, feltryck i prislistan samt
                felaktigheter i priser beroende på felaktig information och
                förbehåller oss rätten att justera priset.
              </p>
              <br />
              <div class="subtitle">
                <strong>Rabatter och Erbjudanden</strong>
              </div>
              <p>
                Rabatter och erbjudanden kan utnyttjas endast om villkoren för
                erbjundandet uppfylls. Läs även sektionen "Vid nyttjande av din
                ångerrätt".
              </p>
              <br />

              <h4 ref="SALE_AND_POLICY" name="SALE_AND_POLICY">
                <strong>FÖRSÄLJNINGS OCH LEVERANSVILLKOR</strong>
              </h4>
              <br />
              <div class="subtitle"><strong>Leverans</strong></div>
              <p>
                Våra normala leveranstider är 1-4 dagar. OBS! Beställningar
                lagda på helger skickas tidigast på måndagen efter. Vi skickar
                automatiskt per e-post en leveransbekräftelse tillsammans med
                ett spårnummer när ditt gods lämnar oss. Vi väljer Postnord
                eller Schenker för frakt. Fraktsätt och val av fraktbolag är
                beroende på vilken storlek och vikt det är på godset som ska
                fraktas. Om förseningar i leveransen skulle uppstå (utan att vi
                har meddelat dig om längre leveranstid) ska du kontakta oss på
                e-postadress: info@mobio.se.
              </p>
              <br />
              <section class="has-text-left-by-one-rem">
                <strong>Postnord</strong>
                <p>
                  Din order kommer skickas i form av ett postpaket eller
                  varubrev baserat på orderns storlek, vikt och innehåll.
                  Paketen brukar vanligtvis levereras inom 1-2 arbetsdagar.
                  PostNord kommer avgöra om ditt paket levereras till ditt
                  lokala ombud eller hem till din brevlåda. Vi har dessvärre
                  ingen kontroll över detta, men varubrev ska i regel landa i
                  brevlådan medan postpaket levereras till ombudet.
                </p>
                <br />
                <strong>Schenker</strong>
                <p>
                  Din order kommer skickas i form av ett postpaket.
                  Leveranstiden är vanligtvis cirka 7 arbetsdagar. Paket
                  levereras till ditt lokala Schenkerombud.
                </p>
                <br />
              </section>

              <div class="subtitle"><strong>Ångerrätt</strong></div>

              <p>
                Vid köp av varor på mobio.se har du som kund enligt svensk lag
                14 dagars ångerätt (distansavtalslagen). Vi erbjuder däremot 30
                dagars ångerrätt som gäller från det att du har tagit emot en
                vara som du har beställt. Återbetalning kan först efter mottagen
                och godkänd retur.
              </p>

              <br />
              <section class="has-text-left-by-one-rem">
                <strong>Vid nyttjande rabatter och erbjudanden: </strong>

                <li>
                  Om produkter som ingår i ett erbjudande hävs i ett köp innebär
                  det att villkoren inte längre uppfylls. Det innebär att
                  produkterna kommer att prisjusteras efter ordinarie pris.
                  Gåvor skall också returneras.
                  <br />
                  <br />
                </li>
                <strong>Vid nyttjande av din ångerrätt: </strong>

                <li>
                  Du måste meddela att du ångrar dig. Meddelandet ska skickas
                  till oss info@mobio.se. I ditt meddelande ska ditt namn, din
                  adress, e-postadress, ordernumret samt vilka varor som
                  returneringen gäller framgå klart och tydligt.
                </li>
                <li>
                  Du bör omedelbart och senast inom 30 dagar efter
                  ångermeddelandet returnera produkterna till oss.
                </li>
                <li>
                  Du står för returfrakt, leverans och skick på produkterna vid
                  retur, produkterna bör därför skickas välpaketerade och i
                  ursprunglig förpackning.
                </li>
                <li>
                  På återbetalningsbeloppet förbehåller vi oss rätten att dra av
                  en summa motsvarande värdeminskningen jämfört med varans
                  ursprungliga värde vid använd eller skadad produkt.
                </li>
                <li>
                  Om en Swish betalning har använts som betalmetod kan endast en
                  återbetalning ske till ett personligt bankkonto.
                  <br />
                  <br />
                </li>
                <strong>Ångerrätten gäller inte vid:</strong>
                <li>
                  Produkter som på grund av hälso- eller hygienskäl har
                  förseglats (plomberats) och där förseglingen (plomberingen)
                  har brutits av dig.
                </li>
                <li>
                  Produkter som har karaktär av förseglad ljud- eller
                  bildupptagning och där förseglingen har brutits av dig.
                </li>
                <li>
                  Specialtillverkad produkt, som har skräddarsytts särskilt åt
                  dig eller har en tydlig personlig prägel efter dina önskemål.
                </li>
                <li>
                  Tjänster som har fullgjorts och där du uttryckligen har
                  samtyckt till att tjänsten påbörjas utan ångerrätt.
                </li>
                <li>Varor som snabbt kan försämras, exempelvis livsmedel.</li>
                <li>
                  Lösnummer av tidningar eller tidskrifter.

                  <br />
                  <br />För mer om den lagstiftade ångerrätten,
                </li>
                Se
                <a
                  target="_blank"
                  href="http://www.konsumentverket.se/for-foretag/konsumentratt-for-foretagare/om-kunden-angrar-sitt-kop/"
                >
                  <strong>här</strong>.
                </a>
              </section>

              <br />
              <h4 ref="COMPLAINTS"><strong>REKLAMATIONER</strong></h4>
              <br />
              <div class="subtitle"><strong>Allmän information</strong></div>
              <p>
                Vi besiktigar alla produkter innan dessa skickas till dig.
                Skulle produkten ändå vara skadad eller felexpedierad när den
                anländer åtar vi oss i enlighet med gällande
                konsumentskyddslagstiftning att kostnadsfritt åtgärda felet. Du
                måste alltid kontakta oss för ett godkännande innan du
                returnerar en defekt vara. Klagomålet ska skickas omedelbart
                efter att defekten har upptäckts.
              </p>
              <br />
              <section class="has-text-left-by-one-rem">
                <strong>Hur går du tillväga vid reklamation?</strong>
                <li>
                  Eventuella fel och defekt ska alltid reklameras till
                  info@mobio.se där du anger ditt namn, din adress,
                  e-postadress, ordernummer och en beskrivning av felet.
                </li>
                <li>
                  Om det inte lyckas oss att åtgärda felet eller leverera en
                  liknande produkt, återbetalar vi dig för den defekta produkten
                  i enlighet med gällande konsumentskyddslagstiftning. Vi står
                  för returfrakt vid godkända reklamationer.
                </li>
                <li>
                  Vi förbehåller oss rätten att neka en reklamation om det visar
                  sig att varan inte är felaktig i enlighet med gällande
                  konsumentskyddslagstiftning. Vid reklamationer följer vi
                  riktlinjer från Allmänna Reklamationsnämnden, se arn.se.
                </li>
              </section>
              <br />

              Om det inte lyckas oss att åtgärda felet eller leverera en
              liknande produkt, återbetalar vi dig för den defekta produkten i
              enlighet med gällande konsumentskyddslagstiftning. Vi står för
              returfrakt vid godkända reklamationer.<br /><br />
              Vi förbehåller oss rätten att neka en reklamation om det visar sig
              att varan inte är felaktig i enlighet med gällande
              konsumentskyddslagstiftning. Vid reklamationer följer vi
              riktlinjer från Allmänna Reklamationsnämnden, se arn.se.

              <br />
              <br />
              <div class="subtitle"><strong>Ansvarsbegränsning</strong></div>
              <p>
                <strong
                  >Vi tar inget ansvar för indirekta skador som kan uppstå på
                  grund av produkten.</strong
                >
                Vi accepterar inget ansvar för förseningar/fel till följd av
                omständigheter utanför företagets rådande (Force Majeure). Dessa
                omständigheter kan exempelvis vara arbetskonflikt, eldsvåda,
                krig, myndighetsbeslut, förminskad eller utebliven leverans från
                leverantör. Vidare tas inget ansvar för eventuella förändringar
                på produkter/produktegenskaper som ändrats av respektive
                leverantör och andra faktorer utanför vår kontroll.
              </p>
              <br />
              <div class="subtitle"><strong>Olöst Tvist</strong></div>
              <p>
                I tillfälle av att tvist inte kan lösas i samförstånd med
                företagets kundtjänst och kunden, kan du som kund vända dig till
                Allmänna Reklamationsnämnden, se arn.se. För boende i ett annat
                EU-land än Sverige kan man lämna klagomål online via
                EU-kommissionens plattform för medling i tvister, se
                http://ec.europa.eu/consumers/odr Vid eventuell tvist följer vi
                beslut från ARN eller motsvarande tvistlösningsorgan. Tvist
                gällande tolkningen eller tillämpningen av dessa allmänna
                villkor ska tolkas i enlighet med svensk rätt och lag.
              </p>
              <br />

              <h4 ref="DATA_AND_PERSONINFO">
                <strong>DATA OCH PERSONUPPGIFTER</strong>
              </h4>
              <br />
              <div class="subtitle">
                <strong>Information om Cookies</strong>
              </div>
              <p>
                Enligt lag om elektronisk information ska besökare på en
                webbplats i integritetssyfte få information om att cookies
                används. Informationen i cookien är möjlig att använda för att
                följa en användares surfande. Cookie är en liten textfil som
                webbplatsen du besöker begär att få spara på din dator för att
                ge tillgång till olika funktioner. Det går att ställa in sin
                webbläsare så att den automatiskt nekar cookies. Mer information
                kan man hitta på Post och telestyrelsens hemsida.
              </p>
              <br />
              <div class="subtitle"><strong>Personuppgifter</strong></div>
              Genom att handla hos Mobio accepterar du vår dataskyddspolicy och
              vår behandling av dina personuppgifter. Vi värnar om din
              personliga integritet och samlar inte in fler uppgifter än
              nödvändigt för att behandla din beställning. Vi säljer eller
              vidareger aldrig dina uppgifter till tredjepart utan rättslig
              grund. Mobio är ansvarig för behandlingen av personuppgifter som
              du lämnat till oss som kund. Dina personuppgifter behandlas av oss
              för att kunna hantera din beställning samt i de tillfällen då du
              har önskat nyhetsbrev eller kampanjerbjudanden - för att kunna
              anpassa marknadsföringen åt dina individuella behov. Nedan
              information är en summering av hur vi i enlighet med
              <a
                href="https://www.datainspektionen.se/dataskyddsreformen/dataskyddsforordningen/"
                target="_blank"
                >dataskyddsförordningen</a
              >
              (GDPR) lagrar och behandlar dina uppgifter. <br /><br />
              <section class="has-text-left-by-one-rem">
                <ul>
                  <li>
                    <strong>Vad är en personuppgift?</strong><br />
                    En personuppgift är all information som direkt eller
                    indirekt kan hänföras till en fysisk person.<br /><br />
                  </li>
                  <li>
                    <strong>Vilka uppgifter lagrar vi?</strong><br />
                    För att kunna hantera din beställning samt svara på frågor
                    relaterat till din order (kundtjänst) lagrar vi ditt
                    förnamn- och efternamn, adress, telefonnummer, e-postadress,
                    ip-adress och köphistorik.<br /><br />
                    Dina uppgifter lagras så länge vi har en rättslig grund att
                    behandla dina uppgifter, exempelvis för att fullfölja
                    avtalet mellan oss eller för att efterleva en rättslig
                    förpliktelse enligt exempelvis bokföringslagen.<br /><br />
                  </li>
                  <li>
                    <strong>Rättslig grund</strong><br />
                    I samband med ett köp behandlas dina personuppgifter för att
                    fullfölja avtalet med dig.<br />
                    Marknadsföring, kampanjer och liknande utskick sker efter
                    samtycke från dig.<br /><br />
                  </li>
                  <li>
                    <strong>Vilka uppgifter delas och med vilket syfte?</strong
                    ><br />
                  </li>
                  <section class="has-text-left-by-one-rem">
                    <li><strong>Betalleverantör</strong></li>
                    <li>
                      Vid genomförande av köp, delas information med vår
                      betalleverantör. Det som lagras är förnamn, efternamn,
                      adress, e-postadress och telefonnummer. Väljer du att
                      betala med faktura sparas även personnummer hos
                      betalleverantören. Informationen sparas för att kunna
                      genomföra köpet och för att skydda parterna mot bedrägeri.
                      <br />
                      De betalleverantörer (betaltjänster) som vi använder oss
                      av är: Klarna, Swish, Bankgiro.<br /><br />
                    </li>
                    <li>
                      <strong>Fraktbolag</strong><br />
                      För att kunna leverera dina beställningar och slutföra
                      vårt avtal måste vi dela med specifik information med
                      fraktbolaget. Det som delas med fraktbolaget är förnamn,
                      efternamn samt adressuppgifter för leverans. E-postadress
                      och/eller mobilnummer kan även komma att delas med
                      fraktbolaget för avisering.<br />
                      De fraktbolag vi samarbetar med är Postnord och
                      Schenker.<br /><br />
                    </li>
                    <li>
                      <strong>Nyhetsbrev</strong><br />
                      Har du valt att prenumerera på vårt nyhetsbrev delas
                      förnamn, efternamn och e-postadress med vår
                      nyhetsbrevsleverantör. Detta för att kunna hålla dig
                      uppdaterad med information och erbjudanden i
                      marknadsföringssyfte.<br />
                      Vi använder för utskick av nyhetsbrev. <br /><br />
                    </li>
                  </section>
                  <li>
                    <strong>Rätten till tillgång</strong><br />
                    Du har rätt att få utdrag av all information som finns om
                    dig hos oss. Utdrag levereras elektroniskt i ett läsbart
                    format.<br /><br />
                  </li>
                  <li>
                    <strong>Rätt till rättelse</strong>
                    <br />
                    Du har rätt att be oss uppdatera felaktig information eller
                    komplettera information som är bristfällig.<br /><br />
                  </li>
                  <li>
                    <strong>Rätten att bli glömd</strong><br />
                    Du kan när som helst be att uppgifterna som avser dig
                    raderas. Det finns få undantag till rätten till radering,
                    som till exempel om det ska behållas för att vi måste
                    uppfylla en rättslig förpliktelse (exempelvis enligt
                    bokföringslagen).<br /><br />
                  </li>
                  <li>
                    <strong>Ansvarig för dataskydd</strong><br />
                    Mobio är ansvarig för lagring och behandling av
                    personuppgifter i webbutiken och ser till att reglerna
                    efterföljs.<br /><br />
                  </li>
                  <li>
                    <strong>Så skyddar vi dina personuppgifter</strong><br />
                    Vi använder oss av industristandarder som SSL/TLS och envägs
                    hash-algoritmer för att lagra, behandla och kommunicera
                    känslig information som exempelvis personuppgifter och
                    lösenord på ett säkert sätt.

                    <br />
                  </li>
                </ul>
              </section>
              <br />

              <h4 ref="OTHER"><strong>ÖVRIGT</strong></h4>
              <br />
              <div class="subtitle">
                <strong>Ändringar till de Allmänna Villkoren</strong>
              </div>
              <p>
                Vi förbehåller oss rätten att när som helst företa ändringar i
                villkoren. Ändringar av villkoren kommer att publiceras online
                på webbplatsen. De ändrade villkoren anses för accepterade i
                samband med order eller besök på webbplatsen.
              </p>
              <br />

              <i>Senast uppdaterad: 2023-07-25</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "policy-view",
  components: {},
  data: function () {
    return {
      policies: [
        {
          title: "FÖRETAGSINFORMATION",
          link: "BUSINESSINFORMATION",
        },
        {
          title: "PRODUKTINFORMATION OCH PRIS",
          link: "PRODUCTINFORMATION_AND_PRICE",
        },
        {
          title: "FÖRSÄLJNINGS OCH LEVERANSVILLKOR",
          link: "SALE_AND_POLICY",
        },
        {
          title: "REKLAMATIONER",
          link: "COMPLAINTS",
        },
        {
          title: "DATA OCH PERSONUPPGIFTER",
          link: "DATA_AND_PERSONINFO",
        },
        {
          title: "ÖVRIGT",
          link: "OTHER",
        },
      ],
    };
  },
  mounted() {
    this.solution([-1, -3]);
  },
  methods: {
    toggleFirstLevelItem: function (item) {
      item.isActive = !item.isActive;
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    solution(A) {
      var minNumber = A.reduce((a, b) => Math.min(a, b));

      if (minNumber < 0) {
        return 1;
      }

      A.some((element) => {
        var diff = element - minNumber;
        if (diff > 1) {
          minNumber = minNumber + 1;
          return true;
        } else {
          if (element > minNumber) {
            minNumber = element;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  box-shadow: none;
}
.subtitle {
  margin-bottom: 0 !important;
}

.first-level:hover {
  //background: $primary-default-cloud-blue-color;
}

.policy-view {
  padding: 2rem;
  .columns:before,
  .columns:after {
    display: none;
  }
}

.menu-list li ul {
  margin: 0 0.75rem 0.75rem 0.75rem;
}
.has-text-left-by-one-rem {
  position: relative;
  width: calc(100% - 2rem);
  left: 2rem;
}
li {
  display: block;
}
</style>
