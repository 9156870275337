<template>
  <div class="membership-view view">
    <div class="container box is-small">
      Vi jobbar stenhårt på att få denna sida att fungera. Återkom senare.
      <br />
      <RouterLink :to="{ name: 'home' }" class="button is-text has-top-margin"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MembershipView",
  components: {},
  data: function () {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.membership-view {
}
</style>
