<template>
  <div class="footer-bottom">
    <div class="columns container">
      <div class="column">
        <router-link :to="{ name: 'home' }" class="logo-wrapper">
          <img
            src="@/assets/images/svg/logo/mobioexpress-machine.svg"
            width="140"
            alt="Image"
          />
        </router-link>
        <p class="has-text-white is-size-5 has-top-margin">info@mobio.se</p>
        <p class="has-text-white is-size-5 has-half-top-margin">Oxelstigen 8</p>
        <p class="has-text-white is-size-5">432 46 VARBERG</p>
      </div>
      <div class="column">
        <h2 class="has-text-white is-bold">Kategorier</h2>
        <div class="separator has-bottom-margin"></div>
        <router-link
          :to="{ name: 'productSearchResult', query: { theme: 'funko' } }"
          class="item has-quarter-bottom-margin"
          >Funko POP!</router-link
        >
        <router-link
          :to="{ name: 'productSearchResult', query: { theme: 'lego' } }"
          class="item has-quarter-bottom-margin"
          >Lego</router-link
        >
        <router-link
          :to="{ name: 'productSearchResult', query: { theme: 'barbie' } }"
          class="item has-quarter-bottom-margin"
          >Barbie</router-link
        >
        <div class="split"></div>
        <router-link
          :to="{ name: 'productSearchResult', query: { theme: 'pokémon' } }"
          class="item has-quarter-bottom-margin"
          >Pokémon</router-link
        >
        <router-link
          :to="{ name: 'productSearchResult', query: { theme: 'nintendo' } }"
          class="item has-quarter-bottom-margin"
          >Nintendo</router-link
        >
      </div>
      <div class="column">
        <h2 class="has-text-white is-bold">Mobio</h2>
        <div class="separator has-bottom-margin"></div>
        <router-link
          :to="{ name: 'about' }"
          class="item has-quarter-bottom-margin"
          >Om</router-link
        >
        <router-link
          :to="{ name: 'policy' }"
          class="item has-quarter-bottom-margin"
          >Villkor</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBottom",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.footer-bottom {
  z-index: 1;
  background: #272727;
  width: 100%;
  position: relative;
  //bottom: -26px;
  padding: 6rem 2rem;
  a {
    display: block;
    font-size: 22px;
    color: white;
    //border-bottom: 2px solid white;
  }
}
</style>
