<template>
  <div class="tutorial-modal has-text-left">
    <ModalPopup v-if="!userHasReadManual" :width="700">
      <template v-slot:header>
        <img
          src="@/assets/images/svg/spellify-city-header.svg"
          :style="{ position: 'absolute', top: '-86px', left: '0px' }"
        />
      </template>
      <template v-slot:content>
        <div style="position: relative" class="welcome">
          <section class="has-2x-top-padding" v-if="view === 'NEW-USER'">
            <h1 class="has-text-centered">Välkommen!</h1>
            <p class="subtitle has-text-centered" style="line-height: 1.5">
              Denna butik fokuserar på att erbjuda populära och efterfrågade
              leksaker till bra priser. Vi vill därför bjuda in dig till att
              vara med om att bestämma utbud och pris på produkterna som läggs
              in i vår digitala varuautomat.
            </p>
            <p class="subtitle has-text-centered" style="line-height: 1.5">
              Du godkänner nödvändiga cookies för att sidan ska fungera korrekt.
            </p>
            <div
              class="buttons has-3x-top-margin"
              style="display: flex; justify-content: center"
            >
              <button class="button is-primary" @click="onExit">
                Jag godkänner cookies
              </button>
            </div>
          </section>
        </div>
      </template>
    </ModalPopup>
  </div>
</template>

<script>
import ModalPopup from "./../general/ModalPopup.vue";
export default {
  name: "TutorialModal",
  components: { ModalPopup },
  data: function () {
    return {
      view: "NEW-USER",
      lastWordHasError: false,
      words: [],
      quotation: ["Gothenburg", "is", "a", "beautiful", "city"],
      typeCheckSuccess: false,
    };
  },
  props: {
    userHasReadManual: {
      default: false,
    },
  },
  created() {},
  methods: {
    validate: function (e) {
      let value = e.target.value.trim();

      if (this.quotation[this.words.length] === value) {
        this.words.push(value);
        e.target.value = "";
        this.lastWordHasError = false;
      } else {
        this.lastWordHasError = true;
        e.target.value = value;
      }
      if (this.words.length === this.quotation.length)
        this.typeCheckSuccess = true;
    },
    onExit: function () {
      this.$emit("has-read", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial-modal {
  position: relative;
  z-index: 999999;
}
.type-example {
  background: #f3f3f3;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}

.welcome {
  padding-top: 14.5rem;
}

@media screen and (max-width: 768px) {
  .welcome {
    padding-top: 7.5rem;
  }
  img {
    //margin-top: 20vh !important;
    top: -33px !important;
  }
}
.is-success {
  color: green;
}
.is-error {
  color: red;
}
.is-bold {
  font-weight: bold;
}
.success-box {
  background: green;
  color: white;
  padding: 0.5rem 0;
  border-radius: 5px;
}

.keystroke {
  background: #f3f3f3;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: 700;
  border: 1px solid lightgray;
  border-radius: 3px;
}
@keyframes affected-animation {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
.is-affected {
  animation: 0.5s affected-animation 0.1s infinite;
}

.skip-button {
  font-size: 12px;
}

.tutorial-content {
  width: 500px;
  margin: 0 auto;
  .example-content {
    width: 400px;
    margin: 0 auto;
  }
}
</style>
