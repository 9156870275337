<template>
  <div class="home view">
    <div class="goteborg"></div>
    <section v-if="viewIsLoading" class="loading-start">
      <div class="loader" style="width: 3em; height: 3em"></div>
      <p class="has-text-white subtitle has-top-margin">Laddar varuautomat</p>
    </section>
    <section
      v-show="!viewIsLoading"
      class="home-content"
      :style="{ top: $store.state.authUser ? '130px' : '280px' }"
    >
      <div class="container is-medium">
        <div class="columns is-mobile is-multiline">
          <div class="column is-full" v-if="!$store.state.authUser">
            <div class="card is-relative nifty" style="height: 100px">
              <img
                class="is-horizontal-middle lighthouse"
                src="@/assets/images/svg/lighthouse.svg"
                alt="Image"
              />
              <img
                src="@/assets/images/svg/nifty.svg"
                class="family"
                alt="Image"
              />
              <div class="why-member">
                <p class="has-text-black has-no-margin">Gå med i klubben!</p>
                <ol>
                  <li class="is-size-7 has-text-black">
                    👉 Få poäng på varje köp
                  </li>
                  <li class="is-size-7 has-text-black">👉 Medlemspriser</li>
                  <li class="is-size-7 has-text-black">
                    👉 Bli först med att få nya erbjudanden
                  </li>
                </ol>
              </div>
              <button
                @click="$router.push('register')"
                class="button is-primary"
                style="position: absolute; top: calc(50% - 20px); right: 2rem"
              >
                Skapa konto
              </button>
            </div>
          </div>
          <div class="column is-full">
            <div class="has-half-bottom-margin">
              <CampaignBlock />
            </div>
            <div class="has-top-margin"><SmallCampaignBlock /></div>
          </div>

          <div class="column is-half-mobile">
            <RouterLink :to="{ name: 'monthlyOffers' }">
              <div
                class="card is-relative has-shadow-on-hover has-small-scale-on-hover"
                style="
                  background: rgb(27 72 87);
                  height: 140px;
                  overflow: hidden;
                "
              >
                <img
                  class="is-horizontal-middle"
                  src="@/assets/images/svg/bg-unknown.svg"
                  style="
                    width: 100%;

                    border-bottom-right-radius: 8px;
                  "
                  alt="Image"
                />
                <p
                  class="is-size-4 is-horizontal-middle card-title"
                  style="
                    position: absolute;
                    bottom: 1rem;
                    color: white;
                    line-height: 1.2;
                  "
                >
                  AKTUELLA ERBJUDANDEN
                </p>
              </div>
            </RouterLink>
          </div>

          <div class="column is-half-mobile">
            <RouterLink :to="{ name: 'wow' }">
              <div
                class="card is-relative has-shadow-on-hover has-small-scale-on-hover"
                style="background: rgb(54, 54, 54); height: 140px"
              >
                <img
                  class="is-horizontal-middle"
                  src="@/assets/images/svg/starwars-ship.svg"
                  style="height: 100%; top: -1rem"
                  alt="Image"
                />
                <p class="is-size-4 is-horizontal-middle card-title">WOOW!</p>
              </div>
            </RouterLink>
          </div>
          <div class="column is-half-mobile">
            <RouterLink
              :to="{
                name: 'productSearchResult',
                query: { opt: 'pre-order' },
              }"
            >
              <div
                class="card is-relative has-shadow-on-hover has-small-scale-on-hover"
                style="background: rgb(38 39 57); height: 140px"
              >
                <img
                  class="is-horizontal-middle"
                  src="@/assets/images/svg/preorder2.svg"
                  style="height: 90%; top: -2rem"
                  alt="Image"
                />
                <p
                  class="is-size-4 is-horizontal-middle card-title"
                  style="position: absolute; bottom: 1rem; color: white"
                >
                  FÖRBOKNINGAR
                </p>
              </div>
            </RouterLink>
          </div>
          <div class="column is-half-mobile">
            <div
              class="card is-relative has-padding"
              style="background: rgb(58, 16, 247); height: 140px"
            >
              <img
                class=""
                src="@/assets/images/svg/error-icon-white.svg"
                style="height: 28px"
                alt="Image"
              />
              <p class="is-size-6" style="color: white; line-height: 1.2">
                Vi håller på att ta fram flera nya tjänster för våra medlemmar.
                <router-link :to="{ name: 'health' }" class="health-button"
                  >Läs mer</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container vending-machine has-2x-top-margin"
        :class="{ 'is-8deg': view === 'POLICY' }"
      >
        <div class="top-header" v-if="view === 'AUTOMAT'">
          <img
            style=""
            src="@/assets/images/svg/logo/mobioexpress-machine.svg"
            width="100"
            alt="Image"
          />
          <img
            class="stickers"
            src="@/assets/images/svg/automat.svg"
            width="270"
            alt="Image"
          />
          <span>Öppen 24/7 - Alltid fri frakt på över 500 kr</span>
        </div>
        <article class="machine-container" v-show="view === 'AUTOMAT'">
          <div class="cart-content">
            <CartInfo isMobile="true" />
          </div>
          <div class="display">
            <ProductList
              :products="products"
              :isLoadmoreButtonDisabled="isLoadmoreProductsDisabled()"
              @load-more="loadMoreProducts"
              :is-loadmore-loading="isLoadmoreProductsLoading"
            />
          </div>
        </article>
        <div class="machine-display">
          <div
            :class="{ overlay: $store.state.cartIsHighlighted }"
            @click="onOverlay"
          ></div>
          <div
            class="cart expand"
            :class="{ expand: isCheckingOut }"
            @click="setCartIsHighlighted"
          >
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <div>Varukorg</div>
                  <div class="amount">{{ getCartValueWithShipping() }} kr</div>
                </div>
              </div>
              <figure class="media-right">
                <p class="image is-16x16 has-top-margin">
                  <img src="@/assets/images/svg/dropdown-down.svg" />
                </p>
              </figure>
            </article>
          </div>
        </div>
        <div class="footer-container"></div>
        <div class="left-foot"></div>
        <div class="right-foot"></div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
import ProductList from "@/components/ProductList.vue";
import CartInfo from "@/components/CartInfo.vue";
import { RouterLink } from "vue-router";
import CampaignBlock from "@/components/blocks/CampaignBlock.vue";
import SmallCampaignBlock from "@/components/blocks/SmallCampaignBlock.vue";

export default {
  name: "HomeView",
  components: {
    ProductList,
    CartInfo,
    RouterLink,
    CampaignBlock,
    SmallCampaignBlock,
  },
  data() {
    return {
      products: [],
      isCheckingOut: false,
      view: "AUTOMAT",
      viewIsLoading: true,
      currentPage: 0,
      nbPages: 0,
      isLoadmoreProductsLoading: false,
    };
  },
  async created() {
    this.isLoadmoreProductsLoading = true;
    const results = await ProductService.searchActivatedProductsBy(
      "",
      this.currentPage,
      "",
      " AND available > 0"
    );

    this.products = results.hits;
    this.nbPages = results.nbPages;
    this.isLoadmoreProductsLoading = false;

    setTimeout(() => {
      this.viewIsLoading = false;
    }, 1);
  },
  methods: {
    isLoadmoreProductsDisabled() {
      if (this.nbPages === 0) return true;
      return this.currentPage === this.nbPages - 1;
    },
    async loadMoreProducts() {
      this.isLoadmoreProductsLoading = true;
      this.currentPage = this.currentPage + 1;
      const results = await ProductService.searchActivatedProductsBy(
        "",
        this.currentPage,
        "",
        " AND available > 0"
      );
      this.products = this.products.concat(results.hits);
      this.isLoadmoreProductsLoading = false;
    },
    onOverlay() {
      this.setCartIsHighlighted();
    },
    onCheckout() {
      this.isCheckingOut = true;
      setTimeout(() => {
        this.$router.push({ name: "checkout" });
      }, 1000);
    },
    getCartValue() {
      return this.$store.getters.getCart.reduce((acc, product) => {
        return acc + product.price * product.quantity;
      }, 0);
    },
    getCartVatValue() {
      return (
        Math.round(this.getCartValueWithShipping() * 0.2 * 100) / 100
      ).toFixed(2);
    },
    getCartValueWithShipping() {
      let cost = this.$store.getters.getCart.reduce((acc, product) => {
        return acc + product.price * product.quantity;
      }, 0);
      if (cost === 0) return 0;
      else if (cost < 500) {
        cost = cost + 59;
      }
      return cost;
    },
    onUpdateProductQuantity(event, product) {
      if (event > product.quantity) {
        this.$store.commit("addProductToCart", product);
      }

      if (product.quantity === 0) {
        this.$store.commit("removeQuantityFromProduct", product);
      }
    },
    setCartIsHighlighted() {
      this.$store.commit(
        "setCartIsHighlighted",
        !this.$store.state.cartIsHighlighted
      );
    },
  },
};
</script>

<style lang="scss">
.home {
  .health-button {
    color: white !important;
    text-decoration: underline;
  }
  .card-title {
    position: absolute;
    bottom: 1rem;
    color: white;
  }
  .nifty {
    border: 3px solid #2647e4;
    background: rgb(120, 198, 200);
    background: -moz-linear-gradient(
      180deg,
      rgba(120, 198, 200, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(120, 198, 200, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(120, 198, 200, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#78c6c8",endColorstr="#ffffff",GradientType=1);
    img.family {
      width: 500px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .why-member {
      position: absolute;
      top: 0.5rem;
      left: calc(50% + 1rem);
    }
  }
  .card {
    border-radius: 8px;
  }
  .home-content {
    position: relative;
    top: calc(25vh);
  }
}

.cart {
  //width: 140px;
  //height: 50px;

  cursor: pointer;
  background: rgb(54, 54, 54);
  border-radius: 8px;

  color: white;

  //right: 1rem;
  width: 100%;
  padding: 1rem 1rem;
  font-size: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(120%);
  }

  &.expand {
    animation: expand-animation 0.5s linear forwards;
  }

  @keyframes expand-animation {
    from {
      transform: scale(1);
    }
    to {
      //transform: scale(9);
    }
  }

  .amount {
    font-size: 14px;
    text-align: right;
  }
}
.is-50x50 {
  width: 40px;
  height: 40px;
}

li {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.bubble {
  background-color: rgb(241, 241, 241);
  padding: 0.5rem;
}

hr {
  margin: 0.5rem 0 !important;
  background-color: rgb(66, 66, 66);
  &.is-secondary {
    background-color: rgb(207, 207, 207);
  }
}

.stickers {
  position: absolute;
  top: 3rem;
  right: 3rem;
}
@media screen and (max-width: 992px) {
  .nifty {
    img.family {
      width: 300px !important;
    }
  }
  .stickers {
    left: 2rem;
    top: 7rem;
    position: absolute;
  }

  .price {
    display: block !important;
    section:first-of-type {
      margin-bottom: 0.5rem;
      div {
        width: 100% !important;
      }
    }
    p {
      display: block !important;
    }
  }

  .vending-machine {
    //top: 34vh !important;

    padding: 0;
  }
  .top-header {
    padding: 1rem 0rem 0 1rem !important;
    margin-bottom: 4rem;
    &:before {
      //display: none;
      top: 1rem !important;
      left: 8.4rem !important;
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
    span {
      left: 1rem !important;
      font-size: 12px;
    }
  }

  .logo {
    //width: 140px;
    top: 5vh;
  }

  .columns:before,
  .columns:after {
    display: none;
  }

  .subtitle {
    font-size: 16px !important;
    line-height: 1.4 !important;
  }

  .policy-view {
    padding: 2rem 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .home .card-title {
    font-size: 18px !important;
  }
  .nifty {
    img.family {
      display: none;
    }
    .why-member {
      left: calc(1rem) !important;
    }
  }
  .price {
    display: block;
  }
  .display {
    background: transparent !important;
  }

  .product-list {
    padding: 0 !important;
    .columns {
      display: flex;
    }
  }
}
.goteborg {
  position: fixed;
  height: 50vh;
  bottom: 0;
  width: 100%;
  background-image: url("../assets/images/svg/gotenborg-mobio.svg");
  //background-image: url("https://cdn.pixabay.com/photo/2016/12/10/01/46/blue-1896481_960_720.png");
  background-repeat: repeat-x;
  img {
    height: 100%;
  }
}
@media screen and (max-width: 1299px) {
  .goteborg {
    height: 10vh;
  }
}

.lighthouse {
  height: 300px;
  bottom: 300px;
}

@media screen and (max-width: 1800px) {
  .lighthouse {
    height: 220px;
    bottom: 220px;
  }
}

@media screen and (max-width: 768px) {
  .lighthouse {
    width: 160px;
    bottom: 202px;
  }
}

.loading-start {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  .loader {
    left: calc(50% - 1.5em);
  }
}
</style>
