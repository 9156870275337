<template>
  <div class="about-view view">
    <div class="container vending-machine-checkout wrapper">
      <Policy></Policy>
      <br />
      <RouterLink
        :to="{ name: 'home' }"
        class="button is-info is-horizontal-middle"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
import Policy from "@/components/Policy.vue";
export default {
  name: "policy-view",
  components: { Policy },
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
