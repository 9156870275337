<template>
  <div class="health-view view">
    <div class="container box is-small">
      <p class="has-top-margin">
        Just nu håller vi på att utveckla tjänsterna som ni finner nedan. Dessa
        funktioner är av högsta prioritet oh beräknas att bli färdiga så fort
        som möjligt.
      </p>
      <br />
      <ul>
        <li>1. Mitt konto - Se och uppdatera fler kontaktuppgifter</li>
        <li>2. Medlemskap - Se hur och när nya poäng registrerades.</li>
      </ul>
      <br />
      <p><i>Texten uppdaterades: 29 December 2023</i></p>

      <br />
      <RouterLink :to="{ name: 'home' }" class="button is-info"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "health-view",
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
