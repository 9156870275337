import { auth, database } from "@/main";
import { signInWithEmailAndPassword, signInAnonymously } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import {
  sendEmailVerification,
  createUserWithEmailAndPassword,
  getAuth,
} from "firebase/auth";

const COLLECTION_USERS = "users";

const AuthService = {
  logout() {
    return auth.signOut();
  },
  loginAnonymously() {
    return signInAnonymously(auth)
      .then(async () => {
        //console.log("user", auth.currentUser);
        // Signed in..
      })
      .catch(() => {
        //const errorCode = error.code;
        //const errorMessage = error.message;
        // ...
      });
  },
  login(user) {
    return signInWithEmailAndPassword(auth, user.email, user.password)
      .then(() => {
        return auth.currentUser;
      })
      .catch(function (error) {
        //const errorCode = error.code;
        const errorMessage = error.message;
        return new Promise((resolve, reject) => {
          reject({ message: errorMessage });
        });
      });
    /*return auth.signInWithEmailAndPassword(user.email, user.password).then((aut) => {
      return auth.currentUser;
    });*/
  },
  isVerified() {
    return auth.currentUser.emailVerified;
  },
  async getUserBy(authId) {
    const docRef = doc(database, COLLECTION_USERS, authId);
    const docSnap = await getDoc(docRef);
    return {
      id: docSnap.id,
      ...docSnap.data(),
    };
  },
  async createAuthUser(email, password) {
    return await createUserWithEmailAndPassword(getAuth(), email, password);
  },
  async requestVerificationMail() {
    return await sendEmailVerification(auth.currentUser);
  },
  sendPasswordResetEmail(email) {
    return auth.sendPasswordResetEmail(email);
  },
};

export default AuthService;
