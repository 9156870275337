import { initializeApp, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import "./assets/general.scss";
import store from "./store";
import { getAnalytics } from "firebase/analytics";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { getStripePayments } from "@stripe/firestore-stripe-payments";

/*Vue.component("font-awesome-icon", FontAwesomeIcon);*/
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Buefy);

import moment from "moment";
Vue.prototype.moment = moment;

const fbApp = initializeApp({
  apiKey: "AIzaSyB2UPcPAxNFnBYIyLMOo3wDqQxQQnTvEGI",
  authDomain: "mobio-express.firebaseapp.com",
  projectId: "mobio-express",
  storageBucket: "mobio-express.appspot.com",
  messagingSenderId: "714034008773",
  appId: "1:714034008773:web:6200f87ec69d75990096ac",
  measurementId: "G-2DFK7F15FZ",
});

export const functions = getFunctions(fbApp, "europe-west1");
export const database = getFirestore(fbApp);
export const auth = getAuth();
export const analytics = getAnalytics(fbApp);

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
export const app = new Vue({
  router,
  render: function (h) {
    return h(App);
  },
  store: store,
}).$mount("#app");

export const stripePayments = getStripePayments(getApp(), {
  productsCollection: "stripeProducts",
  customersCollection: "stripeCustomers",
});
