<template>
  <div class="product-search-result-view">
    <div class="container box has-no-border has-no-padding">
      <PolicyBanner></PolicyBanner>
    </div>
    <div class="container has-no-padding box">
      <section class="flex is-space-between results">
        <article class="media">
          <figure class="media-left">
            <p
              class="image is-64x64"
              v-if="
                [
                  'pokémon',
                  'nintendo',
                  'barbie',
                  'lego',
                  'funko',
                  'pikachu',
                ].includes(theme)
              "
            >
              <img
                src="@/assets/images/svg/menu/pikachu.svg"
                v-if="theme === 'pokémon'"
              />
              <img
                src="@/assets/images/svg/menu/zelda.svg"
                v-else-if="theme === 'nintendo'"
              />
              <img
                src="@/assets/images/svg/menu/barbie.svg"
                v-else-if="theme === 'barbie'"
              />
              <img
                src="@/assets/images/svg/menu/lego.svg"
                v-else-if="theme === 'lego'"
              />
              <img
                src="@/assets/images/svg/menu/funko.svg"
                v-else-if="theme === 'funko'"
              />

              <img
                src="@/assets/images/svg/menu/pikachu.svg"
                v-else-if="theme === 'pikachu'"
              />
            </p>
          </figure>
          <div class="media-content is-vertical-middle">
            <h2 class="is-uppercase is-bold" style="line-height: 1.2">
              {{ theme }}
            </h2>
            <p
              class="is-size-7"
              v-if="
                [
                  'pokémon',
                  'nintendo',
                  'barbie',
                  'lego',
                  'funko',
                  'pikachu',
                ].includes(theme)
              "
            >
              Visar {{ products.length }} av {{ nbHits }} resultat
            </p>
            <p v-else>Visar {{ products.length }} av {{ nbHits }} resultat</p>
          </div>
        </article>
        <section class="controllers">
          <div class="field is-grouped is-grouped-centered">
            <div class="">
              <b-checkbox
                @input="onSelectFilter"
                type="is-info"
                class="is-vertical-middle has-right-margin"
                v-model="showOnlyAvailable"
                native-value="Visa tillgängliga"
              >
                Visa endast i lager
              </b-checkbox>
              <b-checkbox
                @input="onSelectFilter"
                type="is-info"
                v-model="showOnlyPreRelease"
                class="is-vertical-middle"
                native-value="Visa tillgängliga"
              >
                Visa endast förbokningar
              </b-checkbox>
            </div>
            <div class="field has-addons has-left-margin">
              <p
                class="control"
                @mouseenter="isHoveringList = 'LIST-WINDOW'"
                @mouseleave="isHoveringList = null"
              >
                <button
                  class="button product-view"
                  @click="selectedView = 'LIST-WINDOW'"
                  :class="{
                    'is-dark is-selected': selectedView === 'LIST-WINDOW',
                  }"
                >
                  <span class="image is-16x16">
                    <img
                      src="@/assets/images/svg/menu/list-window-white.svg"
                      v-if="
                        (selectedView !== 'LIST-WINDOW' && isHoveringList) ||
                        selectedView === 'LIST-WINDOW'
                      "
                    />
                    <img
                      v-else
                      src="@/assets/images/svg/menu/list-window.svg"
                    />
                  </span>
                </button>
              </p>
              <p
                class="control"
                @mouseenter="isHoveringList = 'LIST'"
                @mouseleave="isHoveringList = null"
              >
                <button
                  class="button product-view"
                  @click="selectedView = 'LIST'"
                  :class="{
                    'is-dark is-selected': selectedView === 'LIST',
                  }"
                >
                  <span class="image is-16x16">
                    <img
                      v-if="
                        (selectedView !== 'LIST' && isHoveringList) ||
                        selectedView === 'LIST'
                      "
                      src="@/assets/images/svg/menu/list-white.svg"
                    />
                    <img v-else src="@/assets/images/svg/menu/list.svg" />
                  </span>
                </button>
              </p>
            </div>
            <b-field class="form-element">
              <b-select
                placeholder="Sortera"
                @input="onSelectSortMethod"
                expanded
              >
                <option
                  v-for="option in sort"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </section>
      </section>
      <section class="mobile-controllers">
        <div class="field is-grouped is-grouped-centered">
          <div class="field has-addons has-left-margin">
            <p
              class="control"
              @mouseenter="isHoveringList = 'LIST-WINDOW'"
              @mouseleave="isHoveringList = null"
            >
              <button
                class="button product-view"
                @click="selectedView = 'LIST-WINDOW'"
                :class="{
                  'is-dark is-selected': selectedView === 'LIST-WINDOW',
                }"
              >
                <span class="image is-16x16">
                  <img
                    src="@/assets/images/svg/menu/list-window-white.svg"
                    v-if="
                      (selectedView !== 'LIST-WINDOW' && isHoveringList) ||
                      selectedView === 'LIST-WINDOW'
                    "
                  />
                  <img v-else src="@/assets/images/svg/menu/list-window.svg" />
                </span>
              </button>
            </p>
            <p
              class="control"
              @mouseenter="isHoveringList = 'LIST'"
              @mouseleave="isHoveringList = null"
            >
              <button
                class="button product-view"
                @click="selectedView = 'LIST'"
                :class="{
                  'is-dark is-selected': selectedView === 'LIST',
                }"
              >
                <span class="image is-16x16">
                  <img
                    v-if="
                      (selectedView !== 'LIST' && isHoveringList) ||
                      selectedView === 'LIST'
                    "
                    src="@/assets/images/svg/menu/list-white.svg"
                  />
                  <img v-else src="@/assets/images/svg/menu/list.svg" />
                </span>
              </button>
            </p>
          </div>

          <b-field class="form-element">
            <b-select
              placeholder="Sortera"
              expanded
              @input="onSelectSortMethod"
            >
              <option
                v-for="option in sort"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </section>

      <div class="">
        <section v-if="viewIsLoading" class="">
          <div class="columns is-multiline">
            <div
              class="column is-one-fifth"
              v-for="col of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
              :key="col"
            >
              <div class="has-padding">
                <b-skeleton
                  width="100%"
                  height="200"
                  :animated="true"
                ></b-skeleton>
                <b-skeleton
                  width="70%"
                  height="30"
                  :animated="true"
                ></b-skeleton>
              </div>
            </div>
          </div>
        </section>
        <div v-else>
          <div v-if="products.length === 0">
            <p
              class="has-text-centered has-2x-top-padding has-2x-bottom-padding"
            >
              Det finns inga produkter.
            </p>
          </div>
          <ProductList
            v-else
            :view="selectedView"
            :products="products"
            :isLoadmoreButtonDisabled="isLoadmoreProductsDisabled()"
            @load-more="loadMoreProducts"
            :is-loadmore-loading="isLoadmoreProductsLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
import ProductList from "@/components/ProductList.vue";
import PolicyBanner from "@/components/policyBanner.vue";
export default {
  name: "ProductSearchResultView",
  components: { ProductList, PolicyBanner },

  data() {
    return {
      viewIsLoading: true,
      products: [],
      productIsLoading: false,
      currentPage: 0,
      nbPages: 0,
      nbHits: 0,
      isLoadmoreProductsLoading: false,
      theme: "",
      isHoveringList: null,

      showOnlyAvailable: true,
      showOnlyPreRelease: false,
      selectedView: "LIST-WINDOW",
      selectedSortMethod: "",

      sort: [
        { id: 1, method: "A-O", name: "Namn A-Ö" },
        { id: 2, method: "O-A", name: "Namn Ö-A" },
        { id: 3, method: "LOW-HIGH", name: "Pris lägst-högst" },
        { id: 4, method: "HIGH-LOW", name: "Pris högst-lägst" },
      ],
    };
  },
  async created() {
    this.theme = this.$route.query.theme;
    const isPreOrderOptOn = this.$route.query.opt;
    if (isPreOrderOptOn) this.showOnlyPreRelease = true;

    this.loadByTheme();
  },
  methods: {
    async onSelectFilter() {
      this.products = [];
      this.currentPage = 0;
      this.loadByTheme();
    },
    async onSelectSortMethod(val) {
      this.selectedSortMethod = this.sort[val - 1].method;
      this.products = [];
      this.loadByTheme();
    },
    async loadByTheme() {
      this.isLoadmoreProductsLoading = true;
      let filter = "";

      if (this.showOnlyPreRelease) {
        filter = filter + " AND isBookable=1";
      }

      if (this.showOnlyAvailable) {
        filter = filter + " AND available > 0";
      }

      const results = await ProductService.searchActivatedProductsBy(
        this.theme,
        this.currentPage,
        this.selectedSortMethod,
        filter
      );

      this.products = results.hits;
      this.nbPages = results.nbPages;
      this.nbHits = results.nbHits;
      this.isLoadmoreProductsLoading = false;

      setTimeout(() => {
        this.viewIsLoading = false;
      }, 1000);
    },
    isLoadmoreProductsDisabled() {
      if (this.nbPages === 0) return true;
      return this.currentPage === this.nbPages - 1;
    },
    async loadMoreProducts() {
      this.isLoadmoreProductsLoading = true;

      let filter = "";

      if (this.showOnlyPreRelease) {
        filter = filter + " AND isBookable=1";
      }

      if (this.showOnlyAvailable) {
        filter = filter + " AND available > 0";
      }
      this.currentPage = this.currentPage + 1;

      const results = await ProductService.searchActivatedProductsBy(
        this.theme,
        this.currentPage,
        this.selectedSortMethod,
        filter
      );
      this.products = this.products.concat(results.hits);
      this.isLoadmoreProductsLoading = false;
    },
  },
  computed: {
    currentTheme() {
      return this.$route.query.theme;
    },
  },
  watch: {
    async currentTheme(newVal) {
      this.theme = newVal;
      this.loadByTheme();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/variables.scss";
.product-search-result-view {
  .results {
    position: sticky;
    top: 98px;
    z-index: 999;
    background: $grey-lightest;
    border-bottom: 1px solid rgb(233, 233, 233);
    //border: $radius-small;
    padding: 0.5rem 1rem;
    .form-element {
      width: 240px;
      padding-right: 1.5rem;
    }
  }
  button.product-view:not(.is-dark) {
    background: $grey;
    border-color: $grey;
    &:hover {
      background: $grey-dark;
    }
  }

  .mobile-controllers {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .results {
      top: 60px;
    }
    .controllers {
      display: none;
    }
    .mobile-controllers {
      display: block;
    }
  }
}
</style>
