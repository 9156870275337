<template>
  <div class="search-view">
    <div class="overlay ov-search" style="z-index: 9999" @click="onClose"></div>
    <div
      class="container has-text-left search"
      :class="{ 'has-content-open': products.length }"
      style="display: flex; justify-content: space-between"
    >
      <div class="search-logo container">
        <img
          src="@/assets/images/svg/logo/mobioexpress-machine.svg"
          width="100"
          alt="Image"
        />
      </div>

      <div style="width: calc(100% - 60px)">
        <input
          style="display: inline-block"
          v-on:input="getAsyncData"
          @typing="getAsyncData"
          class="input"
          type="text"
          placeholder="Vad letar du efter?"
        />
      </div>
      <div>
        <button class="search-button" @click="onClose">STÄNG</button>
      </div>
    </div>
    <section class="search-content container">
      <div v-if="searchIsLoading">
        <div class="container has-text-left">
          <div class="search-content-middle">
            <div
              class="loader"
              style="width: 3em; height: 3em; left: calc(50% - 1.5rem)"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="products.length === 0 && hasSearchedOnce">
        <div class="container has-text-left">
          <div class="search-content-middle">
            Kunde inte hitta några produkter.
          </div>
        </div>
      </div>
      <div
        v-else-if="!searchIsLoading && products.length !== 0"
        class="container has-text-left"
        style="display: flex; justify-content: space-between"
      >
        <div class="search-content-left">
          <div class="header">Sökresultat: {{ products.length }} produkter</div>

          <div class="results">
            <div
              class="result-card"
              v-for="product in products"
              :key="product.objectID"
              @mouseover="hoveredProduct = product"
              @click="onClose"
            >
              <router-link
                :to="{ name: 'product', params: { id: product.objectID } }"
              >
                <article class="media">
                  <figure class="media-left">
                    <p class="image is-64x64">
                      <img :src="product.image" />
                    </p>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <p class="">{{ product.name }}</p>
                    </div>
                  </div>
                  <div class="media-right">
                    <figure class="image is-16x16">
                      <img src="@/assets/images/svg/right-arrow.svg" />
                    </figure>
                  </div>
                </article>
              </router-link>
            </div>
          </div>
        </div>
        <div class="search-content-right has-padding">
          <ProductInfo :product="hoveredProduct" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
//import { debounce } from "./../services/helpers/Debounce";
import debounce from "lodash/debounce";
import ProductInfo from "./ProductInfo.vue";

export default {
  name: "search-view",
  components: { ProductInfo },
  data() {
    return {
      products: [],
      hoveredProduct: null,
      searchIsLoading: false,
      hasSearchedOnce: false,
    };
  },
  mounted() {},
  methods: {
    onClose: function () {
      this.$emit("on-close", true);
    },
    getAsyncData: debounce(async function (event) {
      const name = event.target.value;
      if (!name) {
        this.products = [];
        this.hoveredProduct = null;
        this.hasSearchedOnce = false;
        return;
      }
      this.searchIsLoading = true;

      const searchProducts = await ProductService.searchActivatedProductsBy(
        name,
        0,
        "",
        " AND available > 0"
      );

      this.products = searchProducts.hits;
      this.searchIsLoading = false;
      this.hasSearchedOnce = true;
    }, 1000),
  },
};
</script>

<style scoped lang="scss">
.search-view {
  //position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9999;
  .has-content-open {
    input {
      border-bottom-left-radius: 0 !important;
      //border-bottom-right-radius: 0;
    }
    .search-button {
      border-bottom-right-radius: 0 !important;
    }
  }
  .container {
    max-width: 800px !important;
  }
  .search-logo {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
  }
  //background-color: red;
  .search-content {
    //background-color: white;
    position: relative;
    top: calc(66px + 2rem) !important;
    left: 0;
    top: 81px !important;
    z-index: 9999;
    padding: 0 !important;
    margin-bottom: 1px solid #2647e8;

    .search-content-middle {
      width: 100%;
      background-color: white;
      padding: 1rem;
    }
    .search-content-left {
      background-color: white;
      border-right: 1px solid rgb(232, 232, 232);
      width: 50%;
      max-height: 70vh;
      overflow: auto;
      border-left: 1px solid #2647e8;
      border-bottom: 1px solid #2647e8;
      .header {
        padding: 0.25rem 1rem;
        font-size: 12px;
        font-weight: bold;
        background: rgb(232, 232, 232);
      }

      .media-right {
        visibility: hidden;

        margin-top: 24px;
        margin-right: 12px;
      }
      .results {
        .result-card {
          cursor: pointer;

          border-bottom: 1px solid rgb(232, 232, 232);
          .media-content p {
            font-size: 14px;
            color: rgb(96, 96, 96);
          }
          &:hover {
            background: #2647e8;
            color: white;
            .media-content p {
              color: white;
            }
            .media-right {
              visibility: visible;
            }
          }
        }
      }
    }
    .search-content-right {
      background-color: white;
      width: 50%;
      max-height: 70vh;
      overflow: auto;
      border-right: 1px solid #2647e8;
      border-bottom: 1px solid #2647e8;
      box-shadow: inset 1px 1px 2px hsla(230, 6%, 60%, 0.24),
        inset 2px 2px 8px hsla(230, 6%, 60%, 0.1);
    }
  }
  .search {
    z-index: 9999;
    top: calc(66px + 1rem) !important;
    //background-color: white;
    //height: 100vh;
    width: 100%;

    left: 0;
    top: 0;
    height: 60px;

    .search-button {
      display: inline-block;
      width: 60px;
      height: 60px;
      background-color: white;
      box-shadow: none;
      border: 1px solid #2647e8;
      color: darken(#2647e8, 20%);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      cursor: pointer;
      transition: 0.2s all ease;
      &:hover {
        background: #2647e8;
        color: white;
      }
    }
    input {
      height: 100%;
      width: 100%;
      font-size: 20px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #2647e8;

      &::placeholder {
        color: darken(#2647e8, 25%);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .search-content-right {
      display: none;
    }
    .search-content-left {
      width: 100% !important;
    }
  }
}
</style>
