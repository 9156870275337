<template>
  <div class="login-form">
    <transition name="fade" v-if="!hasForgottenPassword" mode="out-in">
      <form v-on:submit.prevent="onLogin()">
        <div class="">
          <b-notification :closable="false" v-if="loginErrorMsg">
            <small>{{ loginErrorMsg }}</small>
          </b-notification>

          <div class="field">
            <p class="control has-icons-left">
              <input
                v-model="user.email"
                class="input"
                type="text"
                placeholder="E-post"
                autocomplete="new-password"
              />
              <span class="icon is-medium is-left"
                ><i class="fa fa-user"></i
              ></span>
            </p>
          </div>
          <div class="field">
            <div class="control has-icons-left">
              <input
                v-model="user.password"
                class="input has-half-top-margin"
                type="password"
                placeholder="Lösenord"
                autocomplete="new-password"
              />
              <span class="icon is-medium is-left"
                ><i class="fa fa-key"></i
              ></span>
            </div>
          </div>

          <button
            type="submit"
            class="button is-fullwidth is-primary has-top-margin has-half-bottom-margin"
            v-bind:class="{ 'is-loading': loginIsLoading }"
            v-on:submit="onLogin"
            :disabled="!(user.email.length > 0 && user.password.length > 0)"
          >
            Logga in
          </button>

          <small class="">
            <a @click="hasForgottenPassword = !hasForgottenPassword"
              >Glömt lösenord</a
            >
            |

            <span @click="onRegister">
              <router-link :to="{ name: 'register' }">
                Registrera dig
              </router-link>
            </span>
          </small>
        </div>
      </form>
    </transition>
    <transition name="fade" mode="out-in" v-else>
      <forgot-password-form @changeView="onChangeView"></forgot-password-form>
    </transition>
  </div>
</template>

<script>
//import ForgotPasswordForm from "./forgotPasswordForm";
import AuthService from "@/services/AuthService";
import ForgotPasswordForm from "@/components/forms/ForgotPasswordForm.vue";
export default {
  name: "LoginForm",
  components: { ForgotPasswordForm },
  props: {},
  data: function () {
    return {
      hasForgottenPassword: false,
      loginIsLoading: false,
      loginErrorMsg: null,
      user: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      //this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    async onLoginAnonymously() {
      /*this.$emit("on-login");
      this.loginErrorMsg = null;
      this.loginIsLoading = true;
      await AuthService.loginAnonymously()
        .then((authUser) => {
          setTimeout(async () => {
            this.$emit("on-success");
            console.log("login anonymus success", authUser);

            if (this.redirectTo) this.$router.push({ name: this.redirectTo });
          }, 100);
        })
        .catch((error) => {
          console.log("GOT ERROR");
          this.$emit("on-failed");
          this.loginIsLoading = false;
          this.loginErrorMsg = error.message;
        });*/
      //const userId = this.$store.state.authUser.uid;
      //const user = await AuthService.getUserBy(userId);
      this.$store.commit("setUser", {});
    },
    onRegister() {
      this.$emit("on-register");
    },
    async onLogin() {
      this.$emit("on-login");
      this.loginErrorMsg = null;
      this.loginIsLoading = true;
      AuthService.login(this.user)
        .then(async (authUser) => {
          this.$store.commit("setAuthUser", authUser);
          const userId = authUser.uid;
          const user = await AuthService.getUserBy(userId);
          this.$store.commit("setUser", user);

          this.loginIsLoading = false;
          this.$emit("on-success");
        })
        .catch((error) => {
          this.$emit("on-failed");
          this.loginIsLoading = false;
          this.loginErrorMsg = error.message;
        });
    },
    onChangeView(value) {
      this.hasForgottenPassword = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: 1rem;
}
.arrow-up {
  position: absolute;
  top: -1rem;
  right: 35px;
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid white;
}
small {
  line-height: 0.1rem;
}

.fade {
  position: absolute;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
