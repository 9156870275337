<template>
  <div class="register-view view">
    <div class="container box is-xsmall">
      <h1 class="is-bold has-text-centered has-bottom-margin">
        Registrera dig
      </h1>
      <p class="has-bottom-margin">
        Har du nyligen handlat från någon av våra andra butiker? <br />
        Då kan du kan fortfarande registera dina poäng! <br />
        Kontakta support för att få dem registrerade.
      </p>

      <div class="columns has-text-left" v-if="isProduction || isDevelopment">
        <div class="column">
          <div class="input-group-space">
            <b-notification :closable="false" v-if="loginErrorMsg">
              <small>{{ loginErrorMsg }}</small>
            </b-notification>
            <form v-on:submit.prevent="createUser(user)">
              <b-field label="Email" :type="getValidationClassOf(user.email)">
                <b-input
                  value="John"
                  type="email"
                  v-model="user.email"
                  placeholder="anna.karlsson@exempel.com"
                ></b-input>
              </b-field>
              <b-field label="Förnamn" :type="validateName(user.firstname)">
                <b-input value="John" v-model="user.firstname"></b-input>
              </b-field>
              <b-field label="Lösenord">
                <b-input
                  type="password"
                  value="''"
                  v-model="password"
                  password-reveal
                >
                </b-input>
              </b-field>
              <b-field label="Lösenord igen">
                <b-input
                  type="password"
                  value="''"
                  v-model="rePassword"
                  password-reveal
                >
                </b-input>
              </b-field>
              <b-field>
                <b-checkbox
                  v-model="check"
                  true-value="Yes"
                  false-value="No"
                  disabled
                  type="is-info"
                >
                  <small
                    >Ja tack! Jag vill gärna ta del av nyhetsbrev med personliga
                    rabatter, erbjudanden och nyheter</small
                  >
                </b-checkbox>
              </b-field>
              <button
                type="submit"
                class="button is-fullwidth is-medium is-primary has-shadow has-top-margin"
                v-bind:disabled="!validateForm(user)"
                v-bind:class="{ 'is-loading': isLoading }"
              >
                Skapa konto
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="columns has-text-left" v-else>
        <div class="column has-text-centered">
          <strong>This is not production. </strong>
          <p>
            You are not allowed to create a new account on this site. Please go
            to: <br />
            <a href="https://mobio.se">wwww.mobio.se</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import AuthService from "@/services/AuthService";

export default {
  name: "register-view",
  components: {},
  data: function () {
    return {
      isProduction: process.env.NODE_ENV === "production",
      isDevelopment: process.env.NODE_ENV === "development",
      isLoading: false,
      loginErrorMsg: null,
      rePassword: "",
      password: "",
      check: "Yes",
      user: {
        firstname: "",
        lastname: "",
        points: 0,
        email: "",
        address: "",
        address2: null,
        city: "",
        mobile: "",
        phone: "",
        zip: "",
        alias: null,
        isNew: true,
      },
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    isEmail(email) {
      var regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    },
    validateName(name) {
      return name.length > 1 && name.length < 30 ? "is-success" : "is-danger";
    },
    validateEmail(email) {
      return email.length > 5 && email.length < 40 && this.isEmail(email);
    },
    getValidationClassOf(email) {
      return this.validateEmail(email) ? "is-success" : "is-danger";
    },
    validateForm(user) {
      return (
        this.validateEmail(user.email) &&
        user.firstname.length > 1 &&
        user.firstname.length < 30 &&
        this.password !== "" &&
        this.rePassword !== "" &&
        this.password === this.rePassword
      );
    },
    async createUser(user) {
      this.isLoading = true;
      if (this.validateForm(user)) {
        await AuthService.createAuthUser(user.email, this.password)
          .then(async (authUser) => {
            await UserService.createUserBy(authUser.user.uid, user)
              .then(async () => {
                await AuthService.requestVerificationMail();
                setTimeout(() => {
                  //this.$router.go("verify");
                  //this.$router.go();
                  this.$router.replace("account");
                  //this.$router.go();
                }, 100);
                //this.$router.push("account");
              })
              .catch(() => {
                this.loginErrorMsg =
                  "Det gick inte att skapa ett konto med" +
                  user.email +
                  " - FELKOD: 1337";
              });
          })
          .catch(() => {
            this.loginErrorMsg =
              "Det gick inte att skapa ett konto med " + user.email;
          });
      } else {
        this.loginErrorMsg = "Lösenordet matchade inte med det andra.";
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form-container {
}
</style>
