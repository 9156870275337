var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-subscriptions-view view"},[_c('div',{staticClass:"container box"},[(!_vm.isLoading)?_c('section',[_c('h2',{staticClass:"is-bold"},[_vm._v("Dina prenumerationer")]),_c('h3',{staticClass:"is-bold is-size-5 has-half-bottom-margin has-top-margin"},[_vm._v(" Aktiva ")]),(
          _vm.subscriptions.filter((sub) => sub.status === 'active').length !== 0
        )?_c('section',_vm._l((_vm.subscriptions.filter(
            (sub) => sub.status === 'active'
          )),function(subscription){return _c('div',{key:subscription.id,staticClass:"card-plan has-shadow-on-hover"},[_c('div',{staticClass:"is-flex has-space-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.getNameOf(subscription.plan.product))+" ")]),_c('div',{staticClass:"tag",class:_vm.getTagClass(subscription)},[_vm._v(" "+_vm._s(_vm.getStatus(subscription))+" ")])])])}),0):_c('section',[_c('p',[_vm._v("Du har inga prenumerationer.")]),_c('RouterLink',{staticClass:"button is-secondary has-half-top-margin",attrs:{"to":{ name: 'home' }}},[_vm._v("Köp en prenumeration")])],1),_c('h3',{staticClass:"is-bold is-size-5 has-half-bottom-margin has-2x-top-margin"},[_vm._v(" Inaktiva ")]),(
          _vm.subscriptions.filter((sub) => {
            return sub.status !== 'active';
          }).length !== 0
        )?_c('section',_vm._l((_vm.subscriptions.filter((sub) => {
            return sub.status !== 'active';
          })),function(subscription){return _c('div',{key:subscription.id,staticClass:"card-plan has-shadow-on-hover is-not-active"},[_c('div',{staticClass:"is-flex has-space-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.getNameOf(subscription.plan.product))+" ")]),_c('div',{staticClass:"tag",class:_vm.getTagClass(subscription)},[_vm._v(" "+_vm._s(_vm.getStatus(subscription))+" ")])])])}),0):_c('section',[_vm._v("Du har inga prenumerationer.")]),_c('RouterLink',{staticClass:"button is-secondary has-2x-top-margin",attrs:{"to":{ name: 'home' }}},[_vm._v("Till startsidan")])],1):_c('section',[_c('h2',{staticClass:"is-bold has-bottom-margin"},[_vm._v("Dina prenumerationer")]),_c('b-skeleton',{attrs:{"width":"100%","height":"40","animated":_vm.animated}}),_c('b-skeleton',{attrs:{"width":"100%","height":"40","animated":_vm.animated}}),_c('b-skeleton',{attrs:{"width":"100%","height":"40","animated":_vm.animated}}),_c('RouterLink',{staticClass:"button is-secondary has-2x-top-margin",attrs:{"to":{ name: 'home' }}},[_vm._v("Till startsidan")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }