<template>
  <div class="admin-sales-view">
    <section v-if="viewIsLoading" class="loading-start">
      <div class="loader" style="width: 3em; height: 3em"></div>
      <p class="has-text-white subtitle has-top-margin">Laddar vy</p>
    </section>
    <div v-else div class="container box is-small">
      <div class="buttons">
        <router-link
          class="button is-primary"
          :to="{ name: 'admin-sales-create' }"
          >Skapa promotion</router-link
        >
      </div>

      <b-table :data="sales" v-if="sales.length">
        <b-table-column field="title" label="Title" v-slot="props">
          <p>{{ props.row.title }}</p>
        </b-table-column>
        <b-table-column field="id" label="ID" centered v-slot="props">
          <button
            class="button is-danger"
            @click="onDeletePromoBy(props.row.id)"
          >
            Ta bort
          </button>
        </b-table-column>
      </b-table>
      <section v-else>Det finns inga promotioner</section>
    </div>
  </div>
</template>

<script>
import SalesService from "@/services/SaleService";

export default {
  name: "AdminSalesView",
  components: {},
  data: function () {
    return {
      sales: [],
      viewIsLoading: true,
      columns: [
        {
          field: "title",
          label: "Title",
        },
      ],
    };
  },
  async created() {
    this.sales = await SalesService.getSales();
    this.viewIsLoading = false;
  },
  methods: {
    onCreate() {},
    async onDeletePromoBy(id) {
      await SalesService.deleteBy(id);
      this.sales = await SalesService.getSales();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-sales-view {
}
</style>
