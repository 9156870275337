<template>
  <div class="user-dropdown">
    <p class="has-no-margin is-size-6 is-bold">Hej</p>
    <div class="flex is-space-between">
      <div>
        <h3 class="has-no-top-margin has-no-bottom-margin">
          {{ $store.state.user.firstname }}
        </h3>
        <p class="has-no-top-margin is-size-7 has-half-bottom-margin">
          @{{ user.name || user.alias || "-" }}
        </p>
      </div>
      <div
        v-if="$store.state.userIsAdmin"
        class="is-size-7 is-bold tag is-info"
      >
        Webmaster
      </div>
    </div>
    <ul>
      <div @click="onClose">
        <router-link :to="{ name: 'account' }">
          <i class="fa fa-user"></i>
          <span>Mitt konto</span>
        </router-link>
      </div>

      <div @click="onClose">
        <router-link :to="{ name: 'order' }">
          <i class="fa fa-box"></i>
          Beställningar
        </router-link>
      </div>

      <div @click="onClose" v-if="false">
        <router-link :to="{ name: 'mySubscriptions' }">
          <i class="fa fa-box"></i>
          Prenumerationer
        </router-link>
      </div>

      <div @click="onClose">
        <router-link :to="{ name: 'membership' }">
          <i class="fa fa-id-card"></i> Medlemskap</router-link
        >
      </div>
      <div @click="onClose" v-if="$store.state.userIsAdmin">
        <router-link :to="{ name: 'admin-sales' }">
          <i class="fa fa-user"></i>
          <span>Admin Promotioner</span>
        </router-link>
      </div>
      <div @click="onClose" v-if="$store.state.userIsAdmin">
        <router-link :to="{ name: 'admin-utilities' }">
          <i class="fa fa-user"></i>
          <span>Verktyg</span>
        </router-link>
      </div>
    </ul>
    <div
      style="display: flex; justify-content: space-between"
      class="has-top-margin"
    >
      <button class="button is-info is-light" @click="onLogout">
        Logga ut
      </button>
      <div>
        <p class="has-no-margin is-size-7 is-bold has-text-right">Dina poäng</p>
        <p class="has-text-right" style="color: #2647e8">
          {{ $store.state.user.points }}p
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//import ForgotPasswordForm from "./forgotPasswordForm";

export default {
  name: "LoginForm",
  components: {},
  props: {
    user: {
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {};
  },
  created() {},
  methods: {
    onClose() {
      this.$emit("on-logout");
    },
    async onLogout() {
      this.$store.dispatch("signOut");
      this.$emit("on-logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown {
  //padding: 1rem;
  ul a {
    background-color: rgb(232, 232, 232);
    padding: 0.25rem 0.75rem;
    justify-content: flex-start;
    display: block;
    margin-bottom: 0.25rem;
    color: rgb(60, 60, 60);
    &:hover {
      background-color: darken(rgb(232, 232, 232), 10%);
    }
    i {
      padding-right: 0.5rem;
      padding-top: 4px;
    }
  }
}
</style>
