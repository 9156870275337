<template>
  <div class="order-view view">
    <div class="container box">
      <div class="columns is-multiline">
        <div class="column is-full">
          <h2 class="is-bold">Order historik</h2>
          <p>Hantera dina senaste beställningar och fakturor</p>
          <div class="separator has-top-margin"></div>
        </div>
        <div class="column is-one-third">
          <p class="is-bold">Order detaljer</p>
          <p>Granska och hantera senaste beställningar</p>
        </div>
        <div class="column is-two-thirds">
          <div class="list">
            <section v-if="isLoading">
              <b-skeleton
                width="100%"
                height="100"
                :animated="animated"
              ></b-skeleton>
              <b-skeleton
                width="100%"
                height="100"
                :animated="animated"
              ></b-skeleton>
              <b-skeleton
                width="100%"
                height="100"
                :animated="animated"
              ></b-skeleton>
            </section>
            <section v-else>
              <div v-if="orders.length === 0">
                <p class="has-text-centered has-top-margin">
                  Inga ordrar att visa
                </p>
              </div>
              <div v-else>
                <div class="list-item" v-for="order in orders" :key="order.id">
                  <div class="header">
                    <div class="has-text-black">Order #{{ order.selloId }}</div>
                    <div>
                      <div
                        class="tag is-white has-right-margin"
                        v-if="order.isActive"
                      >
                        Behandlas
                      </div>

                      <div
                        class="tag is-white has-right-margin"
                        v-else-if="order.isDeleted"
                      >
                        Annullerad
                      </div>
                      <div
                        class="tag is-white has-right-margin"
                        v-else-if="order.isDelivered"
                      >
                        Skickad
                      </div>
                      <div
                        class="tag is-white has-right-margin"
                        v-else-if="order.isPaid"
                      >
                        Bekräftad - Packas
                      </div>
                      <div class="tag is-success">Betald</div>
                    </div>
                  </div>
                  <div class="content">
                    <ul>
                      <li
                        v-for="product of order.products"
                        :key="product.objectID"
                      >
                        {{ product.quantity }}st {{ product.name }}
                      </li>
                    </ul>
                  </div>
                  <button
                    class="button is-small"
                    :class="{ 'is-loading': btnIsLoading }"
                    @click="onReceipt(order)"
                  >
                    Skriv ut kvitto
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <RouterLink :to="{ name: 'home' }" class="button is-text has-top-margin"
        >Till startsidan</RouterLink
      >
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import SelloService from "@/services/selloService";

export default {
  name: "OrderView",
  components: {},
  data: function () {
    return {
      orders: [],
      isLoading: true,
      btnIsLoading: false,
    };
  },
  async created() {
    const data = await UserService.getMyOrders();
    this.orders = data.data;
    this.isLoading = false;
  },
  methods: {
    async onReceipt(order) {
      if (order.receiptUrl) {
        window.open(order.receiptUrl, "_blank").focus();
      } else {
        this.btnIsLoading = true;
        const receipt = await SelloService.getReceiptBy(order.id);
        window.open(receipt.data.url, "_blank");
        this.btnIsLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";
.order-view {
  .list {
    .list-item {
      padding: 1rem;
      margin-bottom: 1rem;
      background: $grey-light;
      border-radius: $radius-large;
      .header {
        display: flex;
        justify-content: space-between;
      }
      .content {
        padding: 0.5rem 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>
