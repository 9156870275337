<template>
  <div class="cart-pay-method-selector">
    <label class="label is-size-6 has-text-centered">Hur vill du betala?</label>
    <div class="columns">
      <div
        class="column has-text-centered"
        @click="onSelectMethod('SWISH')"
        :class="{ 'is-active': selectedMethod === 'SWISH' }"
      >
        Swish
      </div>
      <div
        class="column has-text-centered"
        @click="onSelectMethod('STRIPE')"
        :class="{ 'is-active': selectedMethod === 'STRIPE' }"
      >
        Kort
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartPayMethodSelector",
  components: {},
  data() {
    return {
      selectedMethod: "STRIPE",
    };
  },
  props: {},
  async created() {},
  methods: {
    onSelectMethod(method) {
      this.selectedMethod = method;
      this.$emit("on-selected-method", method);
    },
  },
};
</script>

<style scoped lang="scss">
.cart-pay-method-selector {
  margin-bottom: 1.5rem;
  .columns {
    margin: 0 0.25rem;
  }
  .column {
    border-bottom: 3px solid rgb(200, 200, 200);
    cursor: pointer;
    &:hover {
      border-bottom-color: rgb(82, 82, 82);
      color: black;
    }
  }
  .column.is-active {
    border-bottom-color: rgb(82, 82, 82);
    color: black;
  }
}
</style>
