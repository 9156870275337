<template>
  <div class="product-availability">
    <div class="header">
      <small>
        <strong>Lagerstatus</strong>
      </small>
    </div>
    <section class="columns is-multiline">
      <div class="column is-full has-no-bottom-padding">
        <div class="flex is-space-between">
          <p class="subtitle has-no-margin">
            <small> Webblager</small>
          </p>
          <div class="amount has-text-right">
            <small v-if="selectedProduct.available === 0">Ej i lager</small>
            <small v-else>{{ getAmountFromWebblager() }}</small>
          </div>
        </div>
      </div>
      <div class="column is-full has-no-top-padding">
        <p class="subtitle has-no-margin">
          <small>
            <router-link to="/about" class="link" style="color: blue">
              Brukstorget, Varberg
            </router-link>
          </small>
        </p>
        <div class="amount has-text-right">
          <small v-if="selectedProduct.available === 0">Slut</small>
          <small v-else>{{ getAmountFromBruket() }}</small>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ProductAvailability",
  components: {},
  props: {
    selectedProduct: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    getAmountFromWebblager() {
      let amount =
        this.selectedProduct.available -
        (this.selectedProduct.store ? this.selectedProduct.store : 0);
      if (amount < 0) {
        amount = 0;
      }
      return amount + " st";
    },
    getAmountFromBruket() {
      let amountBruket = this.selectedProduct.store
        ? this.selectedProduct.store
        : 0;

      if (amountBruket > this.selectedProduct.available) {
        amountBruket = this.selectedProduct.available;
      }
      return amountBruket === 0 ? "Beställ" : `${amountBruket} st`;
      //selectedProduct.other.hasOwnProperty('bruket_amount') ? selectedProduct.other.bruket_amount + ' st': 'Beställ'
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/variables";
.product-availability {
  border: 1px solid $grey-lighter;
  border-radius: $radius;

  .columns {
    padding: 0.5rem;
  }

  .header {
    background: $grey-lighter;
    padding-left: 0.5rem;
  }

  .column {
    .subtitle {
      width: 80%;
      font-size: 16px;
      display: inline-block;
    }
    .amount {
      width: 20%;
      display: inline-block;
    }
  }
}
</style>
