<template>
  <div class="placeholder">&nbsp;</div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  data: function () {
    return {};
  },
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    borderRadius: {
      type: String,
      default: "8px",
    },
    margin: {
      type: String,
      default: "none",
    },
  },
};
</script>

<style scoped>
@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}
.placeholder {
  height: v-bind(height);
  width: v-bind(width);
  border-radius: v-bind(borderRadius);
  background-image: linear-gradient(
    to right,
    #eaebeb 0%,
    #f3f4f4 30%,
    #ebecec 40%,
    #eaebeb 100%
  );
  background-size: 200% 100%;
  margin: v-bind(margin);
  animation: bgAnimate 1.2s linear infinite;
}
</style>
