import { database, functions } from "@/main";
import { httpsCallable } from "firebase/functions";
import { doc, setDoc, updateDoc } from "firebase/firestore";

var COLLECTION = "users";

const UserService = {
  createUserBy(authUserId, user) {
    return setDoc(doc(database, COLLECTION, authUserId), user);
  },

  updateUser(user) {
    return updateDoc(doc(database, COLLECTION, user.id), user);
  },
  updateUsersAttributes(attributes) {
    const updateUsersAttributes = httpsCallable(
      functions,
      "updateUsersAttributes"
    );
    return updateUsersAttributes({ attributes: attributes });
  },

  async getMyOrders() {
    const getUserOrders = httpsCallable(functions, "getMyOrders");
    return getUserOrders();
  },
};

export default UserService;
