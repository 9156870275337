<template>
  <div class="edit-product-form">
    <p>{{ product.id }}</p>
    <div class="flex is-space-between">
      <p>Är produkten förbokningsbar?</p>
      <b-field>
        <b-switch v-model="isBookable" type="is-info"></b-switch>
      </b-field>
    </div>
    <section v-if="isBookable">
      <b-field label="Välj datum för när varorna anländer">
        <div>
          <b-datepicker
            placeholder="Förbokningsdatum..."
            v-model="preOrderDate"
          >
          </b-datepicker>
        </div>
      </b-field>
    </section>
    <button
      @click="onUpdate"
      class="button is-primary is-fullwidth has-half-top-margin"
    >
      Uppdatera
    </button>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
export default {
  name: "EditProductForm",
  props: {
    product: {
      default: null,
    },
  },

  data() {
    return {
      isBookable: false,
      preOrderDate: null,
      preOrderNote: "",
    };
  },
  created() {
    this.isBookable = this.product.isBookable;
    if (this.product.booking) {
      //this.preOrderDate = this.product.booking.date;
      this.preOrderNote = this.product.booking.note;
    }
  },
  methods: {
    async onUpdate() {
      const newProduct = JSON.parse(JSON.stringify(this.product));
      if (this.isBookable === undefined) this.isBookable = false;

      newProduct["isBookable"] = this.isBookable;

      const booking = {
        date: this.preOrderDate,
        note: this.preOrderNote,
      };
      delete newProduct["_highlightResult"];
      delete newProduct["product"];
      delete newProduct["path"];
      delete newProduct["lastmodified"];
      newProduct["booking"] = booking;

      ProductService.updateProduct(newProduct).then(() => {
        this.$buefy.snackbar.open(`Produkten har uppdaterats!`);
      });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";
.edit-product-form {
  border: 3px solid $grey;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}
</style>
