<template>
  <div class="admin-create-sale-view">
    <div div class="container box">
      <h2>Skapa</h2>

      <div class="columns">
        <div class="column">
          <form v-on:submit.once.prevent="onCreateSale(promotion)">
            <b-field label="Titel">
              <b-input v-model="promotion.title"></b-input>
            </b-field>
            <b-field label="Liten banner (synt intill produkten)">
              <b-input v-model="promotion.banner"></b-input>
            </b-field>
            <b-field label="Rabatt typ">
              <b-select
                placeholder="Välj en rabatt typ"
                icon="account"
                v-model="promotion.type"
              >
                <optgroup label="Fungerande">
                  <option value="SALE_AMOUNT_PRODUCTS">
                    Rabatt i antalet produkter
                  </option>
                </optgroup>
                <optgroup label="Ej fungerande">
                  <option value="SALE_TAKE_X_FOR_Y">
                    Rabatt i ta x betala för y
                  </option>
                  <option value="SALE_PERCENTAGE">Rabatt i procent</option>
                  <option value="SALE_CROWNS">Rabatt i kronor</option>
                </optgroup>
              </b-select>
            </b-field>

            <b-field label="Beskrivning">
              <b-input v-model="promotion.description"></b-input>
            </b-field>
            <b-field label="Villkor (Optional)">
              <b-input v-model="promotion.policy"></b-input>
            </b-field>

            <b-field label="Ta x och betala y styck">
              <b-field label="Antal">
                <b-numberinput
                  :editable="true"
                  :step="1"
                  type="is-light"
                  v-model="method.amount"
                  :min="1"
                  :max="100"
                ></b-numberinput>
              </b-field>
              <b-field label="Pris styckvis">
                <b-numberinput
                  :editable="true"
                  :step="1"
                  type="is-light"
                  v-model="method.price"
                  :min="10"
                  :max="10000"
                ></b-numberinput>
              </b-field>
            </b-field>
            <button
              class="button is-small is-primary"
              @click.prevent="onAddMethod"
            >
              Lägg till
            </button>

            <b-notification
              v-if="promotion.method.length"
              class="has-top-margin"
              :closable="false"
              aria-close-label="Close notification"
            >
              <ul>
                <li
                  v-for="method in (index, promotion.method)"
                  :key="method.amount"
                >
                  <button
                    class="button is-small is-danger"
                    @click="onRemoveMethod(index)"
                  >
                    ta bort</button
                  >Ta {{ method.amount }} betala {{ method.price }} kr styck.
                </li>
              </ul>
            </b-notification>

            <b-field label="Minsta köp">
              <b-numberinput
                :editable="true"
                :step="1"
                type="is-light"
                v-model="promotion.minPurchase"
                :max="5000"
              ></b-numberinput>
            </b-field>

            <hr class="voucher-hr" />

            <b-field label="Start datum">
              <b-datepicker
                v-model="promotion.startDate"
                placeholder="Klicka för att välja..."
                icon="far fa-calendar-alt"
                :min-date="minDate"
                editable
                required
              >
              </b-datepicker>
            </b-field>

            <b-field label="Slut datum">
              <b-datepicker
                v-model="promotion.endDate"
                placeholder="Klicka för att välja..."
                icon="far fa-calendar-alt"
                :min-date="minDate"
                editable
              >
              </b-datepicker>
            </b-field>

            <button
              type="submit"
              class="button is-fullwidth is-primary"
              :disabled="promotion.products.length === 0 && !promotion.title"
            >
              Skapa
            </button>
          </form>
        </div>
        <div class="column">
          <b-tabs v-model="activeTab">
            <b-tab-item label="Välj produkter">
              <label class="label">Välj produkter</label>
              <div class="field has-addons">
                <p class="control is-expanded">
                  <b-field>
                    <b-input
                      placeholder="Sök efter produkt"
                      field="title"
                      :loading="isFetching"
                      @typing="getAsyncData"
                      v-on:input="getAsyncData"
                      icon="search"
                    >
                    </b-input>
                  </b-field>
                </p>
              </div>
              Resultat: {{ products.length }}

              <b-table
                v-if="products.length"
                class="is-narrow is-size-7"
                :data="products"
                @page-change="onPageChange"
                paginated
                hoverable
                @click="onToggleProduct"
                v-model:checked-rows.sync="checkedRows"
                backend-pagination
                :total="nbHits"
                :per-page="hitsPerPage"
                v-model:current-page="currentPage"
              >
                <b-table-column field="image" label="Bild" v-slot="props">
                  <img :src="props.row.image" width="50" />
                </b-table-column>
                <b-table-column field="name" label="Name" v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="id" label="Vald" v-slot="props">
                  {{ isObjectSelected(props.row) ? "x" : "" }}
                </b-table-column>
              </b-table>

              <button
                v-if="products.length"
                class="button is-primary is-small"
                @click="selectAllFromResult"
              >
                Välj alla från listan
              </button>

              <div
                v-for="promotionalProduct in promotionalProducts"
                :key="promotionalProduct.id"
              >
                <article
                  class="media promotion-list"
                  :class="{ 'is-active': isProductActive(promotionalProduct) }"
                  @click="onToggleProduct(promotionalProduct)"
                >
                  <figure class="media-left">
                    <p class="image is-32x32">
                      <img :src="promotionalProduct.images[0].url_small" />
                    </p>
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <small>{{ promotionalProduct.lang.SE.title }}</small>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </b-tab-item>

            <b-tab-item label="Valda">
              <b-table
                v-if="promotion.products"
                class="is-narrow is-size-7"
                :data="promotion.products"
                @page-change="onPageChange"
                paginated
                hoverable
                @click="onToggleProduct"
                backend-pagination
                :total="promotion.products.length"
                :per-page="1000"
                v-model:current-page="currentPage"
              >
                <b-table-column field="image" label="Bild" v-slot="props">
                  <img :src="props.row.image" width="50" />
                </b-table-column>
                <b-table-column field="name" label="Name" v-slot="props">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="id" label="Vald" v-slot="props">
                  {{ isObjectSelected(props.row) ? "x" : "" }}
                </b-table-column>
              </b-table>
              <pre>{{ promotion }}</pre>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
import SalesService from "@/services/SaleService";

import debounce from "lodash/debounce";

export default {
  name: "AdminCreateSaleView",
  components: {},
  data: function () {
    const today = new Date();
    return {
      search: "",
      isFetching: false,
      activeTab: 0,
      sales: [],
      viewIsLoading: true,
      columns: [
        {
          field: "title",
          label: "Title",
        },
      ],
      method: {
        amount: 2,
        price: 199,
      },

      promotionalProducts: [],
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),

      nbHits: 0,
      nbPages: 0,
      currentPage: 0,
      hitsPerPage: 0,
      products: [],
      checkedRows: [],
      promotion: {
        banner: "",
        policy: "",
        type: "",
        title: "",
        description: "",
        method: [],
        minPurchase: 100,
        startDate: new Date(),
        endDate: new Date(),
        products: [],
      },
      selectedProducts: [],
    };
  },
  async created() {
    this.sales = await SalesService.getSales();
    this.viewIsLoading = false;
  },
  methods: {
    onRemoveMethod(index) {
      this.promotion.method.splice(index, 1);
    },
    onAddMethod() {
      this.promotion.method.push({
        price: this.method.price,
        amount: this.method.amount,
      });
      this.method.price = 199;
      this.method.amount = 2;
    },
    async onPageChange(page) {
      this.isPageLoading = true;
      this.isFetching = true;
      let productData = await ProductService.searchActivatedProductsBy(
        this.search,
        page - 1
      );

      this.products = productData.hits;

      this.nbHits = productData.nbHits;
      this.nbPages = productData.nbPages;
      this.currentPage = productData.page;
      this.hitsPerPage = productData.hitsPerPage;
      this.isFetching = false;

      this.isPageLoading = false;
    },
    isObjectSelected(object) {
      let index = this.promotion.products.findIndex((product) => {
        return product.id === object.id;
      });
      return index !== -1;
    },
    getAsyncData: debounce(async function (name) {
      this.search = name;
      this.isPageLoading = true;
      if (!name.length) {
        this.products = [];
        return;
      }
      this.isFetching = true;
      let productData = await ProductService.searchActivatedProductsBy(name);

      this.products = productData.hits;

      this.nbHits = productData.nbHits;
      this.nbPages = productData.nbPages;
      this.currentPage = productData.page;
      this.hitsPerPage = productData.hitsPerPage;
      this.isFetching = false;
      this.isPageLoading = false;
    }, 500),
    onCreate() {},

    selectAllFromResult() {
      this.products.forEach((product) => this.onToggleProduct(product));
    },

    onToggleProduct(product) {
      const index = this.promotion.products.findIndex(
        (promoProduct) => promoProduct.id === product.id
      );

      if (index === -1) {
        this.promotion.products.push({
          ...product,
        });
        this.checkedRows.push(
          this.promotion.products[this.promotion.products.length - 1]
        );
      } else {
        this.promotion.products.splice(index, 1);
      }
    },
    onAddProducts() {
      const arr = JSON.stringify(JSON.parse(this.promotion.products));

      this.checkedRows.forEach((checked) => {
        const index = this.promotion.products.findIndex(
          (prod) => prod.id === checked.id
        );

        if (index === -1) {
          arr.push(checked);
        } else arr.splice(index, 1);
      });
      this.promotion.products = arr;
      this.checkedRows = [];
    },
    async onDeletePromoBy(id) {
      await SalesService.deleteBy(id);
      this.sales = await SalesService.getSales();
    },
    async onCreateSale() {
      this.viewIsLoading = true;
      await SalesService.createSale({
        ...this.promotion,
        products: this.promotion.products.map((product) => product.id),
      });
      this.viewIsLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.admin-sales-view {
}
</style>
