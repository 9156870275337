var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-search-result-view"},[_c('div',{staticClass:"container box has-no-border has-no-padding"},[_c('PolicyBanner')],1),_c('div',{staticClass:"container has-no-padding box"},[_c('section',{staticClass:"flex is-space-between results"},[_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[(
              [
                'pokémon',
                'nintendo',
                'barbie',
                'lego',
                'funko',
                'pikachu',
              ].includes(_vm.theme)
            )?_c('p',{staticClass:"image is-64x64"},[(_vm.theme === 'pokémon')?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/pikachu.svg")}}):(_vm.theme === 'nintendo')?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/zelda.svg")}}):(_vm.theme === 'barbie')?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/barbie.svg")}}):(_vm.theme === 'lego')?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/lego.svg")}}):(_vm.theme === 'funko')?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/funko.svg")}}):(_vm.theme === 'pikachu')?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/pikachu.svg")}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"media-content is-vertical-middle"},[_c('h2',{staticClass:"is-uppercase is-bold",staticStyle:{"line-height":"1.2"}},[_vm._v(" "+_vm._s(_vm.theme)+" ")]),(
              [
                'pokémon',
                'nintendo',
                'barbie',
                'lego',
                'funko',
                'pikachu',
              ].includes(_vm.theme)
            )?_c('p',{staticClass:"is-size-7"},[_vm._v(" Visar "+_vm._s(_vm.products.length)+" av "+_vm._s(_vm.nbHits)+" resultat ")]):_c('p',[_vm._v("Visar "+_vm._s(_vm.products.length)+" av "+_vm._s(_vm.nbHits)+" resultat")])])]),_c('section',{staticClass:"controllers"},[_c('div',{staticClass:"field is-grouped is-grouped-centered"},[_c('div',{},[_c('b-checkbox',{staticClass:"is-vertical-middle has-right-margin",attrs:{"type":"is-info","native-value":"Visa tillgängliga"},on:{"input":_vm.onSelectFilter},model:{value:(_vm.showOnlyAvailable),callback:function ($$v) {_vm.showOnlyAvailable=$$v},expression:"showOnlyAvailable"}},[_vm._v(" Visa endast i lager ")]),_c('b-checkbox',{staticClass:"is-vertical-middle",attrs:{"type":"is-info","native-value":"Visa tillgängliga"},on:{"input":_vm.onSelectFilter},model:{value:(_vm.showOnlyPreRelease),callback:function ($$v) {_vm.showOnlyPreRelease=$$v},expression:"showOnlyPreRelease"}},[_vm._v(" Visa endast förbokningar ")])],1),_c('div',{staticClass:"field has-addons has-left-margin"},[_c('p',{staticClass:"control",on:{"mouseenter":function($event){_vm.isHoveringList = 'LIST-WINDOW'},"mouseleave":function($event){_vm.isHoveringList = null}}},[_c('button',{staticClass:"button product-view",class:{
                  'is-dark is-selected': _vm.selectedView === 'LIST-WINDOW',
                },on:{"click":function($event){_vm.selectedView = 'LIST-WINDOW'}}},[_c('span',{staticClass:"image is-16x16"},[(
                      (_vm.selectedView !== 'LIST-WINDOW' && _vm.isHoveringList) ||
                      _vm.selectedView === 'LIST-WINDOW'
                    )?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list-window-white.svg")}}):_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list-window.svg")}})])])]),_c('p',{staticClass:"control",on:{"mouseenter":function($event){_vm.isHoveringList = 'LIST'},"mouseleave":function($event){_vm.isHoveringList = null}}},[_c('button',{staticClass:"button product-view",class:{
                  'is-dark is-selected': _vm.selectedView === 'LIST',
                },on:{"click":function($event){_vm.selectedView = 'LIST'}}},[_c('span',{staticClass:"image is-16x16"},[(
                      (_vm.selectedView !== 'LIST' && _vm.isHoveringList) ||
                      _vm.selectedView === 'LIST'
                    )?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list-white.svg")}}):_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list.svg")}})])])])]),_c('b-field',{staticClass:"form-element"},[_c('b-select',{attrs:{"placeholder":"Sortera","expanded":""},on:{"input":_vm.onSelectSortMethod}},_vm._l((_vm.sort),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)],1)])]),_c('section',{staticClass:"mobile-controllers"},[_c('div',{staticClass:"field is-grouped is-grouped-centered"},[_c('div',{staticClass:"field has-addons has-left-margin"},[_c('p',{staticClass:"control",on:{"mouseenter":function($event){_vm.isHoveringList = 'LIST-WINDOW'},"mouseleave":function($event){_vm.isHoveringList = null}}},[_c('button',{staticClass:"button product-view",class:{
                'is-dark is-selected': _vm.selectedView === 'LIST-WINDOW',
              },on:{"click":function($event){_vm.selectedView = 'LIST-WINDOW'}}},[_c('span',{staticClass:"image is-16x16"},[(
                    (_vm.selectedView !== 'LIST-WINDOW' && _vm.isHoveringList) ||
                    _vm.selectedView === 'LIST-WINDOW'
                  )?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list-window-white.svg")}}):_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list-window.svg")}})])])]),_c('p',{staticClass:"control",on:{"mouseenter":function($event){_vm.isHoveringList = 'LIST'},"mouseleave":function($event){_vm.isHoveringList = null}}},[_c('button',{staticClass:"button product-view",class:{
                'is-dark is-selected': _vm.selectedView === 'LIST',
              },on:{"click":function($event){_vm.selectedView = 'LIST'}}},[_c('span',{staticClass:"image is-16x16"},[(
                    (_vm.selectedView !== 'LIST' && _vm.isHoveringList) ||
                    _vm.selectedView === 'LIST'
                  )?_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list-white.svg")}}):_c('img',{attrs:{"src":require("@/assets/images/svg/menu/list.svg")}})])])])]),_c('b-field',{staticClass:"form-element"},[_c('b-select',{attrs:{"placeholder":"Sortera","expanded":""},on:{"input":_vm.onSelectSortMethod}},_vm._l((_vm.sort),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)],1)]),_c('div',{},[(_vm.viewIsLoading)?_c('section',{},[_c('div',{staticClass:"columns is-multiline"},_vm._l(([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]),function(col){return _c('div',{key:col,staticClass:"column is-one-fifth"},[_c('div',{staticClass:"has-padding"},[_c('b-skeleton',{attrs:{"width":"100%","height":"200","animated":true}}),_c('b-skeleton',{attrs:{"width":"70%","height":"30","animated":true}})],1)])}),0)]):_c('div',[(_vm.products.length === 0)?_c('div',[_c('p',{staticClass:"has-text-centered has-2x-top-padding has-2x-bottom-padding"},[_vm._v(" Det finns inga produkter. ")])]):_c('ProductList',{attrs:{"view":_vm.selectedView,"products":_vm.products,"isLoadmoreButtonDisabled":_vm.isLoadmoreProductsDisabled(),"is-loadmore-loading":_vm.isLoadmoreProductsLoading},on:{"load-more":_vm.loadMoreProducts}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }