<template>
  <div class="admin-utilities-view">
    <section v-if="viewIsLoading" class="loading-start">
      <div class="loader" style="width: 3em; height: 3em"></div>
      <p class="has-text-white subtitle has-top-margin">Laddar</p>
    </section>
    <div v-else div class="container box is-small">
      <button
        @click="balanceProducts"
        class="button is-danger is-fullwidth has-bottom-margin"
      >
        Balance products quantity
      </button>
      <button
        @click="deleteStripe"
        class="button is-danger is-fullwidth has-bottom-margin"
      >
        Delete all stripe coupons
      </button>
      <br />
      <button
        @click="updateProductsIds"
        class="button is-danger is-fullwidth has-bottom-margin"
      >
        Update stripe products ids
      </button>
    </div>
  </div>
</template>

<script>
import PromotionService from "@/services/PromotionService";
import ProductService from "@/services/ProductService";

export default {
  name: "AdminUtilitiesView",
  components: {},
  data: function () {
    return {
      viewIsLoading: true,
    };
  },
  async created() {
    this.viewIsLoading = false;
  },
  methods: {
    async deleteStripe() {
      this.viewIsLoading = true;
      await PromotionService.deleteAllStripePromotionCodes();
      this.viewIsLoading = false;
    },
    async updateProductsIds() {
      this.viewIsLoading = true;
      await ProductService.updateProductsIds();
      this.viewIsLoading = false;
    },
    async balanceProducts() {
      this.viewIsLoading = true;
      await ProductService.balanceProducts();
      this.viewIsLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.admin-utilities-view {
}
</style>
