<template>
  <div class="products-suggestions-by-product">
    <hr class="separator" />
    <div class="products-suggestion-by-product">
      <div v-if="viewIsLoading">
        <div class="columns">
          <div class="column" v-for="(_, index) of [1, 2, 3]" :key="index">
            <SkeletonLoader
              height="150px"
              class="has-bottom-margin has-top-margin"
            ></SkeletonLoader>
          </div>
        </div>
      </div>
      <ProductList
        v-else
        :products="products"
        :isMobileScrollable="true"
        :isLoadmoreButtonDisabled="false"
        :is-loadmore-loading="false"
        :showMoreButton="false"
        :columnsClass="'is-half is-half-mobile is-one-third-tablet is-one-third-widescreen'"
      />
    </div>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList";
import ProductService from "@/services/ProductService";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
export default {
  name: "ProductsSuggestionByProduct",
  components: { ProductList, SkeletonLoader },
  props: {
    product: {
      default: null,
    },
  },

  data() {
    return {
      viewIsLoading: true,
      products: [],
    };
  },
  async created() {
    const brand = this.product.brand;
    const results = await ProductService.searchActivatedProductsBy(
      brand,
      0,
      "",
      " AND available > 0",
      3
    );

    this.products = results.hits;
    this.viewIsLoading = false;
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.products-suggestions-by-product {
}
</style>
