import Vue from "vue";
import Vuex from "vuex";
import AuthService from "@/services/AuthService";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    freezeBodyElement: true,
    authUser: null,
    userIsAdmin: false,
    user: null,
    currentPlayingGenres: [],
    cart: [],
    cartIsHighlighted: false,
    authenticating: true,

    sales: null,
  },
  getters: {
    getSales: (state) => {
      return state.sales;
    },
    getCartIsHighlighted: (state) => {
      return state.cartIsHighlighted;
    },
    getCart: (state) => {
      return state.cart;
    },
    getAuthUser: (state) => {
      return state.authUser;
    },
    getUser: (state) => {
      return state.user;
    },
    isLoggedIn: (state) => {
      return state.authUser !== null;
    },
    isAdmin: (state) => {
      return state.userIsAdmin;
    },
    fullname: (state) => {
      return state.user.firstname + " " + state.user.lastname;
    },
    isAuthenticating: (state) => {
      return state.authenticating;
    },
  },
  actions: {
    signOut(state) {
      AuthService.logout().then(() => {
        state.authUser = null;
        state.userIsAdmin = false;
        state.user = null;
        window.location.reload();
      });
    },
  },
  mutations: {
    setSales(state, sales) {
      state.sales = sales;
    },
    setProductToCart(state, productWithQuantity) {
      let cart = state.cart;
      const productIsInCartIndex = cart.findIndex(
        (item) => item.objectID === productWithQuantity.product.objectID
      );

      cart[productIsInCartIndex] = {
        ...state.cart[productIsInCartIndex],
        quantity: productWithQuantity.quantity,
      };

      state.cart = JSON.parse(JSON.stringify(cart));
    },
    addProductToCart(state, productWithQuantity) {
      const cart = state.cart;
      const productIsInCartIndex = cart.findIndex(
        (item) => item.objectID === productWithQuantity.product.objectID
      );

      if (productIsInCartIndex === -1) {
        state.cart.push({
          ...productWithQuantity.product,
          quantity: productWithQuantity.quantity,
        });
      } else if (
        cart[productIsInCartIndex].available - cart[productIsInCartIndex].sold >
        cart[productIsInCartIndex].quantity
      ) {
        cart[productIsInCartIndex] = {
          ...state.cart[productIsInCartIndex],
          quantity:
            state.cart[productIsInCartIndex].quantity +
            productWithQuantity.quantity,
        };

        state.cart = JSON.parse(JSON.stringify(cart));
      }
    },
    removeQuantityFromProduct(state, product) {
      const cart = state.cart;

      if (product.quantity === 0) {
        const productIsInCartIndex = cart.findIndex(
          (item) => item.objectID === product.objectID
        );
        cart.splice(productIsInCartIndex, 1);

        state.cart = JSON.parse(JSON.stringify(cart));
      }
    },
    setCartIsHighlighted(state, highlight) {
      state.cartIsHighlighted = highlight;
    },
    setFreezeBodyElement(state, freezeBodyElement) {
      state.freezeBodyElement = freezeBodyElement;
    },
    setAuthUser(state, user) {
      state.authUser = user;
    },
    setUserIsAdmin(state, isAdmin) {
      state.userIsAdmin = isAdmin;
    },
    setUser(state, user) {
      state.user = user;
    },
    setCurrentPlayingGenres(state, genres) {
      state.currentPlayingGenres = genres;
    },
    setAuthenticating(state, isAuthenticatin) {
      state.authenticating = isAuthenticatin;
    },
  },
});
