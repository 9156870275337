<template>
  <div class="small-campaign-block">
    <router-link
      :to="{ name: 'productSearchResult', query: { theme: 'pikachu' } }"
    >
      <div class="container flex">
        <img src="./../../assets/images/png/pikachu.png" class="left-image" />
        <h1 class="has-text-white has-right-margin">
          Pika, pika, pika, chu... Pikachu!
        </h1>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SmallCampaignBlock",
  components: {},
  data() {
    return {};
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.small-campaign-block {
  height: 100px;
  h1 {
    line-height: 2 !important;
  }
  .flex {
    display: flex;
  }
  //background: #212529;
  background: #fff6de;
  background: #292d3e;
  //border-radius: 8px;
  color: white;
  //padding: 1rem 1.5rem;
  cursor: pointer;
  .left-image {
    position: relative;
    //left: 0;
    //top: -1rem;
    //height: 160px;
    bottom: 20px;
    height: 120px;
  }
  h1 {
    color: white;
    font-weight: bold;
    font-size: 40px !important;
    line-height: 1;
    margin-bottom: 1rem;
    margin-top: 0.75rem;
  }
  &:hover .subtitle {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    height: 80px;
    h1 {
      font-size: 30px !important;
    }
    .left-image {
      height: 100px;
    }
  }
}
</style>
