<template>
  <div class="product-view view">
    <div class="container box has-no-border" v-if="product">
      <ShopProduct></ShopProduct>
    </div>
  </div>
</template>

<script>
import ProductService from "@/services/ProductService";
import ShopProduct from "@/components/ShopProduct.vue";
export default {
  name: "ProductView",
  components: { ShopProduct },

  data() {
    return {
      quantityHasChanged: null,
      product: null,
      productIsLoading: false,
    };
  },
  async created() {
    this.productIsLoading = true;
    const id = this.$route.params.id;
    ProductService.searchActivatedProductBy(id)
      .then((data) => {
        this.product = data;
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => (this.productIsLoading = false));
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.product-view {
}
</style>
