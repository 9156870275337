<template>
  <div class="shop-product has-text-left">
    <div class="container" v-if="isLoading">
      <div class="columns">
        <div
          class="column is-full-mobile card is-horizontal-middle has-2x-padding"
        >
          <div class="columns is-5 is-variable mobile-loader">
            <div class="column is-two-thirds">
              <SkeletonLoader
                :height="'300px'"
                :width="24"
                :margin="'0 0 1.5rem 0'"
              />
              <div class="columns">
                <div class="column">
                  <SkeletonLoader :height="'200px'" :width="24" />
                </div>
                <div class="column">
                  <SkeletonLoader :height="'200px'" :width="24" />
                </div>
                <div class="column">
                  <SkeletonLoader :height="'200px'" :width="24" />
                </div>
              </div>
              <SkeletonLoader :height="'100px'" :width="24" />
            </div>
            <div class="column is-one-third">
              <SkeletonLoader :height="'80px'" :width="24" />
              <SkeletonLoader
                :height="'40px'"
                :width="'140px'"
                :margin="'1.5rem 0'"
              />
              <SkeletonLoader
                :height="'40px'"
                :width="24"
                :border-radius="'24px'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      <div class="columns">
        <div
          class="column is-full-mobile card is-horizontal-middle has-2x-padding"
        >
          <div class="columns">
            <div class="column is-full is-size-7">
              <router-link :to="{ name: 'home' }"> Start </router-link>
              <span>&nbsp;/&nbsp;</span>
              <span>{{ product.brand }}</span>
              <span>&nbsp;/&nbsp;</span>
              <span>{{ product.name }}</span>
            </div>
          </div>
          <div class="columns is-5 is-variable" v-if="product.image">
            <div class="column is-two-thirds">
              <i class="far fa-heart fa-2x save-for-later"></i>

              <section
                class="imgs-desktop"
                style="height: 300px; overflow: scroll; position: absolute"
              >
                <img
                  class="imgs has-quarter-padding is-block has-half-bottom-margin"
                  :src="image.url_small"
                  v-for="(image, index) in product.images"
                  width="80"
                  height="80"
                  :style="{ top: `${32 + index * 110}px` }"
                  :key="image.id"
                />
              </section>
              <section
                class="imgs-mobile"
                style="
                  height: 100px;
                  width: calc(100% - 3rem);
                  overflow: scroll;
                  position: absolute;
                "
              >
                <img
                  class="imgs has-quarter-padding has-half-bottom-margin"
                  :src="image.url_small"
                  v-for="(image, index) in product.images"
                  width="80"
                  height="80"
                  :style="{ left: `${32 + index * 110}px`, bottom: 0 }"
                  :key="image.id"
                />
              </section>

              <img :src="product.image" class="img" />

              <div
                class="column is-one-third has-left-separator is-relative mobile-price"
              >
                <section class="purchase-content">
                  <h2
                    class="is-size-4 is-bold product-name"
                    style="line-height: 1.8rem"
                  >
                    {{ product.name }}
                  </h2>

                  <div class="has-bottom-margin has-top-margin">
                    <h3
                      class="is-size-3 is-bold has-quarter-bottom-margin"
                      style="line-height: 1.1"
                    >
                      {{ product.price }} KR
                    </h3>
                    <small
                      v-if="product.booking && product.available > 0"
                      style="
                        color: rgb(0, 133, 55);
                        font-weight: 600;
                        display: block;
                      "
                      >Bokningsbar | Beräknad leverans är
                      {{
                        moment(new Date(product.booking.date)).format(
                          "YYYY.MM.DD"
                        )
                      }}</small
                    >
                    <small
                      v-else-if="product.available > 0"
                      style="
                        color: rgb(0, 133, 55);
                        font-weight: 600;
                        display: block;
                      "
                      >Tillgänglig nu</small
                    >
                    <small
                      v-else
                      style="
                        color: rgb(220, 36, 36);
                        font-weight: 600;
                        display: block;
                      "
                      >Slut i lager</small
                    >
                  </div>

                  <div v-if="sale" class="sale">
                    <div class="flex">
                      <span
                        class="mdi mdi-tag-outline has-half-right-margin is-size-3"
                      ></span>
                      <span>
                        <div class="flex">
                          <p
                            class="tag is-info has-half-right-margin tag-campaign"
                          >
                            KAMPANJ
                          </p>

                          <p class="is-bold">{{ sale.title }}</p>
                        </div>
                        <div>
                          <p>
                            {{ sale.description }}
                          </p>
                          <small class="is-size-7 has-text-black"
                            >Kampanj slutar
                            {{
                              moment(
                                new Date(sale.endDate.seconds * 1000)
                              ).format("YYYY.MM.DD")
                            }}</small
                          >
                        </div>
                      </span>
                    </div>
                  </div>

                  <div class="columns is-gapless is-multiline has-no-margin">
                    <div class="column is-full has-top-margin">
                      <b-numberinput
                        :editable="false"
                        min="1"
                        :max="getMaxProductQuantity(product)"
                        v-model="selectedQuantity"
                        type="is-light"
                        size="is-medium"
                        controls-position="compact"
                        :disabled="getMaxProductQuantity(product) === 0"
                      >
                      </b-numberinput>
                    </div>
                  </div>
                  <div class="columns is-multiline">
                    <div class="column is-full">
                      <button
                        class="button is-rounded is-fullwidth is-primary is-medium has-top-margin"
                        style=""
                        :disabled="getMaxProductQuantity(product) === 0"
                        @click="addProductToCart(product)"
                      >
                        <span v-if="product.isBookable">Lägg till bokning</span>
                        <span v-else>Lägg i kundvagnen</span>
                      </button>
                    </div>

                    <div class="column is-full">
                      <small class="has-no-margin has-text-centered is-block">
                        Fri frakt från: 500 kr. Eller hämta gratis i butik.
                      </small>
                    </div>
                    <div class="column is-full">
                      <ProductAvailability
                        :selected-product="product"
                      ></ProductAvailability>
                    </div>
                  </div>
                </section>
              </div>

              <hr class="separator" />
              <ProductProperties :product="product"></ProductProperties>
              <ProductsSuggestionByProduct
                :product="product"
              ></ProductsSuggestionByProduct>

              <ProductDescription :product="product"></ProductDescription>
            </div>
            <div
              class="column is-one-third has-left-separator is-relative desktop-price"
            >
              <section class="purchase-content">
                <h2
                  class="is-size-4 is-bold product-name"
                  style="line-height: 1.8rem"
                >
                  {{ product.name }}
                </h2>

                <div class="has-bottom-margin has-top-margin">
                  <h3
                    class="is-size-3 is-bold has-quarter-bottom-margin"
                    style="line-height: 1.1"
                  >
                    {{ product.price }} KR
                  </h3>
                  <small
                    v-if="product.booking && product.available > 0"
                    style="
                      color: rgb(0, 133, 55);
                      font-weight: 600;
                      display: block;
                    "
                    >Bokningsbar | Beräknad leverans är
                    {{
                      moment(new Date(product.booking.date)).format(
                        "YYYY.MM.DD"
                      )
                    }}</small
                  >
                  <small
                    v-else-if="product.available > 0"
                    style="
                      color: rgb(0, 133, 55);
                      font-weight: 600;
                      display: block;
                    "
                    >Tillgänglig nu</small
                  >
                  <small
                    v-else
                    style="
                      color: rgb(220, 36, 36);
                      font-weight: 600;
                      display: block;
                    "
                    >Slut i lager</small
                  >
                </div>

                <div v-if="sale" class="sale">
                  <div class="flex">
                    <span
                      class="mdi mdi-tag-outline has-half-right-margin is-size-3"
                    ></span>
                    <span>
                      <div class="flex">
                        <p
                          class="tag is-info has-half-right-margin tag-campaign"
                        >
                          KAMPANJ
                        </p>

                        <p class="is-bold">{{ sale.title }}</p>
                      </div>
                      <div>
                        <p>
                          {{ sale.description }}
                        </p>
                        <small class="is-size-7 has-text-black"
                          >Kampanj slutar
                          {{
                            moment(
                              new Date(sale.endDate.seconds * 1000)
                            ).format("YYYY.MM.DD")
                          }}</small
                        >
                      </div>
                    </span>
                  </div>
                </div>

                <div class="columns is-gapless is-multiline has-no-margin">
                  <div class="column is-full has-top-margin">
                    <b-numberinput
                      :editable="false"
                      min="1"
                      :max="getMaxProductQuantity(product)"
                      v-model="selectedQuantity"
                      type="is-light"
                      size="is-medium"
                      controls-position="compact"
                      :disabled="getMaxProductQuantity(product) === 0"
                    >
                    </b-numberinput>
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-full">
                    <button
                      class="button is-rounded is-fullwidth is-primary is-medium has-top-margin"
                      style=""
                      :disabled="getMaxProductQuantity(product) === 0"
                      @click="addProductToCart(product)"
                    >
                      <span v-if="product.isBookable">Lägg till bokning</span>
                      <span v-else>Lägg i kundvagnen</span>
                    </button>
                  </div>

                  <div class="column is-full">
                    <small class="has-no-margin has-text-centered is-block">
                      Fri frakt från: 500 kr. Eller hämta gratis i butik.
                    </small>
                  </div>
                  <div class="column is-full">
                    <ProductAvailability
                      :selected-product="product"
                    ></ProductAvailability>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductService from "@/services/ProductService";
import ProductProperties from "@/components/product/ProductProperties";
import ProductsSuggestionByProduct from "@/components/product/ProductsSuggestionByProduct";
import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductAvailability from "@/components/product/ProductAvailability.vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";

export default {
  name: "ShopProduct",
  components: {
    ProductProperties,
    ProductsSuggestionByProduct,
    ProductDescription,
    ProductAvailability,
    SkeletonLoader,
  },
  data() {
    return {
      isLoading: true,
      product: {},
      selectedQuantity: 1,
      selectedObjectId: null,
      selectedPropertyName: null,
      isGroupPropertyImages: null,
      groupedObjects: null,
      isProductSelected: false,
      discount: this.$store.getters.getDiscount,
      sale: null,
    };
  },
  computed: {
    paramId() {
      return this.$route.params.id;
    },
  },
  async created() {
    const productId = this.$route.params.id;
    this.product = await ProductService.searchActivatedProductBy(productId);
    /* ProductService.searchActivatedProductBy(productId)
      .then((data) => {
        this.product = data;
      })
      .catch(() => {});*/
    this.sale = this.isOnSale(this.product);
    this.isLoading = false;
  },
  methods: {
    getMaxProductQuantity(product) {
      const foundProduct = this.$store.state.cart.find(
        (prod) => prod.objectID === product.objectID
      );
      const amount = foundProduct ? foundProduct.quantity : 0;
      const amountLeft = product.available - amount;

      return amountLeft;
    },
    isOnSale(product) {
      let found = null;

      if (this.$store.state.sales.length) {
        this.$store.state.sales.forEach((sale) => {
          let productFound = sale.products.find(
            (saleProdId) => saleProdId === product.id
          );

          if (productFound && !found) found = sale;
        });
      }

      return found;
    },
    addProductToCart(product) {
      this.$store.commit("addProductToCart", {
        product: product,
        quantity: this.selectedQuantity,
      });

      this.selectedQuantity = 1;
      /*const productItem = {
        product: product,
        selectedQuantity: this.selectedQuantity,
      };
      this.$store.commit("addProductToShoppingCart", productItem);*/
    },
  },
  watch: {
    async paramId(newVal) {
      this.isLoading = true;
      this.product = await ProductService.searchActivatedProductBy(newVal);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

.sale {
  background: rgb(230, 243, 255);
  padding: 0.5rem 0.75rem;
  border-radius: $radius-small;
  p {
    color: $black-ter;
  }
}

.purchase-content {
  position: sticky;
  top: 8rem;
}

.tag-campaign {
  position: absolute;
  right: 1.75rem;
}

.img {
  height: 300px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.imgs {
  background: $grey-light;
}

.imgs-desktop {
  display: block;
}

.imgs-mobile {
  display: none;
  z-index: 999;
  top: 20.5rem;
}

.desktop-price {
  display: block;
}

.mobile-price {
  display: none;
  border-left: none !important;
}

@media screen and (max-width: 768px) {
  .mobile-loader {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .imgs-desktop {
    display: none;
  }

  .imgs-mobile {
    display: none; // TODO Check
  }
  .desktop-price {
    display: none;
  }

  .img {
    height: 250px;
  }

  .column {
    padding: 1rem !important;
  }
  .mobile-price {
    display: block;
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.has-left-separator {
  border-left: 1px solid gainsboro;
}

.card-header {
  background-color: blue;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
}

.card {
  //height: 200px;
  //overflow-y: scroll;
  //border: 2px solid $secondary-default-cute-pink;
  padding: 1rem;
  border-radius: 8px;
}

.product-image-selector {
  width: 64px !important;
  height: 64px !important;
  background: white;
  border: 1px solid gainsboro;

  &.is-active {
    border-color: black;
  }
  margin-right: 0.5rem;
}

.save-for-later {
  position: absolute;
  cursor: pointer;
  transition: 0.2s transform linear;
  &:hover {
    transform: scale(1.2);
  }
  //color: red;
}

.product-image {
  display: block !important;
  max-width: 64px !important;
  max-height: 64px !important;
  width: auto !important;
  height: auto !important;
}

.brand {
  position: absolute;
  top: 50%;
  left: 60px;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
}

.product-name {
  margin-bottom: 1.5rem;
}
</style>
