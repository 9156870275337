import { database, functions } from "@/main";
import { httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";

const PromotionService = {
  createStripePromotionCode(products) {
    let getPromotions = httpsCallable(functions, "createStripePromotionCode");
    return getPromotions({ products: products });
  },
  deleteAllStripePromotionCodes() {
    let getPromotions = httpsCallable(
      functions,
      "deleteAllStripePromotionCodes"
    );
    return getPromotions();
  },

  getCurrentlyPromotions() {
    let getPromotions = httpsCallable(functions, "currentlyPromotions");
    return getPromotions();
  },
  getCurrentlyPromotionProducts(productIds) {
    let getPromotionsProducts = httpsCallable(
      functions,
      "currentlyPromotionProducts"
    );
    return getPromotionsProducts(productIds);
  },
  getCurrentlyPromotionProductsByPromotionId(promotionId) {
    let getPromotionsProducts = httpsCallable(
      functions,
      "currentlyPromotionProductsByPromotionId"
    );
    return getPromotionsProducts(promotionId);
  },
  createPromotion(promotion) {
    return database.collection("promotions").add(promotion);
  },

  async getPromotions() {
    let themes = [];
    const themesRef = await getDocs(collection(database, "promotions"));

    themesRef.forEach((gameRef) => {
      themes.push({
        id: gameRef.id,
        ...gameRef.data(),
      });
    });

    return themes;
  },
};

export default PromotionService;
