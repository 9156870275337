<template>
  <div class="forgot-password-form">
    <div class="">
      <b-notification :closable="false" v-if="loginErrorMsg">
        <small>{{ loginErrorMsg }}</small>
      </b-notification>
      <form v-on:submit.prevent="forgotPassword(email)">
        <div class="field">
          <label class="label">E-post</label>
          <div class="control">
            <input
              v-model="email"
              class="input"
              type="email"
              placeholder="anna.karlsson@exempel.com"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          class="button is-fullwidth is-primary has-half-top-margin"
          v-bind:disabled="!isEmail()"
        >
          Skicka nytt lösenord
        </button>
      </form>
      <button
        class="button is-text is-fullwidth has-button-above"
        @click="onChangeView"
      >
        Tillbaka
      </button>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
export default {
  name: "ForgotPasswordForm",
  components: {},
  data: function () {
    return {
      sentPassword: false,
      loginErrorMsg: "",
      email: "",
    };
  },
  computed: {},
  methods: {
    forgotPassword(email) {
      AuthService.sendPasswordResetEmail(email)
        .then(() => {
          this.email = "";
          this.sentPassword = true;

          setTimeout(() => {
            this.onChangeView();
          }, 2000);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/user-not-found":
              this.loginErrorMsg = "User not found.";
              break;
            case "auth/invalid-email":
              this.loginErrorMsg = "Email address is not valid.";
              break;
          }
        });
    },
    isEmail() {
      var regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(this.email).toLowerCase());
    },
    onChangeView() {
      this.$emit("changeView", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group-space {
  padding: 10px;
}

.has-button-above {
  margin-top: 8px;
}

label.label {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: darkgray;
}
</style>
