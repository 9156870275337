<template>
  <div class="policy-banner">
    <div class="columns is-multiline flex">
      <div
        class="policy column is-half is-half-mobile is-half-tablet is-one-quarter-widescreen"
      >
        <article class="media is-relative">
          <figure class="media-left">
            <p class="image is-26x26">
              <img src="@/assets/images/svg/menu/shipping.svg" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p class="has-text-white is-vertical-middle is-absolute">
                Fri frakt över 500kr
              </p>
            </div>
          </div>
        </article>
      </div>
      <div
        class="policy column is-half is-half-mobile is-half-tablet is-one-quarter-widescreen"
      >
        <article class="media is-relative">
          <figure class="media-left">
            <p class="image is-26x26">
              <img src="@/assets/images/svg/menu/points.svg" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p class="has-text-white is-vertical-middle is-absolute">
                Poäng på varje köp
              </p>
            </div>
          </div>
        </article>
      </div>
      <div
        class="policy column is-half is-half-mobile is-half-tablet is-one-quarter-widescreen"
      >
        <article class="media is-relative">
          <figure class="media-left">
            <p class="image is-26x26">
              <img src="@/assets/images/svg/menu/gift.svg" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p class="has-text-white is-vertical-middle is-absolute">
                Växla poäng mot gåvor
              </p>
            </div>
          </div>
        </article>
      </div>
      <div
        class="policy column is-half is-half-mobile is-half-tablet is-one-quarter-widescreen"
      >
        <article class="media is-relative">
          <figure class="media-left">
            <p class="image is-26x26">
              <img src="@/assets/images/svg/menu/shipping.svg" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p class="has-text-white is-vertical-middle is-absolute">
                Leverans inom 3 dagar
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyBanner",
  props: {},

  data() {
    return {};
  },
  methods: {},
  created() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.policy-banner {
  background: #2647e8;
  border-radius: 6px;
  top: 80px;
  padding: 1rem;

  .is-26x26 {
    width: 26px;
    height: 26px;
  }

  @media screen and (max-width: 550px) {
    .policy {
      font-size: 12px;
    }
  }
}
</style>
