<template>
  <div v-if="false">
    <div
      class="container"
      :style="{ marginTop: '25vh', width: '500px', padding: '1rem' }"
    >
      <img
        class="is-horizontal-middle"
        src="@/assets/images/svg/logo/mobioexpress-machine.svg"
        width="150"
        alt="Image"
      />
      <div class="card has-2x-padding has-top-margin">
        <h2>Vi blir bättre!</h2>
        <p>
          Vi håller på uppdatera hemsidan med nya funktioner och produkter.
          Vänligen återkom någon gång under veckan.
        </p>
      </div>
    </div>
  </div>
  <div v-else class="">
    <TutorialModal
      :userHasReadManual="userHasReadManual"
      @has-read="hasRed"
    ></TutorialModal>

    <TopNavigation :has-top-banner="false"></TopNavigation>

    <div class="has-half-right-padding has-half-left-padding">
      <router-view />
    </div>

    <div
      style="top: calc(130px + 4rem); position: relative"
      v-show="showFooter"
    >
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import TutorialModal from "./components/modals/TutorialModal.vue";
import TopNavigation from "./components/navigation/TopNavigation.vue";
import LocalStorageService from "./services/LocalStorageService";
import PromotionService from "./services/PromotionService";
import SaleService from "./services/SaleService";
import FooterBottom from "./components/FooterBottom.vue";

export default {
  name: "App",
  components: { TutorialModal, TopNavigation, FooterBottom },
  data: function () {
    return {
      userHasReadManual: true,
      selectedGameId: null,
      promotions: [],
      showFooter: false,
    };
  },

  async created() {
    let sales = await SaleService.getSales();

    if (sales.length) {
      sales.forEach((sale) => {
        sale.products = sale.products
          .sort((a, b) => a.amount - b.amount)
          .reverse();
      });
    }
    this.$store.commit("setSales", sales);

    let promotions = await PromotionService.getCurrentlyPromotions();
    this.promotions = promotions.data;

    this.userHasReadManual =
      LocalStorageService.getFromLocalStorageBy("welcome");
    setTimeout(() => {
      this.showFooter = true;
    }, 1000);
  },
  methods: {
    hasRed() {
      LocalStorageService.setFromLocalStorageBy("welcome", true);
      this.userHasReadManual = true;
    },
  },
};
</script>

<style lang="scss">
.side-nav {
  display: flex;
  .left-nav {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
    background: #f5f8fd;
    border-right: 1px solid #edecec;
  }
  .right-nav {
    padding-left: 86px;
    width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  //pointer-events: none;
  background: rgba(0, 0, 0, 0.5);
  z-index: 98;
}

.freeze {
  text-align: left;
  overflow: hidden;
  height: 100vh;
}
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  position: relative;
}

.app-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  .app-content {
    display: flex;
    flex-direction: column;

    &.flex-grow {
      flex-grow: 1;
    }
  }
}
</style>
