import { database, functions } from "@/main";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

const COLLECTION_PRODUCT = "product";

const ProductService = {
  updateProduct(product) {
    const productRef = doc(database, COLLECTION_PRODUCT, product.id);
    return updateDoc(productRef, {
      ...product,
    });
  },
  deActivateProduct(id) {
    const productRef = doc(database, COLLECTION_PRODUCT, id);
    return updateDoc(productRef, {
      isActive: false,
    });
  },
  activateProduct(id) {
    const productRef = doc(database, COLLECTION_PRODUCT, id);
    return updateDoc(productRef, {
      isActive: true,
    });
  },
  balanceProducts() {
    const newGame = httpsCallable(functions, "balanceProducts");
    return newGame();
  },
  async searchActivatedProductsBy(
    searchQuery = "",
    page = 0,
    sortMethod,
    filter = "",
    hitsPerPage = 20
  ) {
    let searchProducts = httpsCallable(functions, "searchActivatedProducts");

    let data = await searchProducts({
      searchQuery: searchQuery,
      page: page,
      sortMethod: sortMethod,
      filter: filter,
      hitsPerPage: hitsPerPage,
    });

    return {
      ...data.data,
      hits: data.data.hits.map((hit) => {
        return {
          ...hit,
          id: hit.objectID,
        };
      }),
    };
  },
  async searchActivatedProductBy(idQuery = "") {
    let searchProducts = httpsCallable(functions, "searchActivatedProductById");

    let data = await searchProducts({
      idQuery: idQuery,
    });

    return {
      ...data.data,
      id: data.data.objectID,
    };
  },
  async getAllProducts() {
    const q = query(
      collection(database, COLLECTION_PRODUCT),
      where("isActive", "==", true)
    );

    let themes = [];

    const themesRef = await getDocs(q);
    themesRef.forEach((gameRef) => {
      themes.push({
        id: gameRef.id,
        ...gameRef.data(),
      });
    });

    return themes;
  },
  async getProductBy(id) {
    const docRef = doc(database, COLLECTION_PRODUCT, id);
    const docSnap = await getDoc(docRef);
    return {
      id: docSnap.id,
      ...docSnap.data(),
    };
  },
  async getTest() {
    let themes = [];
    const themesRef = await getDocs(collection(database, "swishPayments"));

    themesRef.forEach((gameRef) => {
      themes.push({
        id: gameRef.id,
        ...gameRef.data(),
      });
    });

    return themes;
  },
  async getGameBy(id) {
    const docRef = doc(database, COLLECTION_PRODUCT, id);
    const docSnap = await getDoc(docRef);
    return {
      id: docSnap.id,
      ...docSnap.data(),
    };
  },
  updateProductsIds() {
    const leaveGame = httpsCallable(functions, "updateAllStripeProducts");
    return leaveGame();
  },
  joinGameBy(gameId) {
    const joinGame = httpsCallable(functions, "joinGame");
    return joinGame({ id: gameId });
  },
  leaveGameBy(gameId) {
    const leaveGame = httpsCallable(functions, "leaveGame");
    return leaveGame({ id: gameId });
  },
  imReadyBy(gameId) {
    const imReady = httpsCallable(functions, "imReady");
    return imReady({ id: gameId });
  },
  updateMyProgressGame(gameId, words) {
    const updateMyProgressGame = httpsCallable(
      functions,
      "updateMyProgressGame"
    );
    return updateMyProgressGame({ id: gameId, words: words });
  },
  newGameBy(type, genre, reference) {
    const newGame = httpsCallable(functions, "newGame");
    return newGame({ type: type, genre: genre, reference: reference });
  },
  async reset(id) {
    const docRef = doc(database, COLLECTION_PRODUCT, id);
    const docSnap = await getDoc(docRef);
    const game = {
      id: docSnap.id,
      ...docSnap.data(),
    };

    const players = game.players;
    players[0].words = [];

    const newDoc = doc(database, COLLECTION_PRODUCT, id);
    await updateDoc(newDoc, {
      currentAttack: null,
      processedAttacks: [],
      players: players,
    });
  },
};

export default ProductService;
